import React from "react";
import { Modal, Button } from "react-bootstrap";

const InfoModal = ({ showInfoModal, groupInfo, handleCloseInfoModal }) => (
    <Modal
        show={showInfoModal}
        onHide={handleCloseInfoModal}
        keyboard="true"
        size="xl"
    >
        <Modal.Header className="justify-content-center">
            <Modal.Title>Group Info</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <form>
                <fieldset disabled>
                    <legend className="text-center">
                        {groupInfo.report_group_name}
                    </legend>
                    <div className="mb-3">
                        <label
                            htmlFor="disabledTextInput"
                            className="form-label"
                        >
                            Group Base Name
                        </label>
                        <input
                            type="text"
                            id="disabledTextInput"
                            className="form-control"
                            placeholder={groupInfo.report_file_base_name}
                        />
                    </div>
                    <div className="mb-3">
                        <label
                            htmlFor="disabledTextInput"
                            className="form-label"
                        >
                            Group Notes
                        </label>
                        <input
                            type="text"
                            id="disabledTextInput"
                            className="form-control"
                            placeholder={groupInfo.report_page_notes}
                        />
                    </div>
                    <div className="mb-3">
                        <label
                            htmlFor="disabledTextInput"
                            className="form-label"
                        >
                            Group Time Zone
                        </label>
                        <input
                            type="text"
                            id="disabledTextInput"
                            className="form-control"
                            placeholder={groupInfo.report_time_zone}
                        />
                    </div>
                    <div className="mb-3">
                        <label
                            htmlFor="disabledTextInput"
                            className="form-label"
                        >
                            Service Address 1
                        </label>
                        <input
                            type="text"
                            id="disabledTextInput"
                            className="form-control"
                            placeholder={groupInfo.service_addr1}
                        />
                    </div>
                    <div className="mb-3">
                        <label
                            htmlFor="disabledTextInput"
                            className="form-label"
                        >
                            Service Address 2
                        </label>
                        <input
                            type="text"
                            id="disabledTextInput"
                            className="form-control"
                            placeholder={groupInfo.service_addr2}
                        />
                    </div>
                    <div className="mb-3">
                        <label
                            htmlFor="disabledTextInput"
                            className="form-label"
                        >
                            Service Address 3
                        </label>
                        <input
                            type="text"
                            id="disabledTextInput"
                            className="form-control"
                            placeholder={groupInfo.service_addr3}
                        />
                    </div>
                    <div className="mb-3">
                        <label
                            htmlFor="disabledTextInput"
                            className="form-label"
                        >
                            Service City
                        </label>
                        <input
                            type="text"
                            id="disabledTextInput"
                            className="form-control"
                            placeholder={groupInfo.service_addr_city}
                        />
                    </div>
                    <div className="mb-3">
                        <label
                            htmlFor="disabledTextInput"
                            className="form-label"
                        >
                            Service State Abbreviation
                        </label>
                        <input
                            type="text"
                            id="disabledTextInput"
                            className="form-control"
                            placeholder={groupInfo.service_addr_state}
                        />
                    </div>
                    <div className="mb-3">
                        <label
                            htmlFor="disabledTextInput"
                            className="form-label"
                        >
                            Contract Expires
                        </label>
                        <input
                            type="text"
                            id="disabledTextInput"
                            className="form-control"
                            placeholder={groupInfo.contract_expires}
                        />
                    </div>
                </fieldset>
            </form>
        </Modal.Body>
        <Modal.Footer>
            <Button
                variant="primary"
                id="payment-button-cancel"
                onClick={handleCloseInfoModal}
            >
                Okay
            </Button>
        </Modal.Footer>
    </Modal>
);

export default InfoModal;
