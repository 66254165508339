import React, {PureComponent} from "react";
import {
    LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Bar, Tooltip, BarChart
} from "recharts";

import {Modal, Button} from "react-bootstrap";

import {Fetch} from "../../helpers/Fetch";

/*
        dayjs - momentjs replacement
        This block of code must be at the end of the imports
        because the last two lines must follow all imports.
 */
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

import timezone from 'dayjs/plugin/timezone';
dayjs.extend(utc);
dayjs.extend(timezone);

export class NonElecGraph extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            chart_loaded: true,
            loading: false,
            endDate: dayjs() //Left side of chart
                .tz(this.props.site_tz )
                .subtract(29, "days")
                .startOf("day")
                .utc()
                .toISOString(),
            initial_date: dayjs() //Right side of chart
                .tz(this.props.site_tz)
                .add(1, "day")
                .startOf("day")
                .utc()
                .toISOString(),
            site_tz: this.props.site_tz,
            graph_type: "bar",
            graph_difference: 7,
            plot_data: []
        };

    }

    componentDidMount() {
        const {initial_date, endDate} = this.state;

        //Plot the initial 30-day bar graph
        this.get_bar_daily(endDate, initial_date);
        // this.get_bar_daily(1227 , endDate, initial_date);
    }

    /******************************************************
     * Events
     ******************************************************/
    handleCurrentButton = () => {
        const {graph_difference, graph_type, site_tz} = this.state;
        let endDateBar = dayjs()
            .tz(site_tz)
            .subtract(29, "days")
            .startOf("day")
            .utc()
            .toISOString();
        let end_date_line_seven = dayjs()
            .tz(site_tz)
            .subtract(6, "days")
            .startOf("day")
            .utc()
            .toISOString();
        let end_date_line_two = dayjs()
            .tz(site_tz)
            .subtract(1, "days")
            .startOf("day")
            .utc()
            .toISOString();

        let initial_date = dayjs()
            .tz(site_tz)
            .add(1, "day")
            .startOf("day")
            .utc()
            .toISOString();

        if (graph_type === "bar") {
            this.get_bar_daily(endDateBar, initial_date);
        } else if (graph_type === "line" && graph_difference === 7) {
            this.get_trend_data(end_date_line_seven, initial_date, 7);
        } else if (graph_type === "line" && graph_difference === 1) {
            this.get_trend_data(end_date_line_two, initial_date, 1);
        }
    };

    get_bar_daily = (from_date, to_date) => {
        const {device_id, param_id, site_tz} = this.props

        Fetch("nonElec_api.php", {
            'action': 'get_bar_daily', 'device_id': device_id, //BTU/Tons meter
            'param_id': param_id, //BTU/Tons meter
            'fromDate': from_date, //Midnight 8-1 in America/Los_Angeles
            'toDate': to_date, //Midnight 8-8 in America/Los_Angeles
            'site_tz':site_tz
        }).then(data => {
            const maxKwh = Math.max.apply(Math, data.data.map(function (o) {
                return o.y_val;
            }));

            //Set the Y range se we get nice round numbers for each tick
            let rangeYDomain = 0;
            let y_tickCount; //Number of Y axis ticks including zero
            if (maxKwh > 10000) {
                //10,000kwh bumps
                rangeYDomain = (Math.floor(maxKwh / 10000) + 1) * 10000;
                if (rangeYDomain < 4000) y_tickCount = rangeYDomain / 5000 + 1; else y_tickCount = rangeYDomain / 10000 + 1;
            } else if (maxKwh > 1000) {
                //1,000kwh bumps
                rangeYDomain = (Math.floor(maxKwh / 1000) + 1) * 1000;
                if (rangeYDomain < 4000) y_tickCount = rangeYDomain / 500 + 1; else y_tickCount = rangeYDomain / 1000 + 1;
            } else if (maxKwh > 100) {
                //100 bumps
                rangeYDomain = (Math.floor(maxKwh / 100) + 1) * 100;
                if (rangeYDomain < 400) y_tickCount = rangeYDomain / 50 + 1; else y_tickCount = rangeYDomain / 100 + 1;
            } else if (maxKwh > 10) {
                //10 bumps
                rangeYDomain = (Math.floor(maxKwh / 10) + 1) * 10;
                if (rangeYDomain < 40) y_tickCount = rangeYDomain / 5 + 1; else y_tickCount = rangeYDomain / 10 + 1;
            } else {
                //1 bumps
                rangeYDomain = (Math.floor(maxKwh / 1) + 1) * 1;
                if (rangeYDomain < 4) y_tickCount = rangeYDomain + 1; else y_tickCount = rangeYDomain / 1 + 1;
            }

            this.setState({
                plot_data: data.data, y_axis_domain: [0, rangeYDomain], y_tickCount: y_tickCount, graph_type: "bar", //Bar chart
                endDate: from_date, //Right hand side of the plot or from/start date
                initial_date: to_date, //Left hand side of the plot or to/end date
                loading: false,
                graph_difference: 7
            });
        })
    };

    get_trend_data = (from_date, to_date, graph_difference) => {
        const {device_id, param_id} = this.props
        //line chart

        Fetch("nonElec_api.php", {
            'action': 'get_trend_data', 'device_id': device_id, //BTU/Tons meter
            'param_id': param_id, //BTU/Tons meter
            'fromDate': from_date, //Left side date
            'toDate': to_date, //Right side date
        })
            .then(data => {
                //Get the max Kwh value
                //let plot_data = data.data

                //Convert timestamps to unix timestamps
                let plot_data = []
                data.data.forEach(row => {
                    plot_data.push({
                        ts: dayjs.utc(row.ts).unix(), y_val: row.y_val
                    })
                })

                //Generate x axis tick values
                let from_date_unix = dayjs(from_date).unix();
                let to_date_unix = dayjs(to_date).unix();

                //Interval between ticks is 3 hours for 48 hour chart
                //Interval between ticks is 12 hours for anything else
                let step = (to_date_unix - from_date_unix) === 172800 ? 10800 : 43200;
                let tickArray = [];
                for (let i = from_date_unix; i <= to_date_unix; i += step) {
                    tickArray.push(i);
                }

                // const plot_data_sorted = plot_data.sort((a,b) => a.ts - b.ts)

                //Find max value for Y scaling
                const maxKwd = Math.max.apply(Math, plot_data.map(function (o) {
                    return param_id === 29 ? o.tons : o.y_val;
                }));

                //Set the X axis tickCount
                // let x_tickCount = 4;

                //Set the Y range se we get nice round numbers for each tick
                let rangeYDomain = 0;
                let y_tickCount; //Number of ticks including zero
                if (maxKwd > 10000) {
                    //10,000kwh bumps
                    rangeYDomain = (Math.floor(maxKwd / 10000) + 1) * 10000;
                } else if (maxKwd > 1000) {
                    //1,000kwh bumps
                    rangeYDomain = (Math.floor(maxKwd / 1000) + 1) * 1000;
                } else if (maxKwd > 100) {
                    //100 bumps
                    rangeYDomain = (Math.floor(maxKwd / 100) + 1) * 100;
                    if (rangeYDomain < 400) y_tickCount = rangeYDomain / 50 + 1; else y_tickCount = rangeYDomain / 100 + 1;
                } else if (maxKwd > 10) {
                    //10 bumps
                    rangeYDomain = (Math.floor(maxKwd / 10) + 1) * 10;
                    if (rangeYDomain < 40) y_tickCount = rangeYDomain / 5 + 1; else y_tickCount = rangeYDomain / 10 + 1;
                } else {
                    //1 bumps
                    rangeYDomain = (Math.floor(maxKwd / 1) + 1) * 1;
                    if (rangeYDomain < 4) y_tickCount = rangeYDomain + 1; else y_tickCount = rangeYDomain / 1 + 1;
                }
                this.setState({
                    site_tz: this.props.site_tz,
                    plot_data: plot_data,
                    tickArray: tickArray,
                    y_axis_domain: [0, rangeYDomain],
                    y_tickCount: y_tickCount,
                    graph_type: "line", //Line chart
                    endDate: from_date, //Right hand side of the plot or from/start date
                    initial_date: to_date, //Left hand side of the plot or to/end date
                    loading: false,
                    graph_difference: graph_difference
                });
            });
    };

    formatXAxisLine = tickItem => {
        return dayjs.unix(tickItem).tz(this.props.site_tz).format("MM-DD HH:mm");
    };

    formatXAxisBar = tickItem => {
        const {site_tz} = this.state;

        //Sometimes tickItem is not a date
        //Check for valid date
        if (dayjs(tickItem, dayjs.ISO_8601).isValid()) {
            return dayjs
                .utc(tickItem)
                .tz(site_tz)
                .format("MM-DD");
        } else {
            return ''
        }
    };

    formatYAxis = tickItem => {
        return Number(tickItem).toFixed(2);
    };

    handleRadioButtonChange = e => {
        let onchange_val = parseInt(e.target.value);
        const {site_tz} = this.state;

        //The end date - the right hand side date
        const {initial_date} = this.state; //Get the right side date

        //Calculate the left side date of the chart
        let endDate = dayjs(initial_date)
            .tz(site_tz)
            .subtract(onchange_val, "days")
            .utc()
            .toISOString();

        if (onchange_val === 7) {
            //7 days
            let graph_diff = 7;
            this.get_trend_data(endDate, initial_date, graph_diff);
        } else if (onchange_val === 2) {
            let graph_diff = 1;
            //2 days - 48 hours
            this.get_trend_data(endDate, initial_date, graph_diff);
        } else if (onchange_val === 30) {
            let graph_diff = 7;
            //30 day bar chart
            this.get_bar_daily(endDate, initial_date, graph_diff);
        }
    };

    panNegative = () => {
        //get values from state
        const {
            graph_difference, endDate, initial_date, graph_type, site_tz
        } = this.state;

        let calculated_initial = dayjs(initial_date)
            .tz(site_tz)
            .subtract(graph_difference, "days")
            .utc()
            .toISOString();
        let calculated_base = dayjs(endDate)
            .tz(site_tz)
            .subtract(graph_difference, "days")
            .utc()
            .toISOString();

        if (graph_type === "line") {
            this.get_trend_data(calculated_base, calculated_initial, graph_difference);
        } else if (graph_type === "bar" || graph_type === "") {
            this.get_bar_daily(calculated_base, calculated_initial, graph_difference);
        }
    };

    panPositive = () => {

        const {
            graph_difference, endDate, initial_date, graph_type, site_tz
        } = this.state;
        let calculated_initial = dayjs(initial_date)
            .tz(site_tz)
            .add(graph_difference, "days")
            .utc()
            .toISOString();
        let calculated_base = dayjs(endDate)
            .tz(site_tz)
            .add(graph_difference, "days")
            .utc()
            .toISOString();

        let end_time = dayjs(initial_date);
        let time_now = dayjs();

        if (end_time < time_now) {
            if (graph_type === "line") {
                this.get_trend_data(calculated_base, calculated_initial, graph_difference);
            } else if (graph_type === "bar" || graph_type === "") {
                this.get_bar_daily(calculated_base, calculated_initial, graph_difference);
            }
        }
    };

    render() {
        const {show_graph_modal, param_id} = this.props;
        const {
            chart_loaded,
            loading,
            endDate,
            initial_date,
            site_tz,
            graph_type,
            plot_data,
            y_axis_domain,
            y_tickCount,
            tickArray
        } = this.state;

        // const chartWidthDays = Number(dayjs(dayjs(initial_date).diff(dayjs(endDate))).format("D"));

        //NEVER DO THIS * NEVER DO THIS * NEVER DO THIS * NEVER DO THIS * NEVER DO THIS * NEVER DO THIS * NEVER DO THIS *
        //dayjs.suppressDeprecationWarnings = true;
        //NEVER DO THIS * NEVER DO THIS * NEVER DO THIS * NEVER DO THIS * NEVER DO THIS * NEVER DO THIS * NEVER DO THIS *

        const CustomTooltipBar = ({_active, payload, label}) => {
            return (<div className="custom-tooltip">
                <div className="custom-tooltip-header">
                    <p className="tooltip-label label">{`${dayjs
                        .utc(label)
                        .tz(site_tz)
                        .format("MM-DD-YYYY")}`}</p>
                </div>
                <div style={{color: "blue", float: "left"}}>&#9632;</div>
                <div style={{float: "left"}}>
                    {" "}
                    Gallons:{" "}
                    {payload ? payload[0] && payload[0].value : "undefined"}
                </div>
            </div>);
        };

        const CustomTooltipLine = ({_active, payload, label}) => {
            return (<div className="custom-tooltip">
                <div className="custom-tooltip-header">
                    <p className="tooltip-label label">{`${dayjs(label).format("MM-DD h:mm")}`}</p>
                </div>
                <div style={{color: "blue", float: "left"}}>&#9632;</div>
                <div className="custom-tooltip-body" style={{float: "left"}}>
                    {" "}
                    GPH (15 min average): {payload[0] && payload[0].value}
                    {/*kW Demand - 15 min: {payload}*/}

                </div>
            </div>);
        };

        return (<Modal
            show={show_graph_modal}
            onHide={this.props.handleGraphModalClose}
            size="xl"
        >
            <Modal.Header className="modal-header">
                {/*<Modal.Title>{reportRow.report_name || "Test Group"}</Modal.Title>*/}
                <Modal.Title>{this.props.device_name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {graph_type === "line" && ( //Show line chart
                    <ResponsiveContainer width="100%" height={400}>
                        <LineChart
                            width="130%"
                            height={700}
                            data={plot_data}
                            margin={{top: 20, right: 40, bottom: 25, left: 5}}
                        >
                            <Line
                                yAxisId="left"
                                dataKey={param_id === 29 ? "tons" : "y_val"}
                                stroke="#8884d8"
                                dot={false}  //No data marker
                                type="linear"
                                animationDuration={0}
                                isAnimationActive={false}
                                connectNulls={true}
                            />
                            {param_id === 29 && <Line
                                yAxisId="right"
                                dataKey="oat"
                                stroke="#661e03"
                                dot={false}  //No data marker
                                type="linear"
                                animationDuration={0}
                                isAnimationActive={false}
                                connectNulls={true}
                            />}
                            <CartesianGrid strokeDasharray="3 3" stroke="#ccc"/>

                            <XAxis //category
                                type={'number'}
                                scale="time"
                                dataKey="ts"  //Times are in unix
                                tickFormatter={this.formatXAxisLine}  //Format the timestamps from unix
                                interval={0}  //Show all ticks in tickArray
                                ticks={tickArray}
                                domain={[dayjs(endDate).unix(), dayjs(initial_date).unix()]}
                                angle={70}
                                style={{fontSize: ".8rem"}}
                                dy={33} //padding from y axis
                                dx={15} //padding from x axis
                            />
                            <YAxis
                                yAxisId="left"
                                tickFormatter={this.formatYAxis}
                                type="number"
                                domain={y_axis_domain}
                                tickCount={y_tickCount}
                                label={{
                                    value: "GPH DOM Water", dx: -40, //padding from y axis
                                    angle: -90, position: "center"
                                }}
                            />

                            {param_id === 29 && <YAxis
                                yAxisId="right"
                                orientation="right"
                                tickFormatter={this.formatYAxis}
                                type="number"
                                domain={y_axis_domain}
                                tickCount={y_tickCount}
                            />}

                            <Tooltip content={<CustomTooltipLine/>}/>
                        </LineChart>
                    </ResponsiveContainer>)}

                {graph_type === "bar" && ( //Bar chart
                    <ResponsiveContainer width="100%" height={400}>
                        <BarChart
                            width="130%"
                            height={300}
                            data={plot_data}
                            margin={{
                                top: 5, right: 30, left: 20, bottom: 5
                            }}
                        >
                            <CartesianGrid strokeDasharray="3 3"/>
                            <XAxis
                                dataKey="date"
                                tickFormatter={this.formatXAxisBar}
                                interval={0}  //All bars have a tick & label
                                angle={70}
                                dy={20}
                                dx={10}
                                style={{fontSize: ".8rem"}}
                            />
                            <YAxis domain={y_axis_domain} tickCount={y_tickCount} tickFormatter={this.formatYAxis}/>
                            <Tooltip content={<CustomTooltipBar/>}/>
                            <Bar
                                dataKey="y_val"
                                fill="#0047AB"
                                animationDuration={0}
                                isAnimationActive={false}
                            />
                        </BarChart>
                    </ResponsiveContainer>)}

                <div className={graph_type !== "line" ? "mt-5" : "mt-5"}>
                    {chart_loaded ? (<div>
                        <div className="input-group">
                            <div
                                className="form-group mx-auto"
                                style={{marginBottom: "0px"}}
                            >
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            id="48_hours"
                                            name="chartWidth"
                                            onChange={this.handleRadioButtonChange}
                                            disabled={loading}
                                            value="2"
                                        />
                                        48 hours
                                    </label>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            id="7_days"
                                            name="chartWidth"
                                            onChange={this.handleRadioButtonChange}
                                            disabled={loading}
                                            value="7"
                                        />
                                        7 Days
                                    </label>
                                </div>
                                <div className="form-check-inline">
                                    <label className="form-check-label">
                                        <input
                                            type="radio"
                                            className="form-check-input"
                                            id="30_days_bar"
                                            name="chartWidth"
                                            value="30"
                                            onChange={this.handleRadioButtonChange}
                                            disabled={loading}
                                            defaultChecked
                                        />
                                        Past 30 days
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div className="input-group">
                            <div className="mx-auto">
                                <button
                                    id="PanBackward"
                                    className={` hidden-print ${loading ? "" : "btn-primary"} `}
                                    value="negative"
                                    disabled={loading}
                                >
                      <span
                          className="fas fa-chevron-left"
                          onClick={this.panNegative}
                      />
                                </button>

                                <input
                                    className="rounded text-center mx-2 "
                                    id="timezone"
                                    value={site_tz}
                                    disabled
                                />

                                <label
                                    htmlFor="fromDate"
                                    className="col-form-label form-control-sm"
                                >
                                    From:
                                </label>
                                <input
                                    className="rounded text-center"
                                    id="fromDate"
                                    value={dayjs(endDate)
                                        .tz(site_tz)
                                        .format("MM-DD-YYYY HH:mm")}
                                    disabled
                                />

                                <label
                                    htmlFor="toDate"
                                    className="col-form-label form-control-sm"
                                >
                                    To:
                                </label>
                                <input
                                    className="rounded text-center mx-2 "
                                    id="toDate"
                                    value={dayjs(initial_date)
                                        .tz(site_tz)
                                        .format("MM-DD-YYYY HH:mm")}
                                    disabled
                                />

                                <button
                                    id="PanForward"
                                    className={`${loading ? "" : "btn-primary"}  hidden-print`}
                                    value="positive"
                                    disabled={loading}
                                >
                      <span
                          className="fas fa-chevron-right"
                          onClick={this.panPositive}
                      />
                                </button>

                                <button
                                    className="btn btn-primary btn-sm mb-1 mx-2"
                                    onClick={this.handleCurrentButton}
                                >
                                    Current
                                </button>
                            </div>
                        </div>

                        {loading && (<div
                            className="text-center"
                            style={{fontSize: "15px", marginTop: "10px"}}
                        >
                            <div className="loading-div">
                                Loading{" "}
                                <img
                                    alt=""
                                    height={20}
                                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                                />
                            </div>
                        </div>)}
                    </div>) : (<>
                        <div
                            className="text-center"
                            style={{height: "200px", fontSize: "20px"}}
                        >
                            <div className="loading-div">
                                Loading{" "}
                                <img
                                    alt=""
                                    height={20}
                                    src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                                />
                            </div>
                        </div>
                    </>)}
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Button
                    variant="secondary"
                    onClick={this.props.handleGraphModalClose}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>);
    }
}
