import { useState, useEffect, useCallback } from "react";
import Navigation from "../Navbar";
import AppFooter from "../AppFooter";
import NonElecTable from "./NonElecTable";
import SearchBar from "./SearchBar";
import SiteSelect from "../Global/Site_select2";
import { Fetch } from "../../helpers/Fetch";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { storageEventEmitter } from "../../helpers/StorageService";
dayjs.extend(utc);
dayjs.extend(timezone);

const NonElectric = () => {
    const [siteId, setSiteId] = useState(
        sessionStorage.getItem("prev_site_id")
    );
    const [filterText, setFilterText] = useState("");
    const [dates, setDates] = useState([]);
    const [nonElecData, setNonElecData] = useState([]);
    const [pannedVal, setPannedVal] = useState(0);
    const [date, setDate] = useState(
        dayjs().startOf("month").subtract(5, "months")
    );
    const [siteTz, setSiteTz] = useState("");

    useEffect(() => {
        if (siteId) {
            getNonElectricData(siteId);
        }
        //eslint-disable-next-line
    }, [siteId]);

    useEffect(() => {
        const handleStorageChange = () => {
            setSiteId(sessionStorage.getItem("prev_site_id"));
            setSiteTz(sessionStorage.getItem("prev_site_tz"));
        };

        // Listen for the custom event
        storageEventEmitter.addEventListener(
            "storageChanged",
            handleStorageChange
        );

        return () => {
            storageEventEmitter.removeEventListener(
                "storageChanged",
                handleStorageChange
            );
        };
    }, []);

    const westfieldExport = useCallback(async (e) => {
        e.preventDefault();
        await Fetch("nonElec_api.php", { action: "westfield_export" });
        // Handle the fetched data as needed
    }, []);

    const excelExport = useCallback(
        async (e) => {
            e.preventDefault();
            const module = await import(
                /* webpackChunkName: "ExcelExportNonElectric2" */ "../../helpers/ExcelExport"
            );

            const output = [
                ["DID", "Device Name", "Units", ...dates],
                ...nonElecData.map((r) => {
                    // let UnitConvert; //1 HCF = 748 gallons   12000 BTU = 1 ton
                    const unitConvert = r.param_id === 24 ? 1 : 1 / 12000;
                    return [
                        r.device_id,
                        r.device_name,
                        r.param_id === 24 ? "HCF Water" : "Tons CW",
                        ...[r.p1, r.p2, r.p3, r.p4, r.p5, r.p6].map(
                            (p) => Number(p) * unitConvert
                        ),
                    ];
                }),
            ];

            const Excel = {
                template: "nonElectExportV2.xlsx",
                Worksheets: [
                    {
                        worksheet: "Sheet1",
                        start_row: 3,
                        start_col_num: 1,
                        rows: output,
                    },
                ],
                output_filename: "export.xlsx",
            };

            module.excelExport(Excel);
        },
        [dates, nonElecData]
    );

    const handleSiteChange = (e) => {
        console.log("e---", e);
    };

    const handleTableReload = useCallback(() => {
        getNonElectricData(siteId);
        //eslint-disable-next-line
    }, [siteId]);

    const handlePanningChange = useCallback(
        (val) => {
            if (Number.isInteger(val)) {
                setPannedVal(pannedVal + val);
                setDate(dayjs(date).startOf("month").add(val, "months"));
            } else if (val === "current") {
                setPannedVal(0);
                setDate(dayjs().startOf("month").subtract(5, "months"));
            }
            getNonElectricData(siteId);
        },
        //eslint-disable-next-line
        [date, pannedVal, siteId]
    );

    const getNonElectricData = useCallback(async () => {
        const data = await Fetch("nonElec_api.php", {
            action: "fill_table",
            startDate: date,
            showDisabled: false,
            site_id: siteId,
        });

        if (data.status === "ok") {
            setNonElecData(data.data);
            setDates(data.dates);
        } else {
            console.error("Fetch error", data);
        }
    }, [date, siteId]);

    return (
        <div>
            <Navigation />
            <div className="container-fluid main-container-div">
                <div
                    className="sticky-top sticky-site-selector"
                    style={{ backgroundColor: "white" }}
                >
                    <div className="col-4 offset-4" align="center">
                        <SiteSelect handleSiteChange={handleSiteChange} />
                    </div>
                </div>
                {nonElecData && (
                    <div
                        className="button-bar sticky-cc-bar"
                        style={{ backgroundColor: "white" }}
                    >
                        <div className="container">
                            <div className="row mb-2 d-print-none">
                                <div className="col mt-1">
                                    <button
                                        className="btn btn-primary btn-sm button-bar-button mx-2"
                                        onClick={handleTableReload}
                                    >
                                        Refresh Table
                                    </button>
                                    <label className="me-1 mx-1">
                                        {" "}
                                        Show disabled{" "}
                                    </label>
                                    <input
                                        type="checkbox"
                                        name="disabled"
                                        value="disabled"
                                    />
                                    <i
                                        className="fas fa-chart-bar"
                                        style={{
                                            color: "black",
                                            marginLeft: "10px",
                                        }}
                                    />
                                    &nbsp;Click for more information
                                </div>
                                <div className="col mt-2">
                                    <button
                                        className="btn btn-primary btn-sm button-bar-button me-1"
                                        onClick={() => handlePanningChange(-3)}
                                    >
                                        <span className="fas fa-chevron-double-left" />
                                    </button>
                                    <span className="button-bar-button me-1">
                                        3 months
                                    </span>
                                    <button
                                        className="btn btn-primary btn-sm button-bar-button me-1"
                                        onClick={() => handlePanningChange(3)}
                                    >
                                        <span className="fas fa-chevron-double-right" />
                                    </button>
                                    <button
                                        className="btn btn-primary btn-sm button-bar-button"
                                        onClick={() =>
                                            handlePanningChange("current")
                                        }
                                    >
                                        Current
                                    </button>
                                    <button
                                        className="btn btn-secondary btn-sm mx-1"
                                        onClick={excelExport}
                                    >
                                        Excel
                                    </button>
                                    <button
                                        className="btn btn-secondary btn-sm mx-1"
                                        onClick={westfieldExport}
                                    >
                                        Westfield
                                    </button>
                                </div>
                                <div className="col-2 mt-2">
                                    <SearchBar
                                        filterText={filterText}
                                        onFilterTextChange={setFilterText}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {nonElecData && (
                    <NonElecTable
                        non_elec_data={nonElecData}
                        filterText={filterText}
                        panned_date={pannedVal}
                        dates={dates}
                        site_tz={siteTz}
                    />
                )}
            </div>
            <AppFooter />
        </div>
    );
};

export default NonElectric;
