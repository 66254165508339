import React, {Component} from "react";
import Navigation from "../Navbar";

import {Fetch} from "../../helpers/Fetch";

import MeterTable from "./MeterTable";
// import {BadDataGraph} from "./BadDataGraph";

export default class Bad_Datav2 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            meter_data: [],
            report_bill_id: "",
            // profile_table_data: [],
            report_time_zone: "",
            report_name: "",
            report_group_name: "",
            // trend_data: [],
            // raw_data: [],
            // submit_rbid: false,
            // report_bill_id_placeholder: ""
        };
    }

    handle_rbid_change = (e) => {
        if (e.key === 'Enter') {
            Fetch("bad_data.php", {
                action: "get_report_info",
                report_bill_id: e.target.value,
            }).then((data) => {
                if (data.status === "ok" && data.data.length > 0) {
                    this.setState(
                        {
                            report_bill_id: data.report_bill_id,
                            report_time_zone: data.data[0].site_tz,
                            report_name: data.data[0].report_name,
                            report_group_name: data.data[0].report_group_name,
                            meter_data: data.data,
                        }
                    );
                } else {
                    this.setState({
                        profile_table_data: data.data,
                        report_time_zone: "",
                        report_name: "",
                        report_group_name: "",
                        submit_rbid: true,
                    });
                }
            });
        }
    };

    render() {
        const {
            meter_data,
            report_bill_id,
            // device_id,
            // default_param_id,
            report_group_name,
            report_name,
            report_time_zone,
            // trend_data,
            // submit_rbid,
            // graph_difference,
            // report_bill_id_placeholder
        } = this.state;

        return (
            <div>
                <Navigation/>
                <h2 className="text-center">Bad Data Repair</h2>
                <div className="row">
                    <div className="text-center">
                        <label className="me-3" htmlFor="rbid">Enter Report Bill ID:</label>
                        <input
                            id="rbid"
                            className="form-control-sm"
                            onKeyDown={(e) => this.handle_rbid_change(e)}
                            type="number"
                        />
                    </div>
                </div>

                <div className="container">
                    <hr className="mt-3"/>
                    <div className="row">
                        <div className="col-2">
                            <label>Group Name:</label>
                        </div>
                        <div className="col-3">
                            <input
                                className="form-control"
                                value={report_group_name}
                                type="text"
                                readOnly
                            />
                        </div>
                        <div className="offset-2 col-2">
                            <label>Report Name:</label>
                        </div>
                        <div className="col-3">
                            <input
                                className="form-control"
                                value={report_name}
                                type="text"
                                readOnly
                            />
                        </div>
                    </div>
                    <div className="row mt-4">
                        <div className="col-2">
                            <label>Site Time Zone:</label>
                        </div>
                        <div className="col-3">
                            <input
                                className="form-control"
                                value={report_time_zone}
                                type="text"
                                readOnly
                            />
                        </div>
                    </div>
                    <MeterTable
                        meter_data={meter_data}
                        report_time_zone={report_time_zone}
                        report_bill_id={report_bill_id}
                    />
                    

                </div>
            </div>
        );
    }
}

