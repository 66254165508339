import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { Form, Row, Button, Modal, Alert } from "react-bootstrap";
import { Fetch } from "../../helpers/Fetch";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(utc);
dayjs.extend(timezone);

const ExportDevice = ({
    collector_name,
    rowData,
    siteTz,
    handleModalClose,
}) => {
    const {
        handleSubmit, //https://react-hook-form.com/api/useform/handlesubmit/
        reset, //Rest the form: https://react-hook-form.com/api/useform/reset/
    } = useForm();
    const [data, setData] = useState(null);
    const [parameters, setParameters] = useState([]);
    const [selectedParameterId, setSelectedParameterId] = useState(0);
    const [selectedDateId, setSelectedDateId] = useState(0);
    const [loading, setLoading] = useState(false);
    const [initialDate, setInitialDate] = useState(
        dayjs().tz(siteTz).subtract(1, "year"),
    );
    const [endDate, setEndDate] = useState(dayjs().tz(siteTz));
    const [showAlert, setShowAlert] = useState(false);
    const [fadeOut, setFadeOut] = useState(false);

    useEffect(() => {
        if (showAlert) {
            const timer = setTimeout(() => {
                setFadeOut(true); // Start fade-out effect
                const hideTimer = setTimeout(() => {
                    setShowAlert(false); // Hide alert after fade-out
                }, 300); // Match duration of the CSS transition

                return () => clearTimeout(hideTimer);
            }, 3000); // Show alert for 3 seconds

            return () => clearTimeout(timer);
        }
    }, [showAlert]);
    const fetch_parameters = useCallback(async () => {
        if (data) {
            const device_id = data.device_id;
            try {
                const response = await Fetch("devices_api.php", {
                    action: "get_parameters",
                    device_id: device_id,
                });
                if (response && response.status === "ok") {
                    const params = response.parameters;
                    params.sort((a, b) => {
                        if (
                            a.utilility_type_name_short <
                            b.utilility_type_name_short
                        )
                            return -1;
                        if (
                            a.utilility_type_name_short >
                            b.utilility_type_name_short
                        )
                            return 1;
                        return a.param_id - b.param_id; // Sort by param_id if utilility_type_name_short is the same
                    });

                    setParameters(params);
                }
            } catch (error) {
                console.error(error);
            }
        }
    }, [data]);

    const handleInitialDateChange = useCallback(
        (e) => {
            const newDate = dayjs(e.target.value)
                .tz(siteTz, true)
                .startOf("day");
            setInitialDate(newDate);
        },
        [siteTz],
    );

    const handleEndDateChange = useCallback(
        (e) => {
            const newDate1 = dayjs(e.target.value)
                .tz(siteTz, true)
                .startOf("day");
            const maxEndDate = initialDate.add(1, "year");

            if (newDate1.isAfter(maxEndDate)) {
                setShowAlert(true);
                setEndDate(maxEndDate);
            } else {
                setShowAlert(false);
                setEndDate(newDate1);
            }
        },
        [initialDate, siteTz],
    );

    const dateArray = [
        { id: 0, text: "Last 7 days" },
        { id: 1, text: "This Month" },
        { id: 2, text: "Last Month" },
        { id: 3, text: "Year to Date" },
        { id: 4, text: "Last Year" },
        { id: 5, text: "Custom Date" },
    ];

    const onDateChanged = useCallback((e) => {
        setSelectedDateId(parseInt(e.target.value));
    }, []);

    useEffect(() => {
        if (parameters.length > 0) {
            setSelectedParameterId(parameters[0].param_id);
        }
    }, [parameters]);

    useEffect(() => {
        if (rowData) {
            //If data is set, load form
            reset(JSON.parse(rowData)); //Important: reset the form to load new data
            setData(JSON.parse(rowData));
        } else setData(null);
        //eslint-disable-next-line
    }, [rowData, reset]);

    const chooseParameters = useCallback((e) => {
        setSelectedParameterId(parseInt(e.target.value));
    }, []);

    useEffect(() => {
        fetch_parameters();
        //eslint-disable-next-line
    }, [data]);

    const onSubmit = useCallback(async () => {
        if (!data || !data.device_id) {
            return;
        }
        const device_id = data.device_id;
        const param_id = selectedParameterId;
        let filter = "last_7_days";
        let start_date = "";
        let end_date = "";
        switch (selectedDateId) {
            case 1:
                filter = "this_month";
                break;
            case 2:
                filter = "last_month";
                break;
            case 3:
                filter = "year_to_date";
                break;
            case 4:
                filter = "last_year";
                break;
            case 5:
                filter = "custom";
                start_date = initialDate.utc().format("MM-DD-YYYY");
                end_date = endDate.utc().format("MM-DD-YYYY");
                break;
            default:
                filter = "last_7_days";
                break;
        }
        setLoading(true);
        try {
            const params = {
                action: "get_export",
                device_id: device_id,
                param_id: param_id,
                filter: filter,
            };
            if (
                filter === "custom" &&
                start_date.length > 0 &&
                end_date.length > 0
            ) {
                params.start_date = start_date;
                params.end_date = end_date;
            }
            const response = await Fetch("devices_api.php", params);
            if (response.status === "ok") {
                const exportData = response.data;

                const module = await import(
                    /* webpackChunkName: "ExcelExportDevices" */ "../../helpers/DeviceExcelExport"
                );
                const output = [];
                const jsonData = JSON.parse(rowData);

                output.push([`Collector name: ${collector_name}`]);
                output.push([`Designation: ${jsonData.designation}`]);
                output.push([`Device Id: ${jsonData.device_id}`]);
                output.push([`Meter Type: ${jsonData.device_type_name}`]);
                const parameter = parameters.find(
                    (parameter) => parameter.param_id === selectedParameterId,
                );
                if (parameter) {
                    output.push([
                        `Parameter: ${parameter.utilility_type_name_short} - ${parameter.param_id} - ${parameter.param_units}${
                            parameter.param_notes
                                ? ` - ${parameter.param_notes}`
                                : ""
                        }`,
                    ]);
                } else output.push([""]);
                output.push([`Site TZ: ${siteTz}`]);

                output.push([
                    "Time",
                    "Scale Factor",
                    "Log Value",
                    "Scaled Value",
                ]);

                exportData.forEach((item) => {
                    const row = [
                        dayjs(item.utc_time)
                            .tz(siteTz)
                            .format("MM/DD/YYYY HH:mm:ss A"),
                        item.scale_factor,
                        item.log_value,
                        item.scaled_value,
                    ];
                    output.push(row);
                });

                const Excel = {
                    template: "Devices.xlsx",
                    Worksheets: [
                        {
                            worksheet: "Sheet1",
                            start_row: 1,
                            start_col_num: 1,
                            rows: output,
                        },
                    ],
                    output_filename: `DevicesExportV1_${jsonData.designation}.xlsx`,
                };
                module.deviceExcelExport(Excel);
                handleModalClose();
            }
        } catch (error) {
            console.error("Error:", error);
        } finally {
            setLoading(false);
        }
    }, [
        collector_name,
        data,
        endDate,
        handleModalClose,
        initialDate,
        parameters,
        selectedDateId,
        selectedParameterId,
        siteTz,
        rowData,
    ]);

    const handleCancel = useCallback(() => {
        handleModalClose(); //Tell parent that the modal is closed
    }, [handleModalClose]);

    return !data ? null : (
        <Modal show={true} onHide={handleCancel} keyboard={false} centered>
            <Modal.Header closeButton>
                <Modal.Title className="text-center w-100">
                    Data Export
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    onSubmit={handleSubmit(onSubmit)}
                    className="col-10 mx-auto"
                >
                    <div className="row text-center justify-content-center">
                        <h6>Device Name:&nbsp;{data.device_name}</h6>
                        <h6>DID:&nbsp;{data.device_id}</h6>
                        <h6>Type:&nbsp;{data.device_type_name}</h6>
                        {selectedParameterId > 0 && parameters && (
                            <select onChange={chooseParameters}>
                                {parameters.map((parameter) => (
                                    <option
                                        key={parameter.param_id}
                                        value={parameter.param_id}
                                    >
                                        {`${parameter.utilility_type_name_short} - ${parameter.param_id} - ${parameter.param_units}${
                                            parameter.param_notes
                                                ? ` - ${parameter.param_notes}`
                                                : ""
                                        }`}
                                    </option>
                                ))}
                            </select>
                        )}
                        <Form.Group
                            as={Row}
                            className="mt-2 justify-content-center"
                        >
                            <div className="d-flex flex-column align-items-center">
                                {dateArray.map((date, index) => (
                                    <div
                                        className="form-check form-check-inline"
                                        key={`date-${index}`}
                                    >
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="radioSpanSelection"
                                            id={date.id}
                                            value={date.id}
                                            onChange={onDateChanged}
                                            checked={date.id === selectedDateId}
                                        />
                                        <label
                                            className="form-check-label"
                                            htmlFor={date.text}
                                            style={{ minWidth: "90px" }}
                                        >
                                            {date.text}
                                        </label>
                                    </div>
                                ))}
                                {selectedDateId === 5 && (
                                    <div className="d-flex mt-2">
                                        <label
                                            htmlFor="fromDate"
                                            className="col-form-label form-control-sm"
                                        >
                                            From:
                                        </label>
                                        <input
                                            className="rounded text-center"
                                            type="date"
                                            id="fromDate"
                                            step={1}
                                            value={dayjs(initialDate)
                                                .startOf("day")
                                                .format("YYYY-MM-DD")}
                                            onChange={handleInitialDateChange}
                                        />

                                        <label
                                            htmlFor="toDate"
                                            className="col-form-label form-control-sm"
                                        >
                                            To:
                                        </label>
                                        <input
                                            className="rounded text-center mx-2 "
                                            id="toDate"
                                            type="date"
                                            value={dayjs(endDate)
                                                .startOf("day")
                                                .format("YYYY-MM-DD")}
                                            onChange={handleEndDateChange}
                                        />
                                    </div>
                                )}

                                {showAlert && (
                                    <Alert
                                        variant="danger"
                                        onClose={() => {
                                            setFadeOut(true);
                                            setTimeout(
                                                () => setShowAlert(false),
                                                300,
                                            ); // Hide after fade-out
                                        }}
                                        dismissible
                                        className={`mt-2 fade-alert ${fadeOut ? "fade-out" : ""}`} // Apply fade-out class
                                    >
                                        Maximum 1 year
                                    </Alert>
                                )}
                            </div>
                        </Form.Group>
                        <div
                            className=" mt-2 d-flex justify-content-center"
                            style={{ gap: "12px" }}
                        >
                            <Button
                                type="submit"
                                className={` btn-sm ${
                                    loading ? "" : "btn-primary"
                                } `}
                                disabled={loading}
                            >
                                Export
                            </Button>
                            <Button
                                variant="secondary"
                                className="btn-sm"
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ExportDevice;
