const LegendItem = ({ color, text }) => (
    <>
        <i
            className="fas fa-square-full"
            style={{
                color: color,
                marginLeft: "10px",
            }}
        ></i>
        &nbsp;{text}
    </>
);

export default LegendItem;
