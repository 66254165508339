import React, { useState, useEffect, useCallback } from "react";
import { Fetch } from "../../helpers/Fetch";
import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    ResponsiveContainer,
    Tooltip as RechartsTooltip,
} from "recharts";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import BadReadingsTable from "./BadReadingsTable";
dayjs.extend(utc);
dayjs.extend(timezone);

const BadDataGraph = ({ site_tz, report_bill_id, device_id }) => {
    const [loading, setLoading] = useState(false);
    const [endDate, setEndDate] = useState(
        dayjs().tz(site_tz).add(1, "day").startOf("day"),
    );
    const [graphDifference, setGraphDifference] = useState("1");
    const [plotData, setPlotData] = useState([]);
    const [yAxisDomain, setYAxisDomain] = useState([0, 100]);
    const [_xTickCount, setXTickCount] = useState(4);
    const [yTickCount, setYTickCount] = useState(5);
    const [detectTablePan, setDetectTablePan] = useState(true);
    const transformArray = useCallback(
        (data) => {
            const currentDate = dayjs().tz(site_tz).format("YYYY-MM-DD");
            const currentHour = dayjs().tz(site_tz).format("HH:mm:ss");

            return data.map((item) => {
                const [date, hour] = item.x.split("T");
                if (
                    item.kwd === 0 &&
                    (date > currentDate ||
                        (date === currentDate && hour >= currentHour))
                ) {
                    return { ...item, kwd: null };
                }
                return item;
            });
        },
        [site_tz],
    );
    const fetchData = useCallback(async () => {
        setLoading(true);
        const newInitialDate = endDate.subtract(graphDifference, "day");
        const fdata = await Fetch("bad_data.php", {
            action: "get_trend_data",
            report_bill_id,
            fromDate: newInitialDate.utc().format("MM-DD-YYYY HH:mm"),
            toDate: endDate.utc().format("MM-DD-YYYY HH:mm"),
        });
        if (fdata && fdata.status === "ok") {
            const data = transformArray(fdata.data);
            const maxKwd = Math.max(...data.map((o) => o.kwd));

            let rangeYDomain;
            let yTicks;

            if (maxKwd > 10000)
                rangeYDomain = (Math.floor(maxKwd / 10000) + 1) * 10000;
            else if (maxKwd > 1000)
                rangeYDomain = (Math.floor(maxKwd / 1000) + 1) * 1000;
            else if (maxKwd > 100) {
                rangeYDomain = (Math.floor(maxKwd / 100) + 1) * 100;
                if (rangeYDomain < 400) {
                    yTicks = rangeYDomain / 50 + 1;
                } else yTicks = rangeYDomain / 100 + 1;
            } else if (maxKwd > 10) {
                rangeYDomain = (Math.floor(maxKwd / 10) + 1) * 10;
                if (rangeYDomain < 40) yTicks = rangeYDomain / 5 + 1;
                else yTicks = rangeYDomain / 10 + 1;
            } else {
                rangeYDomain = (Math.floor(maxKwd / 1) + 1) * 1;
                if (rangeYDomain < 4) yTicks = rangeYDomain + 1;
                else yTicks = rangeYDomain / 1 + 1;
            }

            setPlotData(data);
            setYAxisDomain([0, rangeYDomain]);
            setYTickCount(yTicks);
            setXTickCount(4);
            setLoading(false);
            setDetectTablePan((prev) => !prev);
        }
    }, [endDate, graphDifference, report_bill_id, transformArray]);

    useEffect(
        () => {
            fetchData();
        },
        //eslint-disable-next-line
        [endDate, graphDifference],
    );

    const handleDateChange = useCallback(
        (e) => {
            const newInitialDate = dayjs(e.target.value)
                .tz(site_tz, true)
                .startOf("day");
            const newEndDate = newInitialDate
                .add(graphDifference, "day")
                .startOf("day");
            setEndDate(newEndDate);
        },
        [graphDifference, site_tz],
    );

    const panData = useCallback(
        (direction) => {
            if (direction === "back") {
                setEndDate(endDate.subtract(graphDifference, "day"));
            } else {
                setEndDate(endDate.add(graphDifference, "day"));
            }
        },
        [endDate, graphDifference],
    );

    const CustomTooltipLine = ({ active, payload, label }) =>
        active ? (
            <div className="custom-tooltip">
                <div className="custom-tooltip-header">
                    <p className="tooltip-label label">
                        {dayjs(label).utcOffset(site_tz).format("MM-DD HH:mm")}
                    </p>
                </div>
                <div style={{ color: "blue", float: "left" }}>&#9632;</div>
                <div className="custom-tooltip-body" style={{ float: "left" }}>
                    kW Demand: {payload[0]?.value}
                </div>
            </div>
        ) : null;

    return (
        <div>
            <ResponsiveContainer
                width="100%"
                height={400}
                className="Tenantv2_chart mb-5"
            >
                <LineChart
                    data={plotData}
                    margin={{ top: 0, right: 20, bottom: 25, left: 5 }}
                >
                    <Line
                        dataKey="kwd"
                        stroke="#8884d8"
                        dot={false}
                        type="linear"
                        animationDuration={0}
                        isAnimationActive={false}
                        connectNulls
                    />
                    <CartesianGrid strokeDasharray="3 3" stroke="#ccc" />
                    <XAxis
                        dataKey="x"
                        tickFormatter={(tickItem) =>
                            dayjs(tickItem).format("MM-DD HH:mm")
                        }
                        angle={70}
                        style={{ fontSize: ".8rem" }}
                        dy={33}
                        dx={15}
                        label={{
                            value: "kW Demand",
                            dy: 80,
                            angle: 0,
                            position: "center",
                        }}
                    />
                    <YAxis
                        tickFormatter={(tickItem) =>
                            Number(tickItem).toFixed(2)
                        }
                        type="number"
                        domain={yAxisDomain}
                        tickCount={yTickCount}
                        label={{
                            value: "kW Demand",
                            dx: -40,
                            angle: -90,
                            position: "center",
                        }}
                    />
                    <RechartsTooltip content={<CustomTooltipLine />} />
                </LineChart>
            </ResponsiveContainer>
            {loading && (
                <div
                    className="text-center"
                    style={{ fontSize: "15px", marginTop: "10px" }}
                >
                    <div className="loading-div">
                        Loading{" "}
                        <img
                            alt=""
                            height={20}
                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                        />
                    </div>
                </div>
            )}
            <div className="input-group d-sm-none d-md-block text-center">
                <div className="offset-4 col-4">
                    {["1", "7", "30"].map((span) => (
                        <div
                            className="form-check form-check-inline"
                            key={span}
                        >
                            <input
                                className="form-check-input"
                                type="radio"
                                name="radioSpanSelection"
                                id={`spanSelect${span}days`}
                                value={span}
                                onChange={(e) =>
                                    setGraphDifference(e.target.value)
                                }
                                checked={graphDifference === span}
                            />
                            <label
                                className="form-check-label"
                                htmlFor={`spanSelect${span}days`}
                            >{`${span} ${span === "1" ? "Day" : "Days"}`}</label>
                        </div>
                    ))}
                </div>
                <div className="mx-auto">
                    <button
                        className={`hidden-print ${loading ? "" : "btn-primary"}`}
                        onClick={() => panData("back")}
                        disabled={loading}
                    >
                        <span className="fas fa-chevron-left" />
                    </button>

                    <input
                        className="rounded text-center mx-2"
                        value={site_tz}
                        disabled
                    />

                    <label
                        htmlFor="fromDate"
                        className="col-form-label form-control-sm"
                    >
                        From:
                    </label>
                    <input
                        className="rounded text-center"
                        type="datetime-local"
                        id="fromDate"
                        step={1}
                        value={dayjs(endDate.subtract(graphDifference, "day"))
                            .startOf("day")
                            .format("YYYY-MM-DD HH:mm")}
                        onChange={handleDateChange}
                    />

                    <label
                        htmlFor="toDate"
                        className="col-form-label form-control-sm"
                    >
                        To:
                    </label>
                    <input
                        className="rounded text-center mx-2"
                        id="toDate"
                        type="datetime-local"
                        value={dayjs(endDate)
                            .startOf("day")
                            .format("YYYY-MM-DD HH:mm")}
                        disabled
                    />

                    <button
                        className={`hidden-print ${loading ? "" : "btn-primary"}`}
                        onClick={() => panData("forward")}
                        disabled={loading}
                    >
                        <span className="fas fa-chevron-right" />
                    </button>
                </div>
            </div>
            <BadReadingsTable
                detect_table_pan={detectTablePan}
                site_tz={site_tz}
                report_bill_id={report_bill_id}
                device_id={device_id}
                initial_date={endDate.subtract(graphDifference, "day")}
                endDate={endDate}
            />
        </div>
    );
};

export default BadDataGraph;
