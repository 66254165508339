import React, { useState, useEffect, useCallback, useMemo } from "react";
import { Table } from "react-bootstrap";
import { useTable } from "react-table";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { NumberFormat } from "../../helpers/NumberFormat";
import { Fetch } from "../../helpers/Fetch";

dayjs.extend(utc);
dayjs.extend(timezone);

const BadReadingsTable = ({
    device_id,
    site_tz,
    endDate,
    initial_date,
    detect_table_pan,
}) => {
    const [badDataReads, setBadDataReads] = useState([]);
    const [swapData, setSwapData] = useState([]);
    const [showReaderTable, setShowReaderTable] = useState(false);

    const getReaderTableData = useCallback(async () => {
        if (device_id) {
            const data = await Fetch("bad_data.php", {
                action: "get_table_data",
                device_id: device_id,
                report_time_zone: site_tz,
                start_date: initial_date.utc().format("MM-DD-YYYY HH:mm"),
                end_date: endDate.utc().format("MM-DD-YYYY HH:mm"),
            });
            setBadDataReads(data.raw_data);
            setSwapData(data.swap_data);
            setShowReaderTable(true);
        }
    }, [device_id, site_tz, endDate, initial_date]);

    useEffect(
        () => {
            getReaderTableData();
        },
        //eslint-disable-next-line
        [detect_table_pan, device_id],
    );

    const handleCheckBoxClick = useCallback(
        async (e, row) => {
            await Fetch("bad_data.php", {
                action: "mark_bad_reading",
                device_id: device_id,
                gmt_timestamp: row.original.gmt_timestamp,
                param_id: row.original.param_id,
                log_value_status: e.target.checked ? 1 : 0,
            });
            await getReaderTableData();
        },
        [device_id, getReaderTableData],
    );

    const columnsSwap = useMemo(
        () => [
            { Header: "ID", accessor: "device_swap_id", key: "device_swap_id" },
            {
                Header: "Swap UTC",
                accessor: "swap_timestamp",
                key: "swap_timestamp",
            },
            {
                Header: "Before Swap",
                accessor: "value_before_swap",
                key: "value_before_swap",
            },
            {
                Header: "After Swap",
                accessor: "value_after_swap",
                key: "value_after_swap",
            },
            {
                Header: "Swap Comment",
                accessor: "swap_comment",
                key: "swap_comment",
            },
        ],
        [],
    );

    const columns = useMemo(
        () => [
            { Header: "DID", accessor: "device_id", key: "device_id" },
            { Header: "Param", accessor: "param_id", key: "param_id" },
            {
                Header: "UTC",
                accessor: "gmt_timestamp",
                key: "gmt_timestamp",
                Cell: ({ cell }) =>
                    dayjs(cell.value).format("YYYY-MM-DD HH:mm:ss"),
            },
            {
                Header: "Site Date/Time",
                accessor: "local_time",
                key: "local_time",
            },
            {
                Header: "Reading",
                accessor: "log_value",
                key: "log_value",
                Cell: ({ cell }) =>
                    cell.value > 0
                        ? NumberFormat().format(cell.value)
                        : cell.value,
            },
            {
                Header: "Diff from prev",
                accessor: "kwh_diff",
                key: "kwh_diff",
                Cell: ({ cell }) =>
                    cell.value > 0
                        ? NumberFormat(2).format(cell.value)
                        : cell.value,
            },
            {
                Header: "Bad",
                accessor: "log_value_status",
                key: "log_value_status",
                Cell: ({ row }) => (
                    <input
                        type="checkbox"
                        defaultChecked={row.original.log_value_status}
                        onClick={(e) => handleCheckBoxClick(e, row)}
                    />
                ),
            },
            {
                Header: "kW",
                accessor: "kw_demand",
                key: "kw_demand",
                Cell: ({ cell }) =>
                    cell.value > 0
                        ? NumberFormat(1).format(cell.value)
                        : cell.value,
            },
        ],
        [handleCheckBoxClick],
    );

    const TableRender = ({ columns, data }) => {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
        } = useTable({ columns, data });
        rows.sort((a, b) =>
            a.values.gmt_timestamp.localeCompare(b.values.gmt_timestamp),
        );

        return (
            <Table
                striped
                bordered
                hover
                responsive
                size="sm"
                {...getTableProps()}
            >
                <thead>
                    {headerGroups.map((headerGroup, index) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={`tr-TableRender-${index}`}
                        >
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps()}
                                    key={column.key}
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr
                                {...row.getRowProps()}
                                key={`badReadingTb-${i}`}
                                className={
                                    row.values.log_value === "0"
                                        ? "bg-warning"
                                        : row.values.kwh_diff === "0"
                                          ? "bg-secondary"
                                          : ""
                                }
                            >
                                {row.cells.map((cell) => (
                                    <td
                                        {...cell.getCellProps()}
                                        key={cell.column.key}
                                    >
                                        {cell.render("Cell")}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        );
    };

    const TableRenderSwap = ({ columns, data }) => {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
        } = useTable({ columns, data });

        return (
            <Table
                striped
                bordered
                hover
                responsive
                size="sm"
                {...getTableProps()}
            >
                <thead>
                    {headerGroups.map((headerGroup, index) => (
                        <tr
                            key={"TableRenderSwap" + index}
                            {...headerGroup.getHeaderGroupProps()}
                        >
                            {headerGroup.headers.map((column) => (
                                <th
                                    key={"TableRenderSwap" + column.key}
                                    {...column.getHeaderProps()}
                                >
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <tr key={"readingTb" + i} {...row.getRowProps()}>
                                {row.cells.map((cell) => (
                                    <td
                                        {...cell.getCellProps()}
                                        key={cell.column.key}
                                    >
                                        {cell.render("Cell")}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </Table>
        );
    };

    return (
        <div>
            {showReaderTable && (
                <>
                    <div
                        className="col-12 p-1"
                        style={{ border: "1px solid black" }}
                    >
                        <div className="input-group justify-content-center">
                            <div className="form-check mt-2 ms-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="hide_badCheck"
                                    checked
                                    onChange={() => {}}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="hide_badCheck"
                                >
                                    Hide bad
                                </label>
                            </div>
                            <div className="form-check form-switch mt-2 ms-1">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="flexSwitchCheckChecked"
                                    checked
                                    onChange={() => {}}
                                />
                                <label
                                    className="form-check-label"
                                    htmlFor="flexSwitchCheckChecked"
                                >
                                    Mark bad
                                </label>
                            </div>
                            <label
                                htmlFor="fromDate"
                                className="form-label form-control-sm mt-1"
                            >
                                Between:
                            </label>
                            <input
                                className="rounded text-center"
                                type="datetime-local"
                                id="fromDate"
                                value={dayjs(initial_date)
                                    .startOf("day")
                                    .format("YYYY-MM-DD HH:mm")}
                                onChange={() => {}}
                            />
                            <input
                                className="rounded text-center ms-1"
                                type="datetime-local"
                                id="toDate"
                                value={dayjs(endDate)
                                    .startOf("day")
                                    .format("YYYY-MM-DD HH:mm")}
                                onChange={() => {}}
                            />
                            <button>Now</button>
                            <label
                                htmlFor="swapDate"
                                className="form-label form-control-sm mt-1"
                            >
                                Create Swap:
                            </label>
                            <input
                                className="rounded text-center"
                                type="datetime-local"
                                id="swapDate"
                                value={dayjs(initial_date)
                                    .startOf("day")
                                    .format("YYYY-MM-DD HH:mm")}
                                onChange={() => {}}
                            />
                            <button>Now</button>
                        </div>
                    </div>
                    <div className="mt-1">
                        {swapData && swapData.length > 0 && (
                            <TableRenderSwap
                                columns={columnsSwap}
                                data={swapData}
                            />
                        )}
                    </div>
                    <div className="mt-1">
                        {badDataReads && badDataReads.length > 0 && (
                            <TableRender
                                columns={columns}
                                data={badDataReads}
                            />
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default BadReadingsTable;
