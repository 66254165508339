import React, { Component } from "react";
import Navigation from "./Navbar";

class NoAccess extends Component {
  render() {
    return (
      <div>
        <Navigation />
        <h3 className="text-center">
          Your account is not yet configured.
          <br />
          <br />
          Contact your account administrator if this is not expected.
        </h3>
      </div>
    );
  }
}

export default NoAccess;
