import React, { useState, useMemo, Fragment, useCallback } from "react";
import { useTable, useExpanded, useSortBy } from "react-table";
import { Button, Table, Tooltip, OverlayTrigger } from "react-bootstrap";
import Fuse from "fuse.js";
import "./Tenants.css";
import { NumberFormat } from "../../helpers/NumberFormat";
import { CurrencyFormat } from "../../helpers/CurrencyFormat";
import ExpandedReportTable from "./ExpandedReportTable";
import { TenantModal } from "./TenantModal";
import { Fetch } from "../../helpers/Fetch";
import InfoModal from "./InfoModal";
import NavigationButton from "./NavigationButton";
import LegendItem from "./LegendItem";
import ToggleCheckbox from "./ToggleCheckbox";
/******************************************
 * Dayjs imports must be at the bottom of imports
 * because the .extend statements must come after all the imports.
 ******************************************/
import dayjs from "dayjs";

//import timezone from "dayjs/plugin/timezone";
//import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
import LocalizedFormat from "dayjs/plugin/localizedFormat";

//dayjs.extend(timezone);
//dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(LocalizedFormat);

/********************************************
 * END OF DAYJS IMPORTS
 ********************************************/

/**
 * This is a Functional Component, not a Class Component
 * https://www.twilio.com/blog/react-choose-functional-components
 * @param props
 * @returns {JSX.Element}
 */
export default function TenantReportReactTable({
    table_data: reports,
    report_dates: reportDates,
    category_rows: categoryRows,
    handle_change_offset,
    refresh_table,
    toggle_consumption,
    disable_pagination,
    loading,
}) {
    const [filterText, setFilterText] = useState("");
    const [showDollars, setShowDollars] = useState(true);
    const [showVacant, setShowVacant] = useState(false);
    const [showHouseLoads, setShowHouseLoads] = useState(false);
    const [showGraphModal, setShowGraphModal] = useState(false);
    const [showZeroConsumption, setShowZeroConsumption] = useState(false);
    const [showInfoModal, setShowInfoModal] = useState(false);
    const [reportRow, setReportRow] = useState({});
    const [groupInfo, setGroupInfo] = useState({});
    const [loadValue, setLoadValue] = useState("");
    const loginData = JSON.parse(localStorage.getItem("login_data"));

    const memoReports = useMemo(() => {
        const fuse = new Fuse(reports, {
            keys: ["report_name", "org_name"],
            threshold: 0,
            ignoreLocation: true,
            isCaseSensitive: false,
        });

        const search_results = fuse.search(filterText);
        return filterText
            ? search_results.map((result) => result.item)
            : reports;
    }, [filterText, reports]);

    const handleClearFilter = useCallback(() => {
        setFilterText("");
    }, [setFilterText]);

    const excelExport = useCallback(
        async (e) => {
            e.preventDefault();
            const module = await import(
                /* webpackChunkName: "ExcelExportNonElectric2" */ "../../helpers/ExcelExport"
            );

            const output = [
                [
                    "Space Name",
                    "Tenant Name",
                    ...reportDates.map(
                        (date) =>
                            dayjs(date?.report_from_date).format("MM-DD-YY") +
                            " - " +
                            dayjs(date?.report_to_date).format("MM-DD-YY"),
                    ),
                ],
            ];

            memoReports.forEach((r) => {
                if (!r.report_name) return;
                const row = [
                    r.report_name,
                    r.org_name,
                    showDollars
                        ? `$${r.m1 ? parseFloat(r.m1).toFixed(2) : "0"}`
                        : `${
                              r.m1_kwh ? parseFloat(r.m1_kwh).toFixed(2) : "0"
                          } kWh - ${
                              r.m1_kw ? parseFloat(r.m1_kw).toFixed(2) : "0"
                          }KW`,
                    showDollars
                        ? `$${r.m2 ? parseFloat(r.m2).toFixed(2) : "0"}`
                        : `${
                              r.m2_kwh ? parseFloat(r.m2_kwh).toFixed(2) : "0"
                          } kWh - ${
                              r.m2_kw ? parseFloat(r.m2_kw).toFixed(2) : "0"
                          }KW`,
                    showDollars
                        ? `$${r.m3 ? parseFloat(r.m3).toFixed(2) : "0"}`
                        : `${
                              r.m3_kwh ? parseFloat(r.m3_kwh).toFixed(2) : "0"
                          } kWh - ${
                              r.m3_kw ? parseFloat(r.m3_kw).toFixed(2) : "0"
                          }KW`,
                    showDollars
                        ? `$${r.m4 ? parseFloat(r.m4).toFixed(2) : "0"}`
                        : `${
                              r.m4_kwh ? parseFloat(r.m4_kwh).toFixed(2) : "0"
                          } kWh - ${
                              r.m4_kw ? parseFloat(r.m4_kw).toFixed(2) : "0"
                          }KW`,
                    showDollars
                        ? `$${r.m5 ? parseFloat(r.m5).toFixed(2) : "0"}`
                        : `${
                              r.m5_kwh ? parseFloat(r.m5_kwh).toFixed(2) : "0"
                          } kWh - ${
                              r.m5_kw ? parseFloat(r.m5_kw).toFixed(2) : "0"
                          }KW`,
                    showDollars
                        ? `$${r.m6 ? parseFloat(r.m6).toFixed(2) : "0"}`
                        : `${
                              r.m6_kwh ? parseFloat(r.m6_kwh).toFixed(2) : "0"
                          } kWh - ${
                              r.m6_kw ? parseFloat(r.m6_kw).toFixed(2) : "0"
                          }KW`,
                ];
                output.push(row);
            });

            const Excel = {
                template: "TenantV3.xlsx",
                Worksheets: [
                    {
                        worksheet: "Sheet1",
                        start_row: 3,
                        start_col_num: 1,
                        rows: output,
                    },
                ],
                output_filename: "TenantV3.xlsx",
            };
            module.excelExport(Excel);
        },
        [memoReports, reportDates, showDollars],
    );

    const handleDataToggleDollars = useCallback(() => {
        setShowDollars((e) => !e);
    }, [setShowDollars]);

    const handleDataToggleVacant = useCallback(() => {
        setShowVacant((e) => !e);
    }, [setShowVacant]);

    const handleOpenInfoModal = useCallback(
        async (report_group_id) => {
            setShowInfoModal(true);
            const data = await Fetch("TenantV3_api.php", {
                action: "get_group_info",
                report_group_id: report_group_id,
            });
            if (data.status === "ok") {
                setGroupInfo(data.data);
            }
        },
        [setShowInfoModal, setGroupInfo],
    );

    const handleCloseInfoModal = useCallback(() => {
        setShowInfoModal(false);
    }, [setShowInfoModal]);

    const handleDataToggleHouseLoads = useCallback(
        (e) => {
            const value = e.target.value;
            if (value === "all") {
                setShowHouseLoads(false);
                setLoadValue("all");
            } else if (value === "loads") {
                setShowHouseLoads(true);
                setLoadValue("loads");
            } else if (value === "house") {
                setShowHouseLoads(true);
                setLoadValue("house");
            }
        },
        [setShowHouseLoads, setLoadValue],
    );

    const toggleConsumption = useCallback(() => {
        setShowZeroConsumption((e) => !e);
        toggle_consumption();
    }, [setShowZeroConsumption, toggle_consumption]);

    const handleShowGraphModal = useCallback(
        (_e, props, _p) => {
            console.log("props---", props);

            setReportRow(props);
            setShowGraphModal(true);
        },
        [setShowGraphModal, setReportRow],
    );

    const handleGraphModalClose = useCallback(() => {
        setShowGraphModal(false);
    }, [setShowGraphModal]);

    const handleGroupExpand = useCallback(
        async (e, report_group_id, collapse_val) => {
            await Fetch("TenantV3_api.php", {
                action: "UPDATE_rg_collapsed",
                report_group_id: report_group_id,
                collapsed: !collapse_val,
            });
            refresh_table();
        },
        [refresh_table],
    );

    //Create Currency/Number format objects
    const cf = useMemo(() => CurrencyFormat(), []);
    const nf = useMemo(() => NumberFormat(), []);

    const columns = useMemo(
        () => [
            {
                Header: () => <div>#</div>, // No header
                id: "expander", // It needs an ID
                Cell: ({ row }) => {
                    if (row.isExpanded) {
                        return (
                            <i
                                {...row.getToggleRowExpandedProps()}
                                className="far fa-minus-square d-print-none"
                                style={{ color: "red" }}
                            />
                        );
                    } else {
                        if (row.original.report_bill_id) {
                            return (
                                <i
                                    {...row.getToggleRowExpandedProps()}
                                    className="far fa-plus-square d-print-none"
                                    style={{ color: "green" }}
                                />
                            );
                        }
                    }
                },
            },
            {
                Header: () => <div>Report Group Name</div>,
                accessor: "report_group_name",
                Cell: (props) => {
                    return props.value;
                },
            },
            {
                Header: () => <div>Space Name</div>,
                accessor: "report_name",
                Cell: (props) => {
                    return (
                        <div>
                            {props.value}
                            {props.row.original.report_bill_id && (
                                <i
                                    className="float-end far fa-chart-bar fa-lg table-icon-styling"
                                    onClick={(e) =>
                                        handleShowGraphModal(
                                            e,
                                            props.row.original,
                                            props,
                                        )
                                    }
                                />
                            )}
                        </div>
                    );
                },
            },
            {
                Header: () => <div>Tenant Name</div>,
                accessor: "org_name",
            },
            {
                Header: () => (
                    <div className="text-center text-nowrap">
                        {reportDates !== undefined
                            ? reportDates[0]
                                ? dayjs(reportDates[0].report_from_date).format(
                                      "MM-DD-YY",
                                  ) +
                                  " - " +
                                  dayjs(reportDates[0].report_to_date).format(
                                      "MM-DD-YY",
                                  )
                                : ""
                            : ""}
                    </div>
                ),
                accessor: "m1_kwh",
                Cell: (props) => {
                    return (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {!showDollars
                                        ? "Invoice: $" +
                                          cf.format(props.row.original.m1)
                                        : nf.format(props.value) +
                                          " kWh - " +
                                          NumberFormat(2).format(
                                              props.row.original.m1_kw,
                                          ) +
                                          "kW"}
                                </Tooltip>
                            }
                            color="black"
                        >
                            <div className="text-center ">
                                {
                                    props.value
                                        ? showDollars
                                            ? "$" +
                                              cf.format(props.row.original.m1)
                                            : nf.format(props.value) + "kWh  @"
                                        : props.row.original.m1
                                          ? "$" +
                                            cf.format(props.row.original.m1)
                                          : "" //default: just show the invoice amount if available
                                }
                                {!showDollars
                                    ? props.row.original.m1_kw
                                        ? NumberFormat(2).format(
                                              props.row.original.m1_kw,
                                          ) + "kW"
                                        : ""
                                    : ""}
                            </div>
                        </OverlayTrigger>
                    );
                },
            },
            {
                Header: () => (
                    <div className="text-center text-nowrap">
                        {reportDates[1]
                            ? dayjs(reportDates[1].report_from_date).format(
                                  "MM-DD-YY",
                              ) +
                              " - " +
                              dayjs(reportDates[1].report_to_date).format(
                                  "MM-DD-YY",
                              )
                            : ""}
                    </div>
                ),
                accessor: "m2_kwh",
                Cell: (props) => {
                    return (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {!showDollars
                                        ? "Invoice: $" +
                                          cf.format(props.row.original.m2)
                                        : nf.format(props.value) +
                                          " kWh - " +
                                          NumberFormat(2).format(
                                              props.row.original.m2_kw,
                                          ) +
                                          "kW"}
                                </Tooltip>
                            }
                            color="black"
                        >
                            <div className="text-center">
                                {props.value
                                    ? showDollars
                                        ? "$" + cf.format(props.row.original.m2)
                                        : nf.format(props.value) + "kWh @"
                                    : props.row.original.m2
                                      ? "$" + cf.format(props.row.original.m2)
                                      : ""}
                                {!showDollars
                                    ? props.row.original.m2_kw
                                        ? NumberFormat(2).format(
                                              props.row.original.m2_kw,
                                          ) + "kW"
                                        : ""
                                    : ""}
                            </div>
                        </OverlayTrigger>
                    );
                },
            },
            {
                Header: () => (
                    <div className="text-center text-nowrap">
                        {reportDates[2] !== undefined
                            ? reportDates[2]
                                ? dayjs(reportDates[2].report_from_date).format(
                                      "MM-DD-YY",
                                  ) +
                                  " - " +
                                  dayjs(reportDates[2].report_to_date).format(
                                      "MM-DD-YY",
                                  )
                                : ""
                            : ""}
                    </div>
                ),
                accessor: "m3_kwh",
                Cell: (props) => {
                    return (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {!showDollars
                                        ? "Invoice: $" +
                                          cf.format(props.row.original.m3)
                                        : nf.format(props.value) +
                                          " kWh - " +
                                          NumberFormat(2).format(
                                              props.row.original.m3_kw,
                                          ) +
                                          "kW"}
                                </Tooltip>
                            }
                            color="black"
                        >
                            <div className="text-center">
                                {props.value
                                    ? showDollars
                                        ? "$" + cf.format(props.row.original.m3)
                                        : nf.format(props.value) + "kWh @"
                                    : props.row.original.m3
                                      ? "$" + cf.format(props.row.original.m3)
                                      : ""}
                                {!showDollars
                                    ? props.row.original.m3_kw
                                        ? NumberFormat(2).format(
                                              props.row.original.m3_kw,
                                          ) + "kW"
                                        : ""
                                    : ""}
                            </div>
                        </OverlayTrigger>
                    );
                },
            },
            {
                Header: () => (
                    <div className="text-center text-nowrap">
                        {reportDates[3] !== undefined
                            ? reportDates[3]
                                ? dayjs(reportDates[3].report_from_date).format(
                                      "MM-DD-YY",
                                  ) +
                                  " - " +
                                  dayjs(reportDates[3].report_to_date).format(
                                      "MM-DD-YY",
                                  )
                                : ""
                            : ""}
                    </div>
                ),
                accessor: "m4_kwh",
                Cell: (props) => {
                    return (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {!showDollars
                                        ? "Invoice: $" +
                                          cf.format(props.row.original.m4)
                                        : nf.format(props.value) +
                                          " kWh - " +
                                          NumberFormat(2).format(
                                              props.row.original.m4_kw,
                                          ) +
                                          "kW"}
                                </Tooltip>
                            }
                            color="black"
                        >
                            <div className="text-center">
                                {props.value
                                    ? showDollars
                                        ? "$" + cf.format(props.row.original.m4)
                                        : nf.format(props.value) + "kWh @"
                                    : props.row.original.m4
                                      ? "$" + cf.format(props.row.original.m4)
                                      : ""}
                                {!showDollars
                                    ? props.row.original.m4_kw
                                        ? NumberFormat(2).format(
                                              props.row.original.m4_kw,
                                          ) + "kW"
                                        : ""
                                    : ""}
                            </div>
                        </OverlayTrigger>
                    );
                },
            },
            {
                Header: () => (
                    <div className="text-center text-nowrap">
                        {reportDates[4] !== undefined
                            ? reportDates[4]
                                ? dayjs(reportDates[4].report_from_date).format(
                                      "MM-DD-YY",
                                  ) +
                                  " - " +
                                  dayjs(reportDates[4].report_to_date).format(
                                      "MM-DD-YY",
                                  )
                                : ""
                            : ""}
                    </div>
                ),
                accessor: "m5_kwh",
                Cell: (props) => {
                    return (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {!showDollars
                                        ? "Invoice: $" +
                                          cf.format(props.row.original.m5)
                                        : nf.format(props.value) +
                                          " kWh - " +
                                          NumberFormat(2).format(
                                              props.row.original.m5_kw,
                                          ) +
                                          "kW"}
                                </Tooltip>
                            }
                            color="black"
                        >
                            <div className="text-center">
                                {
                                    props.value
                                        ? showDollars
                                            ? "$" +
                                              cf.format(props.row.original.m5)
                                            : nf.format(props.value) + "kWh @"
                                        : props.row.original.m5
                                          ? "$" +
                                            cf.format(props.row.original.m5)
                                          : "" //default: just show the invoice amount if available
                                }
                                {!showDollars
                                    ? props.row.original.m5_kw
                                        ? NumberFormat(2).format(
                                              props.row.original.m5_kw,
                                          ) + "kW"
                                        : ""
                                    : ""}
                            </div>
                        </OverlayTrigger>
                    );
                },
            },
            {
                Header: () => (
                    <div className="text-center text-nowrap">
                        {reportDates[5] !== undefined
                            ? reportDates[5]
                                ? dayjs(reportDates[5].report_from_date).format(
                                      "MM-DD-YY",
                                  ) +
                                  " - " +
                                  dayjs(reportDates[5].report_to_date).format(
                                      "MM-DD-YY",
                                  )
                                : ""
                            : ""}
                    </div>
                ),
                accessor: "m6_kwh",
                Cell: (props) => {
                    return (
                        <OverlayTrigger
                            placement="top"
                            overlay={
                                <Tooltip>
                                    {!showDollars
                                        ? "Invoice: $" +
                                          cf.format(props.row.original.m6)
                                        : nf.format(props.value) +
                                          " kWh - " +
                                          NumberFormat(2).format(
                                              props.row.original.m6_kw,
                                          ) +
                                          "kW"}
                                </Tooltip>
                            }
                            color="black"
                        >
                            <div className="text-center">
                                {props.value
                                    ? showDollars
                                        ? "$" + cf.format(props.row.original.m6)
                                        : nf.format(props.value) + "kWh @"
                                    : props.row.original.m6
                                      ? "$" + cf.format(props.row.original.m6)
                                      : ""}
                                {!showDollars
                                    ? props.row.original.m6_kw
                                        ? NumberFormat(2).format(
                                              props.row.original.m6_kw,
                                          ) + "kW"
                                        : ""
                                    : ""}
                            </div>
                        </OverlayTrigger>
                    );
                },
            },
            {
                Header: "report_group_id",
                accessor: "report_group_id",
            },
            {
                Header: "m1",
                accessor: "m1",
            },
            {
                accessor: "m2",
                Header: "m2",
            },
            {
                accessor: "m3",
                Header: "m3",
            },
            {
                accessor: "m4",
                Header: "m4",
            },
            {
                accessor: "m5",
                Header: "m5",
            },
            {
                accessor: "m6",
                Header: "m6",
            },
            {
                accessor: "energy_user_id",
                Header: "energy_user_id",
            },
            {
                accessor: "tree_id",
                Header: "tree_id",
            },
            {
                accessor: "dont_send_bill",
                Header: "dont_send_bill",
            },
            {
                accessor: "report_bill_id",
                Header: "report_bill_id",
            },
            {
                accessor: "collapsed",
                Header: "collapsed",
            },
        ],
        [handleShowGraphModal, reportDates, showDollars, cf, nf],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        // state: { expanded },
    } = useTable(
        {
            columns,
            data: memoReports,
            isLoading: true,
            autoResetExpanded: true,
            debugTable: true,
            initialState: {
                sortBy: [
                    {
                        id: "report_group_id",
                        desc: false,
                    },
                    {
                        id: "report_name",
                        desc: false,
                    },
                ],
                hiddenColumns: [
                    "collapsed",
                    "report_group_name",
                    "m1",
                    "m2",
                    "m3",
                    "m4",
                    "m5",
                    "m6",
                    "report_group_id",
                    "energy_user_id",
                    "tree_id",
                    "dont_send_bill",
                    "report_bill_id",
                ],
            },
        },
        useSortBy,
        useExpanded,
    );
    // let vacant = showVacant ? "tenants-show-vacant" : "hide-row";
    let house_loads = showHouseLoads ? "hide-row" : "tenants-house-load";

    return (
        <>
            <div className="text-center d-print-none">
                <span className="float-start mt-2">
                    {loginData.user_role > 6000 && (
                        <ToggleCheckbox
                            label="Show Dollars"
                            checked={showDollars}
                            onChange={handleDataToggleDollars}
                        />
                    )}

                    <ToggleCheckbox
                        label="Show Vacants"
                        checked={showVacant}
                        onChange={handleDataToggleVacant}
                    />
                    <ToggleCheckbox
                        label="Show Zero Consumption"
                        checked={showZeroConsumption}
                        onChange={toggleConsumption}
                    />
                    <select
                        onChange={handleDataToggleHouseLoads}
                        className="mx-2"
                        value={loadValue}
                    >
                        <option value="all">Show All Loads</option>
                        <option value="loads">Show House</option>
                    </select>
                </span>
                <NavigationButton
                    offset={6}
                    handleClick={handle_change_offset}
                    disabled={disable_pagination}
                />
                <NavigationButton
                    offset={1}
                    handleClick={handle_change_offset}
                    disabled={disable_pagination}
                />
                <span className="button-bar-button">Pan</span>
                <NavigationButton
                    offset={-1}
                    handleClick={handle_change_offset}
                />
                <NavigationButton
                    offset={-6}
                    handleClick={handle_change_offset}
                />
                <button
                    className="btn btn-primary btn-sm button-bar-button"
                    onClick={(e) => handle_change_offset(e, "current")}
                >
                    Current
                </button>

                <Button
                    className="btn btn-primary btn-sm button-bar-button mx-2"
                    onClick={refresh_table}
                >
                    Refresh
                </Button>
                <input
                    type="text"
                    onChange={(e) => setFilterText(e.target.value)}
                    value={filterText}
                    id="device_filter_input"
                    placeholder="Filter(Space,Tenant Name)"
                    className="form-control-sm"
                />
                <button className="btn-sm mx-3" onClick={handleClearFilter}>
                    Clear
                </button>
                <button
                    className="btn btn-secondary btn-sm mx-1 "
                    onClick={excelExport}
                >
                    Excel
                </button>
                <span className="float-end mt-2">
                    Legend:
                    <LegendItem color="orange" text="Send to admin" />
                    <LegendItem color="yellow" text="Vacant" />
                    <LegendItem color="lightgray" text="House Loads" />
                </span>
            </div>
            <Table
                size="sm"
                bordered
                {...getTableProps()}
                className={loading ? "table-loading" : ""}
            >
                <thead>
                    {headerGroups.map((headerGroup, index) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={`header-${index}`}
                        >
                            {headerGroup.headers.map((column, i) => {
                                return (
                                    <th
                                        {...column.getHeaderProps()}
                                        key={`th-${index}-${i}`}
                                    >
                                        {column.render("Header")}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <Fragment key={"idx_" + row.id}>
                                {(i === 0
                                    ? true
                                    : row.values.report_group_name !==
                                      rows[i - 1].values.report_group_name) &&
                                    row.values.report_group_id > 0 && (
                                        <tr
                                            {...row.getRowProps()}
                                            key={`${row.id}-${i}`}
                                        >
                                            <td
                                                colSpan={1}
                                                className={`${
                                                    row.values
                                                        .report_group_id === 0
                                                        ? "custom-group-color"
                                                        : "category-header-color"
                                                } text-center`}
                                                key={`td-${row.id}-${i}-1`}
                                            >
                                                {row.values.collapsed ? (
                                                    <i
                                                        className="fas fa-plus-square"
                                                        onClick={(e) =>
                                                            handleGroupExpand(
                                                                e,
                                                                row.values
                                                                    .report_group_id,
                                                                row.values
                                                                    .collapsed,
                                                            )
                                                        }
                                                    />
                                                ) : (
                                                    <i
                                                        className="fas fa-minus-square"
                                                        onClick={(e) =>
                                                            handleGroupExpand(
                                                                e,
                                                                row.values
                                                                    .report_group_id,
                                                                row.values
                                                                    .collapsed,
                                                            )
                                                        }
                                                    />
                                                )}
                                            </td>
                                            <td
                                                colSpan={2}
                                                className={`${
                                                    row.values
                                                        .report_group_id === 0
                                                        ? "custom-group-color"
                                                        : "category-header-color"
                                                } text-center `}
                                                key={`td-${row.id}-${i}-2`}
                                            >
                                                {row.values.report_group_name}
                                                <i
                                                    className="fa fa-info-circle mx-2 d-print-none"
                                                    onClick={() =>
                                                        handleOpenInfoModal(
                                                            row.values
                                                                .report_group_id,
                                                        )
                                                    }
                                                    aria-hidden="true"
                                                />
                                            </td>
                                            <td
                                                className={`${
                                                    row.values
                                                        .report_group_id === 0
                                                        ? "custom-group-color"
                                                        : "category-header-color"
                                                } text-center `}
                                                key={`td-${row.id}-${i}-3`}
                                            >
                                                {categoryRows.find(
                                                    (val) =>
                                                        val.report_group_id ===
                                                        row.values
                                                            .report_group_id,
                                                ).m1_kwh_total > 0 &&
                                                showDollars
                                                    ? " $ " +
                                                      cf.format(
                                                          memoReports
                                                              .filter(
                                                                  (item) =>
                                                                      item.report_group_id ===
                                                                      row.values
                                                                          .report_group_id,
                                                              )
                                                              .reduce(
                                                                  (sum, item) =>
                                                                      sum +
                                                                      (parseFloat(
                                                                          item.m1,
                                                                      ) || 0),
                                                                  0,
                                                              ),
                                                      )
                                                    : categoryRows.find(
                                                          (val) =>
                                                              val.report_group_id ===
                                                              row.values
                                                                  .report_group_id,
                                                      ).m1_kwh_total > 0 &&
                                                      nf.format(
                                                          memoReports
                                                              .filter(
                                                                  (item) =>
                                                                      item.report_group_id ===
                                                                      row.values
                                                                          .report_group_id,
                                                              )
                                                              .reduce(
                                                                  (sum, item) =>
                                                                      sum +
                                                                      (parseFloat(
                                                                          item.m1_kwh,
                                                                      ) || 0),
                                                                  0,
                                                              ),
                                                      ) + " kWh "}
                                                {categoryRows.find(
                                                    (val) =>
                                                        val.report_group_id ===
                                                        row.values
                                                            .report_group_id,
                                                ).m1_w_gal_total > 0 &&
                                                !showDollars
                                                    ? nf.format(
                                                          memoReports
                                                              .filter(
                                                                  (item) =>
                                                                      item.report_group_id ===
                                                                      row.values
                                                                          .report_group_id,
                                                              )
                                                              .reduce(
                                                                  (sum, item) =>
                                                                      sum +
                                                                      (parseFloat(
                                                                          item.m1_w_gal,
                                                                      ) || 0),
                                                                  0,
                                                              ),
                                                      ) + " gal"
                                                    : ""}
                                            </td>
                                            <td
                                                className={`${
                                                    row.values
                                                        .report_group_id === 0
                                                        ? "custom-group-color"
                                                        : "category-header-color"
                                                } text-center `}
                                                key={`td-${row.id}-${i}-4`}
                                            >
                                                {categoryRows.find(
                                                    (val) =>
                                                        val.report_group_id ===
                                                        row.values
                                                            .report_group_id,
                                                ).m2_kwh_total > 0 &&
                                                showDollars
                                                    ? " $ " +
                                                      cf.format(
                                                          memoReports
                                                              .filter(
                                                                  (item) =>
                                                                      item.report_group_id ===
                                                                      row.values
                                                                          .report_group_id,
                                                              )
                                                              .reduce(
                                                                  (sum, item) =>
                                                                      sum +
                                                                      (parseFloat(
                                                                          item.m2,
                                                                      ) || 0),
                                                                  0,
                                                              ),
                                                      )
                                                    : categoryRows.find(
                                                          (val) =>
                                                              val.report_group_id ===
                                                              row.values
                                                                  .report_group_id,
                                                      ).m2_kwh_total > 0 &&
                                                      nf.format(
                                                          memoReports
                                                              .filter(
                                                                  (item) =>
                                                                      item.report_group_id ===
                                                                      row.values
                                                                          .report_group_id,
                                                              )
                                                              .reduce(
                                                                  (sum, item) =>
                                                                      sum +
                                                                      (parseFloat(
                                                                          item.m2_kwh,
                                                                      ) || 0),
                                                                  0,
                                                              ),
                                                      ) + " kWh "}
                                                {categoryRows.find(
                                                    (val) =>
                                                        val.report_group_id ===
                                                        row.values
                                                            .report_group_id,
                                                ).m2_w_gal_total > 0 &&
                                                !showDollars
                                                    ? nf.format(
                                                          memoReports
                                                              .filter(
                                                                  (item) =>
                                                                      item.report_group_id ===
                                                                      row.values
                                                                          .report_group_id,
                                                              )
                                                              .reduce(
                                                                  (sum, item) =>
                                                                      sum +
                                                                      (parseFloat(
                                                                          item.m2_w_gal,
                                                                      ) || 0),
                                                                  0,
                                                              ),
                                                      ) + " gal"
                                                    : ""}
                                            </td>
                                            <td
                                                className={`${
                                                    row.values
                                                        .report_group_id === 0
                                                        ? "custom-group-color"
                                                        : "category-header-color"
                                                } text-center `}
                                                key={`td-${row.id}-${i}-5`}
                                            >
                                                {categoryRows.find(
                                                    (val) =>
                                                        val.report_group_id ===
                                                        row.values
                                                            .report_group_id,
                                                ).m3_kwh_total > 0 &&
                                                showDollars
                                                    ? " $ " +
                                                      cf.format(
                                                          memoReports
                                                              .filter(
                                                                  (item) =>
                                                                      item.report_group_id ===
                                                                      row.values
                                                                          .report_group_id,
                                                              )
                                                              .reduce(
                                                                  (sum, item) =>
                                                                      sum +
                                                                      (parseFloat(
                                                                          item.m3,
                                                                      ) || 0),
                                                                  0,
                                                              ),
                                                      )
                                                    : categoryRows.find(
                                                          (val) =>
                                                              val.report_group_id ===
                                                              row.values
                                                                  .report_group_id,
                                                      ).m3_kwh_total > 0 &&
                                                      nf.format(
                                                          memoReports
                                                              .filter(
                                                                  (item) =>
                                                                      item.report_group_id ===
                                                                      row.values
                                                                          .report_group_id,
                                                              )
                                                              .reduce(
                                                                  (sum, item) =>
                                                                      sum +
                                                                      (parseFloat(
                                                                          item.m3_kwh,
                                                                      ) || 0),
                                                                  0,
                                                              ),
                                                      ) + " kWh "}
                                                {categoryRows.find(
                                                    (val) =>
                                                        val.report_group_id ===
                                                        row.values
                                                            .report_group_id,
                                                ).m3_w_gal_total > 0 &&
                                                !showDollars
                                                    ? nf.format(
                                                          memoReports
                                                              .filter(
                                                                  (item) =>
                                                                      item.report_group_id ===
                                                                      row.values
                                                                          .report_group_id,
                                                              )
                                                              .reduce(
                                                                  (sum, item) =>
                                                                      sum +
                                                                      (parseFloat(
                                                                          item.m3_w_gal,
                                                                      ) || 0),
                                                                  0,
                                                              ),
                                                      ) + " gal"
                                                    : ""}
                                            </td>
                                            <td
                                                className={`${
                                                    row.values
                                                        .report_group_id === 0
                                                        ? "custom-group-color"
                                                        : "category-header-color"
                                                } text-center `}
                                                key={`td-${row.id}-${i}-6`}
                                            >
                                                {categoryRows.find(
                                                    (val) =>
                                                        val.report_group_id ===
                                                        row.values
                                                            .report_group_id,
                                                ).m4_kwh_total > 0 &&
                                                showDollars
                                                    ? " $ " +
                                                      cf.format(
                                                          memoReports
                                                              .filter(
                                                                  (item) =>
                                                                      item.report_group_id ===
                                                                      row.values
                                                                          .report_group_id,
                                                              )
                                                              .reduce(
                                                                  (sum, item) =>
                                                                      sum +
                                                                      (parseFloat(
                                                                          item.m4,
                                                                      ) || 0),
                                                                  0,
                                                              ),
                                                      )
                                                    : categoryRows.find(
                                                          (val) =>
                                                              val.report_group_id ===
                                                              row.values
                                                                  .report_group_id,
                                                      ).m4_kwh_total > 0 &&
                                                      nf.format(
                                                          memoReports
                                                              .filter(
                                                                  (item) =>
                                                                      item.report_group_id ===
                                                                      row.values
                                                                          .report_group_id,
                                                              )
                                                              .reduce(
                                                                  (sum, item) =>
                                                                      sum +
                                                                      (parseFloat(
                                                                          item.m4_kwh,
                                                                      ) || 0),
                                                                  0,
                                                              ),
                                                      ) + " kWh "}
                                                {categoryRows.find(
                                                    (val) =>
                                                        val.report_group_id ===
                                                        row.values
                                                            .report_group_id,
                                                ).m4_w_gal_total > 0 &&
                                                !showDollars
                                                    ? nf.format(
                                                          memoReports
                                                              .filter(
                                                                  (item) =>
                                                                      item.report_group_id ===
                                                                      row.values
                                                                          .report_group_id,
                                                              )
                                                              .reduce(
                                                                  (sum, item) =>
                                                                      sum +
                                                                      (parseFloat(
                                                                          item.m4_w_gal,
                                                                      ) || 0),
                                                                  0,
                                                              ),
                                                      ) + " gal"
                                                    : ""}
                                            </td>
                                            <td
                                                className={`${
                                                    row.values
                                                        .report_group_id === 0
                                                        ? "custom-group-color"
                                                        : "category-header-color"
                                                } text-center `}
                                                key={`td-${row.id}-${i}-7`}
                                            >
                                                {categoryRows.find(
                                                    (val) =>
                                                        val.report_group_id ===
                                                        row.values
                                                            .report_group_id,
                                                ).m5_kwh_total > 0 &&
                                                showDollars
                                                    ? " $ " +
                                                      cf.format(
                                                          memoReports
                                                              .filter(
                                                                  (item) =>
                                                                      item.report_group_id ===
                                                                      row.values
                                                                          .report_group_id,
                                                              )
                                                              .reduce(
                                                                  (sum, item) =>
                                                                      sum +
                                                                      (parseFloat(
                                                                          item.m5,
                                                                      ) || 0),
                                                                  0,
                                                              ),
                                                      )
                                                    : categoryRows.find(
                                                          (val) =>
                                                              val.report_group_id ===
                                                              row.values
                                                                  .report_group_id,
                                                      ).m5_kwh_total > 0 &&
                                                      nf.format(
                                                          memoReports
                                                              .filter(
                                                                  (item) =>
                                                                      item.report_group_id ===
                                                                      row.values
                                                                          .report_group_id,
                                                              )
                                                              .reduce(
                                                                  (sum, item) =>
                                                                      sum +
                                                                      (parseFloat(
                                                                          item.m5_kwh,
                                                                      ) || 0),
                                                                  0,
                                                              ),
                                                      ) + " kWh "}
                                                {categoryRows.find(
                                                    (val) =>
                                                        val.report_group_id ===
                                                        row.values
                                                            .report_group_id,
                                                ).m5_w_gal_total > 0 &&
                                                !showDollars
                                                    ? nf.format(
                                                          memoReports
                                                              .filter(
                                                                  (item) =>
                                                                      item.report_group_id ===
                                                                      row.values
                                                                          .report_group_id,
                                                              )
                                                              .reduce(
                                                                  (sum, item) =>
                                                                      sum +
                                                                      (parseFloat(
                                                                          item.m5_w_gal,
                                                                      ) || 0),
                                                                  0,
                                                              ),
                                                      ) + " gal"
                                                    : ""}
                                            </td>
                                            <td
                                                className={`${
                                                    row.values
                                                        .report_group_id === 0
                                                        ? "custom-group-color"
                                                        : "category-header-color"
                                                } text-center `}
                                                key={`td-${row.id}-${i}-8`}
                                            >
                                                {categoryRows.find(
                                                    (val) =>
                                                        val.report_group_id ===
                                                        row.values
                                                            .report_group_id,
                                                ).m6_kwh_total > 0 &&
                                                showDollars
                                                    ? " $ " +
                                                      cf.format(
                                                          memoReports
                                                              .filter(
                                                                  (item) =>
                                                                      item.report_group_id ===
                                                                      row.values
                                                                          .report_group_id,
                                                              )
                                                              .reduce(
                                                                  (sum, item) =>
                                                                      sum +
                                                                      (parseFloat(
                                                                          item.m6,
                                                                      ) || 0),
                                                                  0,
                                                              ),
                                                      )
                                                    : categoryRows.find(
                                                          (val) =>
                                                              val.report_group_id ===
                                                              row.values
                                                                  .report_group_id,
                                                      ).m6_kwh_total > 0 &&
                                                      nf.format(
                                                          memoReports
                                                              .filter(
                                                                  (item) =>
                                                                      item.report_group_id ===
                                                                      row.values
                                                                          .report_group_id,
                                                              )
                                                              .reduce(
                                                                  (sum, item) =>
                                                                      sum +
                                                                      (parseFloat(
                                                                          item.m6_kwh,
                                                                      ) || 0),
                                                                  0,
                                                              ),
                                                      ) + " kWh "}
                                                {categoryRows.find(
                                                    (val) =>
                                                        val.report_group_id ===
                                                        row.values
                                                            .report_group_id,
                                                ).m6_w_gal_total > 0 &&
                                                !showDollars
                                                    ? nf.format(
                                                          memoReports
                                                              .filter(
                                                                  (item) =>
                                                                      item.report_group_id ===
                                                                      row.values
                                                                          .report_group_id,
                                                              )
                                                              .reduce(
                                                                  (sum, item) =>
                                                                      sum +
                                                                      (parseFloat(
                                                                          item.m6_w_gal,
                                                                      ) || 0),
                                                                  0,
                                                              ),
                                                      ) + " gal"
                                                    : ""}
                                            </td>
                                        </tr>
                                    )}

                                <tr
                                    className={
                                        row.values.tree_id > 1999
                                            ? house_loads
                                            : row.values.energy_user_id === null
                                              ? "tenants-send-bill"
                                              : ""
                                    }
                                    {...row.getRowProps()}
                                    key={`${row.id}-no-${i}`}
                                >
                                    {row.cells.map((cell, j) => {
                                        return (
                                            cell.row.original.report_group_id >
                                                0 &&
                                            !cell.row.original.collapsed &&
                                            (showVacant ? (
                                                cell.row.original
                                                    .energy_user_id === null &&
                                                cell.row.original.tree_id ===
                                                    1000 && (
                                                    <td
                                                        {...cell.getCellProps({
                                                            className:
                                                                cell.column
                                                                    .c_className,
                                                        })}
                                                        key={`cell-${row.id}-${i}-${j}`}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                )
                                            ) : (
                                                <td
                                                    {...cell.getCellProps({
                                                        className:
                                                            cell.column
                                                                .c_className,
                                                    })}
                                                    key={`cell-no-${row.id}-${i}-${j}`}
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            ))
                                        );
                                    })}
                                </tr>
                                {row.isExpanded ? (
                                    <tr>
                                        <td colSpan={visibleColumns.length}>
                                            <ExpandedReportTable
                                                report_bill_id={
                                                    row.values.report_bill_id
                                                }
                                            />
                                        </td>
                                    </tr>
                                ) : null}
                            </Fragment>
                        );
                    })}
                </tbody>
            </Table>

            {showGraphModal && (
                <TenantModal
                    show_graph_modal={showGraphModal}
                    reportRow={reportRow}
                    handleGraphModalClose={handleGraphModalClose}
                    site_tz={sessionStorage.getItem("prev_site_tz")}
                />
            )}

            {showInfoModal && (
                <InfoModal
                    showInfoModal={showInfoModal}
                    groupInfo={groupInfo}
                    handleCloseInfoModal={handleCloseInfoModal}
                />
            )}
        </>
    );
}
