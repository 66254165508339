import React, {Component, Suspense, lazy} from "react";
import Select from "react-select";

//Application parts
import AppFooter from "../AppFooter";
import Navigation from "../Navbar";

/************************************************************************
 ONLY CHANGE THE CODE IN THIS BLOCK BELOW TO ADD A NEW QUERY SELECTION
 ************************************************************************/
//Create an array of the selections IN THE ORDER YOU WANT THEM TO APPEAR
let query_array = [
    //Custom selections in the order they will appear in the selector
    // ['JS file containing code for selection', "Selection description Text"]
    ['BoraProblemMeters', 'The Bora: Problem meters'],
    ['UnionWaterMeters', 'Union Market: Water meter listing'],
    ['MosaicUtilityInvoices', 'Latest Mosaic Utility Invoices'],
    ['zTemplateExampleFunction', 'Full featured query demonstration template.'],
];
/************************************************************************
 ONLY CHANGE THE CODE IN THE BLOCK ABOVE ^^^^^
 ************************************************************************/

export default class Queries extends Component {
    constructor(props) {
        super(props);

        //Create state object
        this.state = {
            query_array: query_array,
            QuerySelect: -1 //-1 to indicate no selection
        };

    }

    handleQueryChange = (e) => {  //When a tab is clicked
        this.setState({QuerySelect: e.value})  //Save the idx of the selection and trigger render
    }

    render() {
        const {
            query_array,
            QuerySelect
        } = this.state;

        let QuerySelectElement
        const QuerySelectNone = () => <></>;
        if (QuerySelect === -1)  //No query selection made yet so nothing to load
            QuerySelectElement = QuerySelectNone
        else  //Load query definition file
            QuerySelectElement = lazy(() => import('./' + query_array[QuerySelect][0]));

        return (
            <>
                <Navigation/>
                <Select
                    className="d-print-none"
                    placeholder="Select a Query"
                    onChange={this.handleQueryChange}
                    options={

                        query_array.map((t, i) => {
                                return (
                                    {
                                        key: 'idx_' + i,
                                        label: <b>{t[1]}</b>,
                                        value: i
                                    }
                                )
                            }
                        )
                    }
                >
                </Select>

                <Suspense fallback={<div>Loading...</div>}>
                    <QuerySelectElement/>
                </Suspense>

                {/* Standard application footer for all pages */}
                <AppFooter/>
            </>
        )
    }

}