// import React, { useState } from "react";
import React, {useEffect, useState} from "react";
//  import React from "react";

import "../styles/Navbar.css";
// import {isMobile} from "react-device-detect";
import {Button, Nav, Navbar} from "react-bootstrap";
import {LogUserAction} from "../helpers/LogUserAction";
import {Fetch} from "../helpers/Fetch";
import {history} from "../helpers/history";
import {svr_name} from "../helpers/AppConstants";

export default function Navigation(_props) {
    //
    let user_data = localStorage.getItem("login_data");
    user_data = JSON.parse(user_data);

    const [unacked_active_alarm, set_unacked_active_alarm] = useState("");
    const [unacked_alarm, set_unacked_alarm] = useState("");

    //defaults to the logged-in user
    const [user_menu, set_user_menu] = useState(JSON.parse(localStorage.getItem("user_menu")));

    //Check for alarms every 5 seconds
    useEffect(() => {
        const timeoutId = setInterval(() => {

            Fetch("alarm_api.php", {
                action: "get_all_alarms",
                option: "refresh_check",
                alarm_tz: "",
                site_id: 0,
            })
                .then((data) => {
                    if (data.status !== "same") {

                        set_unacked_active_alarm(data.unacked_active_alarm);
                        set_unacked_alarm(data.unacked_alarm);

                    }
                })
                .catch((error) => {
                    console.error("Error:", error);
                });
        }, 5000);

        // Cleanup function to clear the timeout if the component unmounts
        return () => clearTimeout(timeoutId);
    }, []); // Empty dependency array ensures the effect runs only once


    //Perform initial alarm status
    if (!unacked_active_alarm) {
        Fetch("alarm_api.php", {
            action: "get_all_alarms",
            option: "clean_start",
            alarm_tz: "",
            site_id: 0,
        })
            .then((data) => {
                if (data.status === "ok") {

                    set_unacked_active_alarm(data.unacked_active_alarm);
                    set_unacked_alarm(data.unacked_alarm);

                }
            })
            .catch((error) => {
                console.log("Error:", error);
            });
    }

    //Check login status every 10 seconds
    useEffect(() => {
        const interval = setInterval(() => {
            if ( //do not run if on these pages
                history.location.pathname !== "/login" &&
                history.location.pathname !== "/register"
            ) {
                Fetch("login.php", {})
                    .then((data) => {
                        if (data.loginStatus !== "same") {

                            //If the status is not the same decide if good or bad. Else do nothing
                            if (data.loginStatus === "good") {
                                // localStorage.setItem(
                                //     "user_menu",
                                //     JSON.stringify(data.user_menu)
                                // );
                                // set_user_menu(data.user_menu)
                            } else {
                                clearInterval(interval); //stop the timer and no longer check login status
                                localStorage.clear();
                                sessionStorage.clear();
                                history.push("/login");
                                LogUserAction("Navigate: user logout timeout");
                            }
                        }
                    })
                    .catch((error) => {
                        console.error("Error:", error);
                    });
            }
        }, 10000);
        return () => clearInterval(interval);
    }, []);

    let doUserLogout = () => {
        history.push("/login");
        sessionStorage.clear();
        localStorage.clear();

        LogUserAction("Navigate: user logout");

        //Logout server
        Fetch("login.php", {
            logout: true,
        })
    };

    let handleUserChange = (e) => {
        sessionStorage.setItem("selected_user_id", e.target.value);
        fetch(svr_name + `login.php`, {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
                // "Accept-Encoding": "gzip"
            },
            body: JSON.stringify({
                user_id: e.target.value,
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                if (data.user_menu && data.user_menu.length > 0) {
                    localStorage.setItem("user_menu", JSON.stringify(data.user_menu));
                    set_user_menu(data.user_menu)
                    history.push("/#");
                }
            });
    };

    let handleInValidLogin = () => {
        history.push("/login");
    };

    let login_data = JSON.parse(localStorage.getItem("login_data"));
    let selected_user = sessionStorage.getItem("selected_user_id");
    // const {image} = this.state;
    const default_user = selected_user
        ? selected_user
        : login_data
            ? login_data.master_user_id
            : handleInValidLogin();

    return (
        // <div className="container-fluid main-container-div">
        <>
            {/* The Login data.user_select is populated in api/login.php */}
            {login_data && login_data.user_select && (
                <select
                    className="col-md-4 col-sm-12"
                    defaultValue={default_user}
                    onChange={handleUserChange}
                >
                    <optgroup
                        className="text-center"
                        label="** Recent logins(60 days) **"
                    >
                        {login_data &&
                            login_data.user_select
                                .sort((a, b) => a.days_last_login - b.days_last_login)
                                .filter(
                                    (val) => val.days_last_login < 60 && val.days_last_login
                                )
                                .map((val, index) => (
                                    <option value={val.user_id} key={index}>
                                        {val.sel_text} - {val.days_last_login} days ago{" "}
                                    </option>
                                ))}
                    </optgroup>
                    <optgroup
                        className="text-center"
                        label="** Old logins(>=60 days) **"
                    >
                        {login_data &&
                            login_data.user_select
                                .sort((a, b) => a.days_last_login - b.days_last_login)
                                .filter((val) => val.days_last_login >= 60)
                                .map((val, index) => (
                                    <option value={val.user_id} key={index}>
                                        {val.sel_text} - {val.days_last_login} days ago{" "}
                                    </option>
                                ))}
                    </optgroup>
                    <optgroup className="text-center" label="** Never Logged In **">
                        {login_data &&
                            login_data.user_select
                                .filter((val) => !val.days_last_login)
                                .map((val, index) => (
                                    <option value={val.user_id} key={index}>
                                        {val.sel_text}
                                    </option>
                                ))}
                    </optgroup>
                </select>
            )}

            <div className="col-12 nav-images">
                {/*Echaser Logo*/}
                <img
                    src="/img/ec_logo.gif"
                    className="ECLogo col-2"
                    alt="EC Logo"
                />
                {/*client Logo*/}
                <img
                    src={"/logos/" + user_data.site_logo}
                    className="CustLogo col-2"
                    height="100"
                    alt={"Customer Logo"}
                />

            </div>

            <Navbar
                className="col-12"
                collapseOnSelect
                expand="lg"
                style={{zIndex: 750}}
            >
                {/*{isMobile && <Navbar.Brand>{current_tab}</Navbar.Brand>}*/}
                <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
                <Navbar.Collapse id="responsive-navbar-nav">
                    <Nav variant="tabs" activeKey={window.location.hash} id="navbarNav">
                        {user_menu.map((menu_item, j) => {
                            return (
                                <Nav.Item key={j}>
                                    <Nav.Link
                                        href={"#/" + menu_item.tab_id}
                                        className={
                                            menu_item.tab_id === "alarms"
                                                ? unacked_active_alarm === "yes"
                                                    ? "alarm-unack"
                                                    : unacked_alarm === "yes"
                                                        ? "alarm-unack"
                                                        : unacked_alarm === "no"
                                                            ? "alarm-ack"
                                                            : "alarm-clear"
                                                : "nav-link"
                                        }
                                        // onClick={() =>
                                        //     this.capture_selected_tab(menu_item)}
                                    >
                                        {menu_item.tab_text}
                                    </Nav.Link>
                                </Nav.Item>
                            )
                        })}
                        <Button
                            key="btn_logout"
                            variant="secondary"
                            onClick={doUserLogout}
                            className="logout-button"
                        >
                            Logout
                        </Button>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </>
    );
}
