import React, {Component} from 'react';
import {Alert, Button, Modal, Table} from "react-bootstrap";
// import {svr_name} from "../../helpers/AppConstants";
import {Fetch} from "../../helpers/Fetch";
import {useTable} from "react-table";
import {useBlockLayout} from "react-table/src/plugin-hooks/useBlockLayout";

class AdminUserSites extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show_sites_modal: false,
            show_edit_modal: false,
            edit_data: {
                site_name: '',
                site_id: ''
            },
            all_site_list: [],
            user_site_list: [],
            site_id: '',
            show_general_alert:false
        }
    }

    componentDidMount() {
        this.handleGetSites();
    }

    componentDidUpdate(prevProps, _prevState, _snapshot) {
        if (prevProps.edited_user_id !== this.props.edited_user_id) {
            this.handleGetSites();
        }
    }

    handleGetSites = () => {
        const {edited_user_id} = this.props;

        //Get complete site list
        let prom0 = Fetch("admin_sites.php", {
            action: "get_site_list"
        }).then(data => {
            return data.data;
        });

        //Get the edited users site list
        let prom1 = Fetch("admin_sites.php", {
            action: "get_user_site_list",
            user_id: edited_user_id
        }).then(data => {
            return data.data;
        });

        Promise.all([prom0, prom1]).then(values => {
            if(values && values[0] ){
                this.setState({
                    all_site_list:  values[0].sort((a, b) => b.site_name.localeCompare(a.site_name)), //Sorted Promise 0 result
                    user_site_list: values[1].sort((a, b) => a.site_name.localeCompare(b.site_name)) //Sorted Promise 1 result

                });
            } else{
                this.setState({
                    show_general_alert:true
                })
            }
        });
    }

    handleShowSitesModal = () => {
        this.setState({
            show_sites_modal: true
        })
    }
    handleCloseSitesModal = () => {
        this.setState({
            show_sites_modal: false
        })
    }

    handleSiteChange = (e) => {
        this.setState({
            site_id: e.target.value
        })
    }

    handleAddSite = () => {
        const {site_id} = this.state
        Fetch("admin_sites.php", {
            action: "add_user_site",
            selected_user_id: this.props.edited_user_id,
            site_id: site_id

        }).then(data => {
            if (data.status === "ok") {
                this.setState({
                    show_sites_modal: false
                }, () => this.handleGetSites())
            }
        }).catch((err) => console.log(err));
    }



    handleDelete = (props) => {
        if (window.confirm('Are you sure you want to remove this site')) {
            Fetch("admin_sites.php", {
                action: "delete_user_site",
                selected_user_id: this.props.edited_user_id,
                site_id: props.row.original.site_id

            }).then(data => {
                if (data.status === "ok") {
                     this.handleGetSites()
                }
            }).catch((err) => console.log(err));
        }
    }

    handleEditModalChange = (e) => {
        const {name, value} = e.target
        this.setState({
            edit_data: {
                [name]: value
            }
        })
    }

    handleShowEditModal = (record) => {
        this.setState({
            show_edit_modal: true,
            edit_data: record
        })
    }
    handleCloseEditSitesModal = () => {
        this.setState({
            show_edit_modal: false
        })
    }

    handleGeneralAlertClose=()=>{
        this.setState({
            show_general_alert:false
        })
    }


    render() {
        const {show_general_alert} =this.state

        const columns = [
            {
                title: 'Action',
                accessor: 'action',
                Cell: props => <div className="text-center me-5 ">{<i className="far fa-trash-alt "
                                                                      onClick={(_e) => this.handleDelete(props)}/>}</div>,
                width:100

            },
            {
                Header: 'Site Id',
                accessor: 'site_id',
                key: 'site_id',
                width:100
            },
            {
                Header: 'Site Name',
                accessor: 'site_name',
                key: 'site_name',
                width:200
            }
        ];

        function TableRender({columns, data}) {

            const {
                getTableProps,
                getTableBodyProps,
                headerGroups,
                rows,
                prepareRow
            } = useTable({
                columns,
                data
            },
                useBlockLayout
            );

            // rows.sort((a, b) => b.site_name.localeCompare(a.site_name))
            return (
                <Table //Bootstrap table settings
                    striped
                    bordered
                    hover
                    responsive
                    size="sm"
                    // variant="dark"
                    {...getTableProps()}
                >
                    <thead>
                    {headerGroups.map(headerGroup => (
                        <tr key={"header"} {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th key={column.key} {...column.getHeaderProps()}>
                                    {column.render("Header")}
                                </th>
                            ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    {rows.map((row, i) => {
                        prepareRow(row);
                        return (
                            <React.Fragment key={i}>
                                <tr key={row.values.site_id} {...row.getRowProps()}>
                                    {row.cells.map(cell => {
                                        return (
                                            <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                                        );
                                    })}
                                </tr>
                            </React.Fragment>
                        );
                    })}
                    </tbody>
                </Table>
            );
        }

        const {show_sites_modal, show_edit_modal, edit_data, user_site_list, all_site_list} = this.state
        let filtered_sites = all_site_list.sort((a, b) => a.site_name && a.site_name.localeCompare(b.site_name)).filter(item => !user_site_list.some(val => val.site_id === item.site_id))
        return (
            <div>
                {show_general_alert &&
                <Alert variant="danger" className="text-center mt-2" onClose={this.handleGeneralAlertClose}
                       dismissible>
                    Something went wrong if this unexpected contact your administrator!
                </Alert>
                }
                <div className="offset-4">
                    <button className="btn btn-primary tab-child mb-2" onClick={this.handleShowSitesModal}>Add Site
                    </button>
                    <TableRender columns={columns} data={user_site_list}/>
                </div>
                <Modal
                    show={show_sites_modal}
                    onHide={this.handleShowSitesModal}
                    size="lg"
                >
                    <Modal.Header>
                        <Modal.Title>
                            Add Site to User
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <select className="form-select" onChange={this.handleSiteChange}>
                            <option>Select a site</option>
                            {filtered_sites && filtered_sites.map((element, index) => (
                                <option key={index} value={element.site_id}>{element.site_name}</option>
                            ))

                            }
                        </select>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.handleCloseSitesModal}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={this.handleAddSite}
                        >
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
                <Modal
                    show={show_edit_modal}
                    onHide={this.handleShowEditModal}
                    size="lg"
                >
                    <Modal.Header>
                        <Modal.Title>
                            Edit User Tabs
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <label>Site Name</label>
                        <input type="text" className="form-control" value={edit_data.site_name} name="site_id"
                               onChange={this.handleEditModalChange}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={this.handleCloseEditSitesModal}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={this.handleCloseEditSitesModal}
                        >
                            Save Changes
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    }
}

export default AdminUserSites;