const NavigationButton = ({ offset, handleClick, disabled }) => (
    <button
        className="btn btn-primary btn-sm button-bar-button mx-2"
        disabled={disabled}
        onClick={(e) => handleClick(e, offset)}
    >
        {offset < 0 ? (
            <>
                <span className="fas fa-chevron-right" />
                &nbsp;{Math.abs(offset)}
            </>
        ) : (
            <>
                {offset}&nbsp;
                <span className="fas fa-chevron-left" />
            </>
        )}
    </button>
);

export default NavigationButton;
