import React, { Component } from "react";
import { Fetch } from "../../helpers/Fetch";
import Select from "react-select";
import AdminAlarmsTable from "./AdminAlarmsTable";

class AdminAlarms extends Component {
  constructor(props) {
    super(props);
    this.state = {
      site_id: sessionStorage.getItem("prev_site_id") || 0,
      alarm_dd_data: [],
      alarm_user_data: [],
      alarm_data_rows: [],
      alarm_type_data: [],
      alarm_group_id: "",
    };
  }

  componentDidUpdate(prevProps, _prevState, _snapshot) {
    if (prevProps.edited_user_id !== this.props.edited_user_id) {
      this.get_alarm_group_data();
    }
  }

  componentDidMount() {
    this.get_alarm_group_data();
  }

  get_alarm_group_data = () => {
    Fetch("admin_alarm.php", {
      action: "get_alarm_user_groups",
      edited_user_id: this.props.edited_user_id,
    })
      .then((data) => {
        if (data.status === "ok") {
          this.setState({
            alarm_user_data: data.data,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  get_admin_alarm_rows = () => {
    Fetch("admin_alarm.php", {
      action: "get_admin_alarm_rows",
      alarm_group_id: this.state.alarm_group_id,
      edited_user_id: this.props.edited_user_id,
    }).then((data) => {
      this.setState({
        alarm_data_rows: data.data,
      });
    });
  };

  handleAlarmChange = (e) => {
    this.setState(
      {
        alarm_group_id: e.value,
      },
      () => this.get_admin_alarm_rows()
    );
  };

  render() {
    const { alarm_user_data, alarm_data_rows, alarm_group_id } = this.state;

    const alarm_select_options = alarm_user_data.map((val) => ({
      value: val.alarm_group_id,
      label: val.site_name + " - " + val.alarm_group_name,
    }));

    return (
      <div>
        <div className="offset-4 col-4">
          <Select
            options={alarm_select_options}
            className="mb-3"
            onChange={this.handleAlarmChange}
          />
        </div>

        <div className="d-flex align-items-center justify-content-center">
          {alarm_data_rows.length > 0 && (
            <AdminAlarmsTable
              data={alarm_data_rows}
              edited_user_id={this.props.edited_user_id}
              alarm_group_id={alarm_group_id}
            />
          )}
        </div>
      </div>
    );
  }
}

export default AdminAlarms;
