import React, { useState, useEffect, useCallback, useMemo } from "react";
import Navigation from "../Navbar";
import SiteSelect from "../Global/Site_select2";
import GroupSelect from "../Global/GroupSelect";
import { Fetch } from "../../helpers/Fetch";
import TenantInfoTable from "./TenantInfoTable";
import { Alert } from "react-bootstrap";

const TenantInfo = () => {
    const [tenantV3Data, setTenantV3Data] = useState([]);
    const [siteId, setSiteId] = useState(
        sessionStorage.getItem("prev_site_id")
    );
    const [tenantInfoData, setTenantInfoData] = useState([]);
    const [selectedReportGroupId, setSelectedReportGroupId] = useState(0);
    const [offset, _setOffset] = useState(0);
    const [disablePagination, setDisablePagination] = useState(false);
    const [loading, setLoading] = useState(true);
    const [alertType, setAlertType] = useState("");
    const [alertMessage, setAlertMessage] = useState("");

    const data = useMemo(() => {
        if (selectedReportGroupId === 0) {
            return { tenantInfoData, tenantV3Data };
        } else {
            const filteredTenantV3Data = tenantV3Data.filter(
                ({ report_group_id }) => {
                    return report_group_id === selectedReportGroupId;
                }
            );

            const reportBillIds = filteredTenantV3Data.map(
                ({ report_bill_id }) => report_bill_id
            );

            const filteredInfoData = tenantInfoData.filter((t) =>
                reportBillIds.includes(t.report_bill_id)
            );

            return {
                tenantInfoData: filteredInfoData,
                tenantV3Data: filteredTenantV3Data,
            };
        }
    }, [selectedReportGroupId, tenantInfoData, tenantV3Data]);

    const updateReportName = useCallback((val) => {
        if (!val) return;

        sessionStorage.setItem("prev_group_id", val.value);
        sessionStorage.setItem("prev_group_name", val.label);
        sessionStorage.setItem("rgid", val.value);
        setSelectedReportGroupId(val.value);
    }, []);

    const handleAlert = (type, message) => {
        if (type && message) {
            setAlertType(type);
            setAlertMessage(message);
        }
    };

    useEffect(() => {
        if (alertType.length > 0) {
            const timer = setTimeout(() => {
                setAlertType(""); // Reset alertType after 3 seconds
            }, 3000);

            // Clean up the timer when component unmounts or alertType changes
            return () => clearTimeout(timer);
        }
    }, [alertType]);

    /**
     * Memoized getTenantsInfoData function
     */
    const getTenantsInfoData = useCallback(
        async () => {
            setLoading(true);
            try {
                const data = await Fetch("tenant_info_api.php", {
                    action: "fill_tenant_info_table",
                    site_id: siteId,
                    viewConsump: "all",
                    offset: offset,
                });

                if (data.status === "ok") {
                    setTenantInfoData(data.data1);
                    let massagedDates = data.dates;
                    while (massagedDates.length < 6) {
                        massagedDates.push("");
                    }

                    setTenantV3Data(data.data2);
                    sessionStorage.removeItem("prev_group_id");
                    sessionStorage.removeItem("prev_group_name");

                    setSelectedReportGroupId(0);
                    setDisablePagination(data.dates.length < 6);
                } else {
                    // console.log("Fetch ERROR", data);
                }
            } catch (error) {
                console.error(error);
            } finally {
                setLoading(false);
            }
        },
        //eslint-disable-next-line
        [siteId, offset]
    );

    useEffect(() => {
        if (siteId) {
            getTenantsInfoData();
        }
    }, [getTenantsInfoData, siteId]);

    /**
     * Memoized handleSiteChange function
     */
    const handleSiteChange = useCallback(
        (e) => {
            setSiteId(e.site_id);
            setLoading(true);

            getTenantsInfoData();
        },
        [getTenantsInfoData]
    );

    //
    /**
     * Memoized handleTableRefresh function
     */
    const handleTableRefresh = useCallback(() => {
        setLoading(true);
        getTenantsInfoData();
    }, [getTenantsInfoData]);

    const defaultSortBy = [{ id: "Apartment", desc: false }]; // Default sort
    const hiddenColumns = ["report_bill_id"]; // No columns hidden in display

    return (
        <div>
            {alertType.length > 0 && (
                <Alert variant={alertType}>{alertMessage}</Alert>
            )}
            <Navigation />
            <div className="row">
                <div className="container react-select-report-runs ">
                    <SiteSelect handleSiteChange={handleSiteChange} />
                    {siteId && (
                        <GroupSelect
                            handleGroupSelect={(e) => updateReportName(e)}
                            site_id={siteId}
                        />
                    )}
                </div>
            </div>
            <div>
                {tenantInfoData.length > 0 && (
                    <TenantInfoTable
                        data={data.tenantInfoData}
                        tenant_v3_data={data.tenantV3Data}
                        defaultSortBy={defaultSortBy}
                        hiddenColumns={hiddenColumns}
                        disable_pagination={disablePagination}
                        refresh_table={handleTableRefresh}
                        loading={loading}
                        onMessage={handleAlert}
                    />
                )}
            </div>
        </div>
    );
};

export default TenantInfo;
