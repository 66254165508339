import React, {Component} from "react";
import Navigation from "../Navbar";
import SiteSelect from "../Global/Site_select2";
import PeriodSelect from "../Global/Period_select";
import {Fetch} from "../../helpers/Fetch";
import Select from "react-select";
import ExtraLineTable from "./ExtraLineTable"
import "./Extra_Line_Items.css";
import {Button, Modal} from "react-bootstrap";

export default class Extra_Line_Items extends Component {
    constructor(props) {
        super(props);
        this.state = {
            period_select_data: [],
            site_id: sessionStorage.getItem("prev_site_id"),
            rate_id: sessionStorage.getItem("prev_rate_id"),
            show_create_modal: false,
            show_copy_from_modal: false,
            select_extras: [],
            selected_charge: {},
            quantity: 1,
            charge_amount: 0,
            charge_amount_error: false,
            item_description: '',
            item_description_error: false,
            rate_effective_id: sessionStorage.getItem("prev_reid_select"),
            extra_charge_added: false
        };
    }

    handleExtraChargeFormSave = () => {
        const {
            rate_effective_id,
            quantity,
            item_description,
            charge_amount,
            selected_charge
        } = this.state;

        const {report_bill_id, energy_user_id} = selected_charge

        Fetch("extra_line_items_api.php",
            {
                action: "new_extra_charge",
                rate_effective_id: rate_effective_id,
                energy_user_id: energy_user_id,
                report_bill_id: report_bill_id,
                quantity: !!quantity ? quantity : 1,
                item_description: item_description,
                charge_amount: !!charge_amount ? charge_amount : 0
            }
        ).then(() => {
            this.setState({
                extra_charge_added: true
            });
            this.handleHideCreateModal()
        });
    };

    handleValidateSave = () => {
        const {item_description} = this.state

        this.setState({item_description_error: !item_description.trim()})

        if (!!item_description) {
            this.handleExtraChargeFormSave();
        }
    };

    handleExtraChargeAdded = () => {
        this.setState({extra_charge_added: false})
    }

    handleChargeFormChange = (e) => {
        const {name, value} = e.target;
        this.setState({
            [name]: value,
        });
    };

    getData = () => {
        Fetch("extra_line_items_api.php", {
            action: "fill_new_extras",
            site_id: this.state.site_id,
            rate_effective_id: this.state.rate_effective_id,
        }).then((fdata) => {
            if (fdata.status === "ok") {
                this.setState({select_extras: fdata.data})
            }
        });
    }

    componentDidMount() {
        const {rate_id} = this.state
        if (rate_id) {
            this.handle_site_change()
        }
    }

    handle_site_change = (e) => {
        if (e !== undefined) {
            if (String(e.rate_id) !== String(this.state.rate_id)) {
                this.setState({rate_effective_id: ''})
            }

            this.setState({
                site_id: e.site_id,
                rate_id: e.rate_id,
            });
        }
    };

    handle_select_charge = (e) => {
        const options = this.state.select_extras.filter((item) => item.o_text === e.value)
        if (options.length > 0)
            this.setState({
                selected_charge: options[0],
            });
    }

    handleCreate = () => {
        this.setState({
            show_create_modal: true,
        });
        this.getData()
    };
    /**
     * Perform copy from previous REID to new REID
     */
    handleCopyFromCopy= () => {
        const {
            rate_effective_id,  //copy to
            copy_from_reid,  //copy from
        } = this.state;

        Fetch("extra_line_items_api.php",
            {
                action: "copy_extra_charges",
                rate_effective_id: rate_effective_id,
                copy_from_reid: copy_from_reid
            }
        ).then(() => {
            //Update state and Close modal
            this.setState({
                show_copy_from_modal: false,
            });
        });

    }
    handleCopyFromShow = () => {
        this.setState({
            show_copy_from_modal: true,
        });

    };

    handleHideCopyFromModal = () => {
        this.setState({
            show_copy_from_modal: false,
        });
    }

    handleHideCreateModal = () => {
        this.setState({
            show_create_modal: false,
            selected_charge: {},
            quantity: 1,
            charge_amount: 0,
            item_description: '',
            quantity_error: '',
            item_description_error: false,
            charge_amount_error: false,
        });
    };

    handlePeriodChange = (e) => {
        // const {rate_effective_id} = this.state;
        // const reid_select = e && e.value ? e.value : rate_effective_id

        this.setState({
            rate_effective_id: e.target.value
        });

        // sessionStorage.setItem("prev_period_label", e.label);
        sessionStorage.setItem("prev_reid_select", e.target.value);
    };

    handleCopyPeriodChange= (e) => {
        this.setState({
            copy_from_reid: e.target.value,
        });

    };

    render() {
        const {
            rate_effective_id,
            site_id,
            show_create_modal,
            show_copy_from_modal,
            copy_from_reid,
            quantity,
            charge_amount,
            select_extras,
            item_description,
            selected_charge,
            item_description_error,
            extra_charge_added,
            rate_id,
            charge_amount_error
        } = this.state;

        const select_charge_options = select_extras.map((val) => ({
            label: val.o_text,
            value: val.o_text,
        }))

        return (
            <>
                <Navigation/>
                {/* Button bar */}
                <div className="row">
                    <div className="col-6 col-lg-4 offset-lg-2">
                        <div className="input-group">
                            <label htmlFor="select_site" className="mt-3 me-2">
                                Select Site
                            </label>
                            <div className="col-12 col-lg-9">
                                <SiteSelect handleSiteChange={this.handle_site_change}/>
                            </div>
                        </div>
                    </div>
                    <div className="col-6 col-lg-6">
                        <div className="input-group">

                            <PeriodSelect
                                handlePeriodChange={this.handlePeriodChange}
                                selectedPeriod={rate_effective_id}
                                rate_id={rate_id}
                            />
                            <div className="d-flex justify-content-center pt-2">
                                <button
                                    disabled={!rate_effective_id}
                                    className="btn btn-primary btn-sm button-bar-button mx-2"
                                    onClick={() => this.handleCreate()}
                                >
                                    Create
                                </button>
                            </div>
                            <div className="d-flex justify-content-center pt-2">
                                <button
                                    disabled={!rate_effective_id}
                                    className="btn btn-primary btn-sm button-bar-button mx-2"
                                    onClick={() => this.handleCopyFromShow()}
                                >
                                    Copy From
                                </button>
                            </div>
                        </div>
                    </div>
                    <Modal show={show_copy_from_modal} onHide={this.handleHideCopyFromModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Copy charges from another period</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className="container-fluid">
                                <div className="row">
                                    <PeriodSelect
                                        handlePeriodChange={this.handleCopyPeriodChange}
                                        selectedPeriod={copy_from_reid}
                                        rate_id={rate_id}
                                        dont_show={[Number(rate_effective_id)]}
                                    />
                                    <p className="h3 text-danger">This operation will delete and replace all existing charges!!</p>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleHideCopyFromModal}>
                                Cancel
                            </Button>
                            <Button variant="danger" disabled={!this.state.copy_from_reid}
                                    onClick={this.handleCopyFromCopy}>
                                Copy
                            </Button>
                        </Modal.Footer>

                    </Modal>
                    <Modal show={show_create_modal} onHide={this.handleHideCreateModal}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Extra Charge</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Select
                                name="extras"
                                className="form-control"
                                options={select_charge_options}
                                onChange={this.handle_select_charge}
                            />
                            <form className="col-md-12">
                                <div className="row">
                                    <div className="col-12">
                                        <div className="row justify-content-center mt-2">
                                            <label
                                                htmlFor="quantity"
                                                className="col-md-3 col-form-label"
                                            >
                                                Quantity
                                            </label>
                                            <div className="col-md-4">
                                                <input
                                                    type="number"
                                                    max="100000"
                                                    className="form-control"
                                                    name="quantity"
                                                    value={quantity}
                                                    id="quantity"
                                                    onChange={this.handleChargeFormChange}
                                                />
                                            </div>
                                        </div>
                                        <div className="row justify-content-center mt-2">
                                            <label
                                                htmlFor="charge_amount"
                                                className="col-md-3 col-form-label"
                                            >
                                                Unit Charge
                                            </label>
                                            <div className="col-md-4">
                                                <input
                                                    type="number"
                                                    max="100000"
                                                    className="form-control"
                                                    name="charge_amount"
                                                    value={charge_amount}
                                                    id="charge_amount"
                                                    onChange={(e) => {
                                                        if (e.target.value <= 0) this.setState({charge_amount_error: true})
                                                        else this.setState({charge_amount_error: false})
                                                        this.handleChargeFormChange(e)
                                                    }}
                                                />
                                                {charge_amount_error &&
                                                    <span
                                                        className='text-danger'>The unit charge must be greater than 0</span>
                                                }
                                            </div>
                                        </div>
                                        <div className="row justify-content-center mt-2">
                                            <label
                                                htmlFor="item_description"
                                                className="col-md-3 col-form-label"
                                            >
                                                Description
                                            </label>
                                            <div className="col-md-4">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    name="item_description"
                                                    value={item_description}
                                                    id="item_description"
                                                    onChange={this.handleChargeFormChange}
                                                    required
                                                />
                                                {item_description_error &&
                                                    <span className='text-danger'>The item description field is required.</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" onClick={this.handleHideCreateModal}>
                                Cancel
                            </Button>
                            <Button variant="primary" disabled={charge_amount <= 0 || !selected_charge.energy_user_id}
                                    onClick={this.handleValidateSave}>
                                Save Changes
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
                {rate_effective_id && site_id && (
                    <ExtraLineTable
                        rate_effective_id={rate_effective_id}
                        site_id={site_id}
                        extra_charge_added={extra_charge_added}
                        handleExtraChargeAdded={this.handleExtraChargeAdded}
                    />
                )}
            </>
        );
    }
}
