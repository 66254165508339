import React from 'react';
import Navigation from "../Navbar";
import AppFooter from "../AppFooter";

export default function Home(_props) {
    let _user_data = JSON.parse(localStorage.getItem("login_data"));

    //Previous selection from other pages
    let prev_site_id = sessionStorage.getItem("prev_site_id");
    let prev_site_name = sessionStorage.getItem("prev_site_name");
    let prev_site_notes = sessionStorage.getItem("prev_site_notes");
    let prev_site_tz = sessionStorage.getItem("prev_site_tz");

    let prev_rate_id = sessionStorage.getItem("prev_rate_id");
    let prev_reid_select	= sessionStorage.getItem("prev_reid_select");

    return (
        <>
            <Navigation/>
            <>
                {/* Example: All page content goes here*/}
                <h1 className="col-12 text-center">Home Page</h1>

                <ul className="list-group col-6 offset-3 text-center">
                    <li className="list-group-item"><b><u>Previous Site Information</u></b></li>
                    <li className="list-group-item">prev_site_id: {prev_site_id}</li>
                    <li className="list-group-item">prev_site_name: {prev_site_name}</li>
                    <li className="list-group-item">prev_site_notes: {prev_site_notes}</li>
                    <li className="list-group-item">prev_site_tz: {prev_site_tz}</li>
                    <li className="list-group-item"><b><u>Rate Information</u></b></li>
                    <li className="list-group-item">prev_rate_id: {prev_rate_id}</li>
                    <li className="list-group-item">prev_reid_select (rate_effective_id): {prev_reid_select}</li>
                </ul>
            </>
            <AppFooter/>
        </>
    );
}

// Old component method
// export default class Home extends Component {
//     render() {
//         return (
//             <div>
//                 <Navigation/>
//                 <div className="container-fluid main-container-div">
//                     <h1 className="col-12 text-center">Home</h1>
//                 </div>
//                 <AppFooter/>
//             </div>
//         );
//     }
// }

