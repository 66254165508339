import React, {
    useState,
    useEffect,
    useMemo,
    Fragment,
    useCallback,
} from "react";
import { useTable, useSortBy, useExpanded } from "react-table";
import EditDevice from "./EditDevice";
import ExportDevice from "./ExportDevice";
import { Table, FormCheck, Tooltip, OverlayTrigger } from "react-bootstrap";
import { Fetch } from "../../helpers/Fetch";
import dayjs from "dayjs";
import { globalDateFormat } from "../../helpers/GlobalDateFormat";
import { NumberFormat } from "../../helpers/NumberFormat";

let duration = require("dayjs/plugin/duration");
dayjs.extend(duration);

/**
 * This is a Functional Component, not a Class Component
 * https://www.twilio.com/blog/react-choose-functional-components
 * @param props
 * @returns {JSX.Element}
 */
const DeviceTable3 = ({ row, siteTz, showDisabledDevices }) => {
    const userTimezone = useMemo(() => {
        const storedUserData = localStorage.getItem("login_data");
        if (storedUserData) {
            try {
                const parsedUserData = JSON.parse(storedUserData);
                return parsedUserData.user_tz;
            } catch (error) {
                console.error("Error parsing user data:", error);
                return null;
            }
        }
        return null;
    }, []);

    const [devices1, setDevices] = useState([]);
    const [rowdata, setRowdata] = useState(null);
    const [isExportDevice, setIsExportDevice] = useState(false);
    const [exportData, setExportData] = useState(null);

    const fetch_site_devices = useCallback(async () => {
        try {
            const data = await Fetch("devices_api.php", {
                action: "get_cid_devices",
                collector_id: row.collector_id,
            });
            if (data && data.status === "ok") {
                setDevices(data.devices);
            } else {
                console.error("error", data);
            }
        } catch (error) {
            console.error(error);
        }
    }, [row.collector_id]);

    useEffect(() => {
        fetch_site_devices();
        //eslint-disable-next-line
    }, [row.collector_id]);

    const handleEditDevice = useCallback(
        async (status) => {
            /* Show modal when rowdata is not null
                                status will either be a click event (evaluates to true) or false
                                A click event means load rowdata and open the modal
                                false indicates that the modal has closed. Set rowdata to null.
                                 */

            if (status) {
                //Click event
                setRowdata(status.target.attributes.rowdata.value);
            } else {
                //return from modal
                setRowdata(null);
                await fetch_site_devices();
            }
        },
        [fetch_site_devices],
    );

    const onClickExport = useCallback((e) => {
        setExportData(e.target.attributes.rowdata.value);
        setIsExportDevice(true);
    }, []);

    const onCloseExportModal = useCallback(() => {
        setExportData(null);
        setIsExportDevice(false);
    }, []);

    const toggleDeviceEnabled = useCallback(
        async (e) => {
            let row = JSON.parse(e.target.attributes.rowdata.value);

            //Toggle enabled value
            row.enabled = !row.enabled;

            try {
                const data = await Fetch("devices_api.php", {
                    action: "update_device",
                    device: JSON.stringify(row), //Send
                    updateCollector: "true", //Send true and false as text
                });
                if (data && data.status === "ok") {
                    await fetch_site_devices();
                } else {
                    console.error("error", data);
                }
            } catch (error) {
                console.error(error);
            }
        },
        [fetch_site_devices],
    );

    const GenerateAlarmIcon = useCallback(
        (props) => {
            const row = props.row;

            //Set defaults
            let st = { color: "black", cursor: "pointer" };
            let i = "fas fa-bell m-0";
            let t = null;
            let onClickFunc = null;

            if (row.device_type_id === 18) {
                //if PXMP point, no alarm config icon
                return null;
            } else {
                if (row.ac_alarm_enabled === null) {
                    //No alarm configuration - black fa-bell-slash
                    i = "fas fa-bell-slash ps-1 m-0";
                    t = "No alarm configuration!\nClick to configure.";
                    onClickFunc = handleEditDevice;
                } else {
                    //Alarm configuration does exist
                    i = "fas fa-bell ps-1 m-0";
                    t = "Click to configure alarming.";

                    if (
                        row.enabled &&
                        row.ac_alarm_enabled &&
                        row.alarm_enabled
                    ) {
                        //If both enabled settings are true, set icon color
                        //If not, default to black bell
                        if (!row.in_alarm) {
                            //all good
                            st = { color: "green", cursor: "pointer" };
                        } else if (
                            row.in_alarm &&
                            row.alarm_active &&
                            !row.alarm_acked
                        ) {
                            //unacked alarm
                            st = { color: "red", cursor: "pointer" };
                        } else if (
                            row.in_alarm &&
                            row.alarm_active &&
                            row.alarm_acked
                        ) {
                            //active but acked
                            st = { color: "yellow", cursor: "pointer" };
                        } else if (
                            row.in_alarm &&
                            !row.alarm_active &&
                            !row.alarm_acked
                        ) {
                            //non-active and unacked
                            st = { color: "blue", cursor: "pointer" };
                        }
                    }
                }
                return (
                    <i
                        onClick={onClickFunc}
                        rowdata={JSON.stringify(row)}
                        title={t}
                        className={i}
                        style={st}
                    ></i>
                );
            }
        },
        [handleEditDevice],
    );

    const memoDevices = useMemo(
        () =>
            devices1.filter(
                (device) => device.enabled === !showDisabledDevices,
            ),
        [devices1, showDisabledDevices],
    );

    const columns = useMemo(
        () => [
            {
                accessor: "enabled",
                // c_className: "col",
                Cell: (props) => {
                    return (
                        <Fragment>
                            <div
                                style={{
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            >
                                <FormCheck
                                    onChange={toggleDeviceEnabled}
                                    rowdata={JSON.stringify(props.row.original)}
                                    className="m-0 pe-1"
                                    // disabled
                                    inline
                                    title="Toggle device enabled."
                                    checked={props.value}
                                />
                                <i
                                    onClick={handleEditDevice}
                                    rowdata={JSON.stringify(props.row.original)}
                                    className="fas fa-edit ps-1 m-0"
                                    title="Click to edit device configuration."
                                    style={{ cursor: "pointer" }}
                                ></i>
                                <GenerateAlarmIcon
                                    row={props.row.original}
                                ></GenerateAlarmIcon>
                                <i
                                    rowdata={JSON.stringify(props.row.original)}
                                    className="far fa-arrow-alt-circle-down ps-1"
                                    onClick={onClickExport}
                                    title="Click to export device."
                                    style={{ cursor: "pointer" }}
                                ></i>
                            </div>
                        </Fragment>
                    );
                },
            },
            {
                id: "tree_id",
            },
            {
                id: "device_id",
                accessor: "device_id",
                Header: "DID",
            },
            {
                id: "device_name",
                accessor: "device_name",
                Header: "Device Name",
                // width: 500,
                sortType: (rowA, rowB) => {
                    const a = rowA.values.device_name;
                    const b = rowB.values.device_name;
                    if (a == null || b == null) {
                        return 0;
                    }
                    // Function to extract numbers from strings and return an array
                    const parseDeviceName = (name) =>
                        name
                            .split(/(\d+)/)
                            .map((part) =>
                                isNaN(part) ? part : parseInt(part, 10),
                            );

                    const parsedA = parseDeviceName(a);
                    const parsedB = parseDeviceName(b);

                    // Compare parsed arrays element by element
                    for (
                        let i = 0;
                        i < Math.max(parsedA.length, parsedB.length);
                        i++
                    ) {
                        if (parsedA[i] !== parsedB[i]) {
                            if (
                                typeof parsedA[i] === "string" &&
                                typeof parsedB[i] === "string"
                            ) {
                                return parsedA[i].localeCompare(parsedB[i]);
                            } else if (
                                typeof parsedA[i] === "number" &&
                                typeof parsedB[i] === "number"
                            ) {
                                return parsedA[i] - parsedB[i];
                            } else {
                                return typeof parsedA[i] === "string" ? 1 : -1;
                            }
                        }
                    }

                    return 0; // Return 0 if both device names are the same
                },
                Cell: (props) => {
                    return (
                        <div>
                            {props.value}{" "}
                            {props.row.original.enabled !== true && (
                                <span
                                    style={{ color: "red", fontSize: "14px" }}
                                >
                                    Disabled
                                </span>
                            )}
                        </div>
                    );
                },
            },
            {
                id: "designation",
                accessor: "designation",
                Header: "Designation",
                // width: 500,
            },
            {
                accessor: "device_type_name",
                Header: "Device Type",
                c_className: "d-none d-lg-table-cell text-nowrap", //hide on screens smaller than lg
            },
            {
                accessor: "gmt_last_read",
                Header: "Last Read",
                c_className: "text-nowrap",
                Cell: (props) => {
                    const cellVal = props.cell.value;

                    let d = dayjs().unix() - dayjs.utc(cellVal).unix();
                    if (d < 0) {
                        d = 0;
                    }
                    if (d < 3600) return d + " sec ago";
                    else
                        return globalDateFormat(
                            cellVal,
                            undefined,
                            userTimezone,
                        ); //very late - show date
                },
            },
            {
                accessor: "last_read",
                Header: <span className="float-end">Last Read</span>,
                c_className: "text-nowrap",
                Cell: (props) => {
                    return props.row.original.default_param_id ? (
                        <span
                            className="float-end"
                            title={
                                "Last read: " +
                                globalDateFormat(
                                    props.row.original.gmt_last_read,
                                    undefined,
                                    userTimezone,
                                )
                            }
                        >
                            {NumberFormat().format(props.value) +
                                " " +
                                props.row.original.default_param_units}
                        </span>
                    ) : (
                        <span className="float-end">No default</span>
                    );
                },
            },
            {
                accessor: "last_7_days",
                Header: <span className="float-end">Last 7 days</span>,
                c_className: "d-none d-lg-table-cell text-nowrap", //hide on screens smaller than lg
                Cell: (props) => {
                    return props.row.original.default_param_id ? (
                        <span className="float-end">
                            {NumberFormat().format(props.value) +
                                " " +
                                props.row.original.default_param_units}
                        </span>
                    ) : (
                        <span className="float-end"></span>
                    );
                },
            },
            {
                accessor: "last_28_days",
                Header: <span className="float-end">Last 28 days</span>,
                c_className: "d-none d-lg-table-cell text-nowrap", //hide on screens smaller than lg
                Cell: (props) => {
                    return props.row.original.default_param_id ? (
                        <span className="float-end">
                            {NumberFormat().format(props.value) +
                                " " +
                                props.row.original.default_param_units}
                        </span>
                    ) : (
                        <span className="float-end"></span>
                    );
                },
            },
        ],
        [handleEditDevice, onClickExport, toggleDeviceEnabled, userTimezone],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        // visibleColumns,
        // state: { expanded },
    } = useTable(
        {
            columns,
            data: memoDevices,
            autoResetExpanded: false,
            initialState: {
                // hiddenColumns: ["tree_id"],
                sortBy: [
                    // {
                    //     id: "tree_id",
                    //     desc: false,
                    // },
                    {
                        id: "device_name",
                        desc: false,
                    },
                ],
            },
        },
        useSortBy,
        useExpanded,
    );

    return (
        <div
            className="DeviceTable col-xl-12 col-xxl-11 mx-auto"
            style={{
                maxHeight: "1000px",
                overflow: "auto",
                display: "inlineBlock",
            }}
        >
            <Table //Bootstrap table settings
                //bordered
                size="sm"
                {...getTableProps()}
            >
                <thead>
                    {headerGroups.map((headerGroup, i) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={`tr-${i}`}
                        >
                            {headerGroup.headers.map((column, j) => {
                                return (
                                    <th
                                        {...column.getHeaderProps({
                                            className: column.c_className,
                                        })}
                                        key={`th-${i}-${j}`}
                                    >
                                        {column.render("Header")}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {/* Process collector rows */}
                    {rows.map((row, i) => {
                        prepareRow(row);

                        const rd = row.original; //row data
                        return (
                            <Fragment key={"idx_" + row.id}>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            <div>DID: {rd.device_id}</div>
                                            <div>
                                                Type: {rd.device_type_name}
                                            </div>
                                            <div>
                                                Comm: {rd.device_comm_data1}
                                            </div>
                                            <div>
                                                Type ID: {rd.device_type_id}{" "}
                                                Tree ID: {rd.tree_id}
                                            </div>
                                            {rd.device_notes ? (
                                                <Fragment>
                                                    <div>Notes</div>
                                                    <div
                                                        style={{
                                                            borderStyle:
                                                                "solid",
                                                            borderWidth: "1px",
                                                            whiteSpace:
                                                                "pre-line",
                                                        }}
                                                    >
                                                        {rd.device_notes}
                                                    </div>
                                                </Fragment>
                                            ) : null}
                                        </Tooltip>
                                    }
                                    color="black"
                                >
                                    <tr {...row.getRowProps()} key={`tr-${i}`}>
                                        {row.cells.map((cell, j) => {
                                            return (
                                                <td
                                                    {...cell.getCellProps({
                                                        className:
                                                            cell.column
                                                                .c_className,
                                                    })}
                                                    key={`td-${i}-${j}`}
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </OverlayTrigger>
                            </Fragment>
                        );
                    })}
                </tbody>
            </Table>
            <EditDevice handleModalClose={handleEditDevice} rowdata={rowdata} />
            {isExportDevice && (
                <ExportDevice
                    collector_name={row.collector_name}
                    handleModalClose={onCloseExportModal}
                    rowData={exportData}
                    siteTz={siteTz}
                />
            )}
        </div>
    );
};

export default DeviceTable3;
