import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);

/**
 *
 * @param date The date you want formatted
 * @param tz The time zone to format the date. Defaults to browsers TZ
 * @param format The format of the date string. Defaults to "L HH:mm:ss z"
 * @returns {string}
 * Simplest use: globalDateFormat(some_date)  --80% of use
 * Next level: globalDateFormat(some_date, site_tz or logged user TZ) --20% of use
 * Advanced: globalDateFormat(some_date, site_tz, 'L') --only display date --used rarely
 */
export function globalDateFormat(date, format, tz) {
    //If TZ not specified, default to browser TZ
    if (tz == null) {
        tz = Intl.DateTimeFormat().resolvedOptions().timeZone; //default to browsers TZ
    }
    //If format not specified, default to "m/d/yyyy h:m:s AM/PM TZ"
    if (typeof format === "undefined") {
        format = "MM/DD/YYYY HH:mm:ss A";
        const formattedDate = dayjs.utc(date).tz(tz).format(format);

        // Get the timezone abbreviation using Intl.DateTimeFormat
        const timezoneAbbr = new Intl.DateTimeFormat("en-US", {
            timeZone: tz,
            timeZoneName: "short",
        })
            .formatToParts(new Date(date))
            .find((part) => part.type === "timeZoneName").value;
        // Return the formatted date with the timezone abbreviation

        return `${formattedDate} ${timezoneAbbr}`;
    }

    return dayjs.utc(date).tz(tz).format(format);
}
