import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Table } from "react-bootstrap";
import { useTable } from "react-table";
import BadDataGraph from "./BadDataGraph";
import "./Bad_Data.css";

const BadDataTable = ({
    profile_table_data,
    site_tz,
    report_bill_id,
    submit_rbid,
}) => {
    const [tableData, setTableData] = useState(profile_table_data);
    const [deviceId, setDeviceId] = useState("");
    const [defaultParamId, setDefaultParamId] = useState("");

    useEffect(() => {
        setTableData(profile_table_data);
    }, [profile_table_data]);

    const columns = useMemo(
        () => [
            {
                Header: "pid",
                accessor: "default_param_id",
                key: "default_param_id",
            },
            {
                Header: "DID",
                accessor: "device_id",
                key: "device_id",
            },
            {
                Header: "Device Name",
                accessor: "device_name",
                key: "device_name",
            },
            {
                Header: "Collector Name",
                accessor: "collector_name",
                key: "collector_name",
            },
        ],
        [],
    );

    const handleBadDataExpand = useCallback((row) => {
        setDeviceId(row.values.device_id);
        setDefaultParamId(22);
    }, []);

    const detectTablePan = useCallback(() => setDeviceId(""), []);

    const TableRender = ({ columns, data }) => {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
        } = useTable({
            columns,
            data,
            initialState: { hiddenColumns: ["default_param_id"] },
        });

        rows.sort((a, b) => a.values.device_id - b.values.device_id);

        return (
            <div>
                <Table
                    striped
                    bordered
                    hover
                    responsive
                    size="sm"
                    {...getTableProps()}
                >
                    <thead>
                        {headerGroups.map((headerGroup, index) => (
                            <tr
                                {...headerGroup.getHeaderGroupProps()}
                                key={`header_${index}`}
                            >
                                {headerGroup.headers.map((column, j) => (
                                    <th
                                        {...column.getHeaderProps()}
                                        key={`column_${index}_${j}`}
                                    >
                                        {column.render("Header")}
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {rows.map((row, i) => {
                            prepareRow(row);
                            return (
                                <React.Fragment key={`body_${i}`}>
                                    <tr
                                        {...row.getRowProps()}
                                        key={`row-${row.id}`}
                                        onClick={() => handleBadDataExpand(row)}
                                        className={
                                            deviceId === row.values.device_id
                                                ? "highlight_sel_row"
                                                : ""
                                        }
                                    >
                                        {row.cells.map((cell, j) => (
                                            <td
                                                {...cell.getCellProps()}
                                                key={`rowCell_${i}_${j}`}
                                            >
                                                {cell.render("Cell")}
                                            </td>
                                        ))}
                                    </tr>
                                </React.Fragment>
                            );
                        })}
                    </tbody>
                </Table>
                {report_bill_id.length > 0 && (
                    <BadDataGraph
                        site_tz={site_tz}
                        report_bill_id={report_bill_id}
                        submit_rbid={submit_rbid}
                        device_id={deviceId}
                        default_param_id={defaultParamId}
                        detect_table_pan={detectTablePan}
                    />
                )}
            </div>
        );
    };

    return (
        <div>
            <div className="mt-3">
                {tableData.length > 0 && (
                    <TableRender columns={columns} data={tableData} />
                )}
            </div>
        </div>
    );
};

export default BadDataTable;
