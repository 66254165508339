import React, { Component } from "react";
import { useTable } from "react-table";
import { Button, Modal, Table } from "react-bootstrap";
import AlertModal from "../../helpers/AlertModal";

// import moment from "moment-timezone";
import numeral from "numeral";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { svr_name } from "../../helpers/AppConstants";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { Fetch } from "../../helpers/Fetch";
import Dropzone from "react-dropzone";

dayjs.extend(utc);
dayjs.extend(timezone);

class InvoiceTable extends Component {
  constructor(props) {
    super(props);
    this.onDrop = (files) => {
      this.setState({ files });
    };
    this.state = {
      sites: [], //Place to store date for table
      loading: true,
      delete_modal: false,
      consumption: "",
      service_to: "",
      service_from: "",
      utility_name: "",
      bill_date: "",
      showEditModal: false,
      row_data: [],
      runConfirmModal: false, //Used to show the run confirmation modal
      runSelection: "",
      runConfirmModalBody: "",
      files: [],
    };
  }

  //Function to get the table data and save to state
  getUsersData() {
    fetch(
      svr_name + "functions_api.php?action=pop_selectSite&obj_id=selectSite",
      {
        method: "POST",
        credentials: "include",
        mode: "cors",
        headers: {
          "Content-Type": "application/json",
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ loading: false, sites: data.data });
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  handleOpenDeleteModal = (row) => {
    this.setState({
      delete_modal: true,
      row_data: row.original,
    });
  };

  handleDownloadFile = (row) => {
    Fetch("utilityInvoices_api.php", {
      action: "get_pdf_file",
      rates_utility_id: row.original.rates_utility_id,
      bill_date: row.original.bill_date,
    }).then((data) => {
      if (data.status === "ok") {
        this.setState({
          delete_modal: false,
        });
      }
    });
  };

  handleDelete = () => {
    Fetch("utilityInvoices_api.php", {
      action: "delete_utility_bill",
      rates_utility_id: this.state.row_data.rates_utility_id,
      bill_date: this.state.row_data.bill_date,
    }).then((data) => {
      if (data.status === "ok") {
        this.setState({
          delete_modal: false,
        });
      }
    });
  };

  handleCloseDeleteModal = () => {
    this.setState({
      delete_modal: false,
    });
  };

  handleEditModalChange = (e) => {
    const { name, value } = e.target;

    this.setState((values) => ({
      ...values,
      [name]: value,
    }));
  };

  handleSubmitEditData = () => {
    const {
      site_tz,
      rates_utility_id,
      bill_date,
      service_from,
      utility_name,
      service_to,
      consumption,
      current_charges,
      demand,
      bill_id
    } = this.state;

    Fetch("utilityInvoices_api.php", {
      action: "edit_invoice",
      bill_id: bill_id,
      rates_utility_id: rates_utility_id,
      bill_date: dayjs.tz(bill_date, site_tz),
      service_to: dayjs.tz(service_to, site_tz),
      service_from: dayjs.tz(service_from, site_tz),
      consumption: consumption,
      current_charges: current_charges,
      utility_name: utility_name,
      demand:demand
    }).then((data) => {
      if(data.status ==="ok"){
        this.props.handlePeriodChange()
        this.setState({
          showEditModal: false
        })
      }

    });
  };

  handleCloseEditModal = () => {
    this.setState({
      showEditModal: false,
    });
  };

  handleShowEditModal = (row) => {
    this.setState({
      showEditModal: true,
      row_data: row.original,
      utility_name: row.original.utility_name,
      utility_account: row.original.utility_account,
      bill_date: row.original.bill_date,
      service_from: row.original.service_from,
      service_to: row.original.service_to,
      consumption: row.original.consumption,
      current_charges: row.original.current_charges,
      demand:row.original.demand,
      bill_id:row.original.bill_id
    });
  };

  handleRunConfirmConfirmed = () => {
    this.setState({
      runConfirmModal: false,
    });

    Fetch("utilityInvoices_api.php", {
      action: "run_reporting_period",
      rate_effective_id: this.props.rate_effective_id,
      report_group_id: this.state.report_group_id, //Just this group
      run_selection: 1, //Same as Generate reports
    }).then((_data) => {
    });
  };

  //Get the data after loading/mounting
  componentDidMount() {
    this.getUsersData();
  }

  render() {
    //Define the columns
    const accepted_files = {
      "application/pdf": [".pdf"],
    };
    const files = this.state.files.map((file) => (
      <li key={file.name}>
        {file.name} - {file.size} bytes
      </li>
    ));
    const { invoice_group_data, rate_effective_id } = this.props;
    const {
      delete_modal,
      showEditModal,
      utility_name,
      utility_account,
      service_from,
      service_to,
      consumption,
      bill_date,
      current_charges,
      demand
    } = this.state;
    const columns = [
      {
        Header: "Action",
        accessor: "action",
        Cell: (props) => (
          <div className="text-center me-5 ">
            <i
              className="fas fa-edit me-1"
              onClick={() => this.handleShowEditModal(props.row)}
            />
            <i
              className="fa fa-trash"
              style={{ color: "red" }}
              aria-hidden="true"
              onClick={() => this.handleOpenDeleteModal(props.row)}
            />
            <i
              className="fa fa-file-pdf mx-1"
              onClick={() => this.handleDownloadFile(props.row)}
            />
          </div>
        ),
      },
      {
        Header: "Utility Name", //Text of the header
        accessor: "utility_name", //Property name in data
      },
      {
        Header: "Report Group Name", //Text of the header
        show: false,
        accessor: "report_group_name", //Property name in data
      },
      {
        Header: "Account",
        accessor: "utility_account",
      },
      {
        Header: "Param",
        show: false,
        accessor: "param_units",
      },
      {
        Header: "From",
        accessor: "service_from",
        Cell: (props) => (
          <div
            className={
              props.value ? "text-center me-5 " : "text-center me-5 no_invoice"
            }
          >
            {" "}
            {props.value
              ? dayjs.utc(props.value).format("YYYY-MM-DD HH:mm")
              : "No Invoices"}
          </div>
        ),
      },
      {
        Header: "To",
        accessor: "service_to",
        Cell: (props) => (
          <div className="text-center me-5 ">
            {props.value ? dayjs.utc(props.value).format("YYYY-MM-DD HH:mm") : ""}
          </div>
        ),
      },
      {
        Header: "Invoice Date",
        accessor: "bill_date",
        Cell: (props) => (
          <div className="text-center me-5 ">
            {props.value ? dayjs(props.value).format("YYYY-MM-DD") : ""}
          </div>
        ),
      },
      {
        Header: "Consumption",
        accessor: "consumption",
        Cell: (props) => (
          <div className="text-center me-5 ">
            {numeral(props.value).format("0,0") +
              " " +
              props.row.original.param_units}
          </div>
        ),
      },
      {
        Header: "Report Group",
        show: false,
        accessor: "report_group_id",
      },
      {
        Header: "Per Day",
        accessor: "per_day",
        Cell: (props) => (
          <div className="text-center me-5 ">
            {numeral(
              parseInt(props.row.original.consumption) /
                dayjs(props.row.original.service_to).diff(
                  dayjs(props.row.original.service_from),
                  "days"
                )
            ).format("0,0") +
              " " +
              props.row.original.param_units}
          </div>
        ),
      },
      {
        Header: "Demand",
        accessor: "demand",
        Cell: (props) => (
          <div className="text-center me-5 ">
            {"$" + props.value !== "0"
              ? numeral(props.value).format("0,0.00")
              : ""}
          </div>
        ),
      },
      {
        Header: "Notes",
        accessor: "bill_notes",
      },
      {
        // Header: "Date end",
        accessor: "date_end",
      },
      {
        Header: "Charges",
        accessor: "current_charges",
        Cell: (props) => (
          <div className="text-center me-5 ">
            {"$" + numeral(props.value).format("0,0.00")}
          </div>
        ),
      },
      {
        Header: "$ Per Day",
        accessor: "$_per_day",
        Cell: (props) => (
          <div className="text-center me-5 ">
            {"$" +
              numeral(
                parseInt(props.row.original.current_charges) /
                  dayjs(props.row.original.service_to).diff(
                    dayjs(props.row.original.service_from),
                    "days"
                  )
              ).format("0,0.00")}
          </div>
        ),
      },
      {
        Header: "Unit Cost",
        accessor: "per_unit",
        Cell: (props) => (
          <div className="text-center me-5 ">
            {numeral(props.value).format("0,0.0000") +
              " " +
              props.row.original.param_units}
          </div>
        ),
      },
      //Hidden columns
      {
        accessor: "rates_utility_id",
      },
      {
        accessor: "tariff_id",
      },
      {
        accessor: "utility_id",
      },
      {
        accessor: "report_gen_file_name",
      },
    ];

    //Build the table -- Note that this function is generic
    //There is no custom code here that changes with the number of columns
    function TableRender({ columns, data, parentThis }) {
      // Report run confirmed
      const handleRunReportingPeriod = (report_group_id, rate_effective_id) => {

        parentThis.setState({
          runConfirmModal: true, //Show the modal
          // run_selection: 1, //Pass data to run with after confirmation
          report_group_id: report_group_id,
          rate_effective_id: rate_effective_id,

          //Body of the alert message
          runConfirmModalBody: (
            <>
              <h3 className="text-center">
                This operation is VERY CPU intensive!
              </h3>
              Pressing confirm will cause the report to be generated for the
              group. This process will take time to complete.
              <br />
              <br />
              Are you sure?
            </>
          ),
        });
      };

      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = useTable({
        columns,
        data,
        initialState: {
          hiddenColumns: [
            "report_group_name",
            "param_units",
            "report_group_id",
            "bill_notes",
            "rates_utility_id",
            "tariff_id",
            "utility_id",
            "date_end",
            "report_gen_file_name",
          ],
        },
      });

      //sort by report group name and utility name and utility account and service_from
      rows.sort(
        (a, b) =>
          a.values.report_group_name.localeCompare(
            b.values.report_group_name
          ) ||
          a.values.utility_name.localeCompare(b.values.utility_name) ||
          a.values.utility_account.localeCompare(b.values.utility_account) ||
          dayjs(b.values.service_from).unix() -
            dayjs(a.values.service_from).unix()
      );

      return (
        <Table //Bootstrap table settings
          striped
          bordered
          hover
          size="sm"
          // variant="dark"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr key={index} {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, i) => (
                  <th key={i} {...column.getHeaderProps()}>
                    {column.render("Header")}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              //Loop though the rows
              prepareRow(row);

              return (
                <React.Fragment key={i}>
                  {(i === 0 //first row
                    ? true //Produce first category row
                    : row.values.report_group_id !==
                      rows[i - 1].values.report_group_id) && (
                    //If different category/true - produce category row
                    <tr className="text-center invoice_header_row">
                      <td colSpan={12}>
                        Report Group: {row.values.report_group_name}{" "}
                        <button
                          type="button"
                          className="ms-4 btn btn-sm btn-secondary"
                          onClick={() =>
                            handleRunReportingPeriod(
                              row.values.report_group_id,
                              rate_effective_id
                            )
                          }
                        >
                          Run
                        </button>
                        {dayjs(row.values.service_to).isBefore(
                          dayjs(row.values.date_end)
                        ) ? (
                          dayjs()
                            .subtract(3, "months")
                            .isBefore(row.values.date_end) ? (
                            <i
                              className="fa fa-exclamation-triangle mx-1"
                              style={{ color: "red" }}
                              aria-hidden="true"
                            />
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                        {!row.values.service_to &&
                        dayjs()
                          .subtract(3, "months")
                          .isBefore(row.values.date_end) ? (
                          <i
                            className="fa fa-exclamation-triangle mx-1"
                            style={{ color: "red" }}
                            aria-hidden="true"
                          />
                        ) : (
                          ""
                        )}
                      </td>
                    </tr>
                  )}
                  <OverlayTrigger
                    placement="top"
                    overlay={
                      <Tooltip>
                        <div>rgid: {row.values.report_group_id}</div>
                        <div>
                          rates_utility_id: {row.values.rates_utility_id}
                        </div>
                        <div>tariff_id: {row.values.tariff_id}</div>
                        <div>utility_id: {row.values.utility_id}</div>
                        <div>
                          report_gen_file_name:{" "}
                          {row.values.report_gen_file_name}
                        </div>
                        <div>Notes: {row.values.bill_notes}</div>
                      </Tooltip>
                    }
                    color="black"
                  >
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, index) => {
                        return (
                          <td key={index} {...cell.getCellProps()}>
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  </OverlayTrigger>
                </React.Fragment>
              );
            })}
          </tbody>
        </Table>
      );
    }

    return (
      <div>
        <Modal show={showEditModal} onHide={this.handleCloseEditModal}>
          <Modal.Header closeButton>
            <Modal.Title>Invoice Edit</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <label>Utility Name</label>
            <input
              type="text"
              className="form-control "
              value={utility_name}
              disabled
              name="utility_name"
              onChange={this.handleEditModalChange}
            />

            <label>Utility Account</label>
            <input
              type="text"
              className="form-control"
              value={utility_account}
              disabled
              name="utility_account"
              onChange={this.handleEditModalChange}
            />

            <label>Invoice Date</label>
            <input
              disabled
              type="date"
              className="form-control"
              value={dayjs(bill_date).format("YYYY-MM-DD")}
              name="bill_date"
              onChange={this.handleEditModalChange}
            />

            <label>Service From</label>
            <input
              type="date"
              className="form-control"
              value={dayjs(service_from).format("YYYY-MM-DD")}
              name="service_from"
              onChange={this.handleEditModalChange}
            />

            <label>Service To</label>
            <input
              type="date"
              className="form-control"
              value={dayjs(service_to).format("YYYY-MM-DD")}
              name="service_to"
              onChange={this.handleEditModalChange}
            />

            <label>Charges</label>
            <input
              type="number"
              className="form-control"
              value={current_charges}
              name="current_charges"
              onChange={this.handleEditModalChange}
            />

            <label>Demand</label>
            <input
              type="number"
              className="form-control"
              name="demand"
              value={demand}
              onChange={this.handleEditModalChange}
            />

            <label>Consumption</label>
            <input
              type="text"
              className="form-control"
              value={consumption}
              name="consumption"
              onChange={this.handleEditModalChange}
            />
            <div className="mt-3">
              {/*<InvoiceDropZone />*/}
              <span className="baseStyleDropZone">
                <Dropzone
                  onDrop={this.onDrop}
                  accept={accepted_files}
                  maxFiles={3}
                >
                  {({ getRootProps, getInputProps }) => (
                    <section className="container">
                      <div {...getRootProps({ className: "dropzone" })}>
                        <input {...getInputProps()}></input>
                        <p>Drag 'n' drop files here or click to select.</p>
                      </div>
                    </section>
                  )}
                </Dropzone>
              </span>
              <aside>
                <h4>Files</h4>
                <ul>{files}</ul>
              </aside>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseEditModal}>
              Cancel
            </Button>
            <Button variant="primary" onClick={this.handleSubmitEditData}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={delete_modal} onHide={this.handleCloseDeleteModal}>
          <Modal.Header closeButton>
            <Modal.Title>Invoice Delete</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to delete this entry</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseDeleteModal}>
              Cancel
            </Button>
            <Button variant="danger" onClick={this.handleDelete}>
              Delete
            </Button>
          </Modal.Footer>
        </Modal>
        <AlertModal
          show={this.state.runConfirmModal}
          handleCancel={() => {
            this.setState({ runConfirmModal: false });
            //   Just close modal and DO NOTHING
          }}
          modalHeading="CAUTION"
          modalBody={this.state.runConfirmModalBody}
          confirmBtnText="Confirm"
          confirmBtnClick={this.handleRunConfirmConfirmed}
        />

        {invoice_group_data.length > 0 && (
          <TableRender
            columns={columns}
            data={invoice_group_data}
            parentThis={this}
          />
        )}
      </div>
    );
  }
}

export default InvoiceTable;
