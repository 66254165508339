const ToggleCheckbox = ({ label, checked, onChange }) => (
    <>
        <input
            className="mx-2"
            type="checkbox"
            checked={checked}
            onChange={onChange}
        />
        <label className="form-check-label">{label}</label>
    </>
);

export default ToggleCheckbox;
