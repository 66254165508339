import React, { Component } from "react";

import { Accordion } from "react-bootstrap";

import Select from "react-select";

//Application parts
import AppFooter from "../AppFooter";
import Navigation from "../Navbar";
import { Fetch } from "../../helpers/Fetch";
import "./Admin2.css";

/*****************************************************************************************
 Accordion components - one for each accordion tab
 These can be named to suit the application.
 You just need to change the name in the JSX code for the accordion tab below.
 *****************************************************************************************/
import AdminUserProfile from "./AdminUserProfile";
import AdminUserNavigation from "./AdminUserNavigation";
import AdminUserSites from "./AdminUserSites";
import AdminReports from "./AdminReports";
import AdminAlarms from "./AdminAlarms";
import AdminUserLog from "./AdminUserLog";

export default class Admin2 extends Component {
  constructor(props) {
    super(props);

    // *** Set this variable to the number of accordion tabs in JSX
    this.num_tabs = 5;

    //Initialize to stop extra rendering
    // this.stopRender = false;

    /*********************************************************
         Site select code
         Gets the previous site_id values from session storage if they exist
         *********************************************************/
    // this.prev_site_id = sessionStorage.getItem("prev_site_id");
    // this.prev_site_tz = sessionStorage.getItem("prev_site_tz");
    //Generate state object to collapse all tabs initially
    let stateObj = { prevent_collapse: false };
    for (let x = 1; x <= this.num_tabs; x++) {
      stateObj["tab" + x + "_expanded"] = false;
    }

    //Add application specific parameters to state
    stateObj["dataLoaded"] = false;
    stateObj["edited_user_id"] = null;
    stateObj["users"] = [];
    stateObj["disableSelections"] = false;
    stateObj["show"] = false;

    // this.state={
    //   stateObj:{
    //     dataLoaded:false,
    //     edited_user_id:null,
    //     users:[],
    //     disableSelections:false,
    //   },
    //   show_locked_out:false
    // }

    //Create state object
    this.state = stateObj;
  }

  componentDidMount() {
    this.handleGetAllUsers();
  }

  /**
   * This stops a render from occurring when setting state.
   * Example:
   *    this.stopRender = false;
   *    this.setState(...)
   * The setState will not trigger a render
   */
  tab_event = (e) => {

    const tab = e.target.parentElement.parentNode.classList[0];

    let stateObj = {};
    if (e.target.classList[1] === "collapsed") {
      // If the class contains collapsed, it is being expanded!

      //Generate state object to collapse all tabs except the one that matches variable tab
      for (let x = 1; x <= 6; x++) {
        stateObj["tab" + x + "_expanded"] = "tab" + x === tab;
      }

    } else {
      //Tab is being collapsed

      //Collapse all tabs since only one is open at a time
      //Generate state object to collapse all tabs
      for (let x = 1; x <= this.num_tabs; x++) {
        stateObj["tab" + x + "_expanded"] = false;
      }
    }
    //Rerender accordion
    this.setState(stateObj);
  };

  /**
   *   This function is called from tab.js via props
   *   This function is only needed for tabs that require collapse to be restricted
   *   This function prevents all tabs from collapsing. Normally only one tab is open at a time.
   */

  tab_prevent_collapse = (prevent) => {
    if (this.state.prevent_collapse !== prevent) {
      //If there is a change

      //MUST re-render to show warning and prevent collapse
      this.setState({
        prevent_collapse: prevent,
        disableSelections: prevent,
      });
    }
  };

  handleGetAllUsers = (show) => {
    Fetch("admin_profile.php", {
      action: "get_all_users",
      show: show,
    }).then((data) => {
      if (data.status !== "ok") {
        return false;
      } else {
        this.setState({
          users: data.data,
          dataLoaded: true,
        });
      }
    });
  };

  handleUserSelect = (e) => {
    this.handleGetAllUsers();
    this.setState({
      edited_user_id: e.value,
      show_accordion: true,
    });
  };

  collapseFormCancelSubmit = (_e) => {
    this.setState({
      collapse_form: true,
    });
  };

  resetFormCollapse = (_e) => {
    this.setState({
      collapse_form: false,
    });
  };

  toggleLockedOut = (e) => {
    if (e.target.checked) {
      this.handleGetAllUsers("locked_out");
    } else {
      this.handleGetAllUsers("");
    }
  };

  render() {

    const {
      prevent_collapse,
      users, //List of users for the site for the DD
      show_accordion,
      edited_user_id, //The user being edited
    } = this.state;

    //Build options array for select user dropdown
    const user_options = users
      .sort((a, b) => a.org_name && a.org_name.localeCompare(b.org_name))
      .map((val) => ({
        label: val.org_name + " - " + val.fname + " - " + val.username,
        value: val.user_id,
      }));

    return (
      <div className="admin-page">
        <Navigation />
        {/* Standard application header/navbar for all pages */}
        <div className="row">
          <div className="col-2 offset-3 mt-3" style={{ marginRight: "-6%" }}>
            <input
              className="mx-2"
              type="checkbox"
              onChange={this.toggleLockedOut}
            />
            <label className="form-check-label">Locked Out Users</label>
          </div>
          <div className="col-4 pt-1 mb-3">
            <Select
              options={user_options}
              placeholder={"Select a user"}
              onChange={this.handleUserSelect}
            />
          </div>
        </div>

        {show_accordion && (
          <div className="container-fluid">
            <Accordion>
              {/*
           To add more tabs, just copy/paste the Accordion.Item section below.
           Then modify tab# in three places, give it a unique eventKey,
           change header text to suit, and modify this.num_tabs above to the number of tabs.
           */}

              {/* tab1 */}
              <Accordion.Item
                eventKey="1"
                className={"tab1"}
                style={
                  prevent_collapse
                    ? { pointerEvents: "none" }
                    : { pointerEvents: "auto" }
                }
                onClick={this.resetFormCollapse}
              >
                <Accordion.Header onClick={this.tab_event}>
                  Profile
                  <span
                    className="badge bg-warning col-md-5 offset-md-3"
                    style={
                      this.state.tab1_expanded && prevent_collapse
                        ? {
                            display: "inline-block",
                          }
                        : { display: "none" }
                    }
                  >
                    Submit or cancel changes
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  {/* Only render Component if the tab is expanded */}
                  {this.state.tab1_expanded && (
                    <AdminUserProfile
                      preventCollapse={this.tab_prevent_collapse}
                      collapseForm={this.collapseFormCancelSubmit}
                      edited_user_id={edited_user_id}
                      // user_prev_site_id={this.prev_site_id}
                      // user_prev_site_tz={this.prev_site_tz}
                    />
                  )}
                </Accordion.Body>
              </Accordion.Item>

              {/* tab2 */}
              <Accordion.Item
                eventKey="2"
                className={"tab2"}
                style={
                  prevent_collapse
                    ? { pointerEvents: "none" }
                    : { pointerEvents: "auto" }
                }
              >
                <Accordion.Header onClick={this.tab_event}>
                  Navigation
                  <span
                    className="badge bg-warning col-md-5 offset-md-3"
                    style={
                      this.state.tab2_expanded && prevent_collapse
                        ? {
                            display: "inline-block",
                          }
                        : { display: "none" }
                    }
                  >
                    Submit or cancel changes
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  {/* Only render Component if the tab is expanded */}
                  {this.state.tab2_expanded && (
                    <AdminUserNavigation
                      preventCollapse={this.tab_prevent_collapse}
                      edited_user_id={edited_user_id}
                      user_options={user_options}
                    />
                  )}
                </Accordion.Body>
              </Accordion.Item>

              {/* tab3 */}
              <Accordion.Item
                eventKey="3"
                className={"tab3"}
                style={
                  prevent_collapse
                    ? { pointerEvents: "none" }
                    : { pointerEvents: "auto" }
                }
              >
                <Accordion.Header onClick={this.tab_event}>
                  Sites
                  <span
                    className="badge bg-warning col-md-5 offset-md-3"
                    style={
                      this.state.tab3_expanded && prevent_collapse
                        ? {
                            display: "inline-block",
                          }
                        : { display: "none" }
                    }
                  >
                    Submit or cancel changes
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  {/* Only render Component if the tab is expanded */}
                  {this.state.tab3_expanded && (
                    // <div>User site</div>
                    <AdminUserSites
                      preventCollapse={this.tab_prevent_collapse}
                      edited_user_id={edited_user_id}
                      // user_prev_site_id={this.prev_site_id}
                      // user_prev_site_tz={this.prev_site_tz}
                    />
                  )}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item
                eventKey="4"
                className={"tab4"}
                style={
                  prevent_collapse
                    ? { pointerEvents: "none" }
                    : { pointerEvents: "auto" }
                }
              >
                <Accordion.Header onClick={this.tab_event}>
                  Reports
                  <span
                    className="badge bg-warning col-md-5 offset-md-3"
                    style={
                      this.state.tab4_expanded && prevent_collapse
                        ? {
                            display: "inline-block",
                          }
                        : { display: "none" }
                    }
                  >
                    Submit or cancel changes
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  {this.state.tab4_expanded && (
                    <AdminReports edited_user_id={edited_user_id} />
                  )}
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item
                eventKey="5"
                className={"tab5"}
                style={
                  prevent_collapse
                    ? { pointerEvents: "none" }
                    : { pointerEvents: "auto" }
                }
              >
                <Accordion.Header onClick={this.tab_event}>
                  Alarms
                  <span
                    className="badge bg-warning col-md-5 offset-md-3"
                    style={
                      this.state.tab5_expanded && prevent_collapse
                        ? {
                            display: "inline-block",
                          }
                        : { display: "none" }
                    }
                  >
                    Submit or cancel changes
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  {this.state.tab5_expanded && (
                    <AdminAlarms edited_user_id={edited_user_id} />
                  )}
                </Accordion.Body>
              </Accordion.Item>

              <Accordion.Item
                eventKey="6"
                className={"tab6"}
                style={
                  prevent_collapse
                    ? { pointerEvents: "none" }
                    : { pointerEvents: "auto" }
                }
              >
                <Accordion.Header onClick={this.tab_event}>
                  User log
                  <span
                    className="badge bg-warning col-md-5 offset-md-3"
                    style={
                      this.state.tab6_expanded && prevent_collapse
                        ? {
                            display: "inline-block",
                          }
                        : { display: "none" }
                    }
                  >
                    Submit or cancel changes
                  </span>
                </Accordion.Header>
                <Accordion.Body>
                  {this.state.tab6_expanded && (
                    <AdminUserLog edited_user_id={edited_user_id} />
                  )}
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        )}
        {/* Standard application footer for all pages */}
        <AppFooter />
      </div>
    );
  }
}
