import React, {useEffect, useState} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {useForm} from "react-hook-form";
import {Col, Form, Row} from "react-bootstrap";
import {Fetch} from "../../helpers/Fetch";
import {tenant_dates} from "../../helpers/TenantDates";
import {generateYears} from "../../helpers/TenantDates";
import dayjs from "dayjs";

export default function AddNewTenantModal(props) {
    const [show_modal, set_show_modal] = useState(false);
    const [no_end, set_no_end] = useState(false);
    const [form_read_only, set_form_read_only] = useState(false);

    const {register, handleSubmit, reset} = useForm();
    useEffect(() => {
        set_show_modal(props.show_add_new_modal);
        get_edit_occ();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.show_add_new_modal, reset]);

    const get_edit_occ = () => {
        if (props.energy_user_id == null) {
            return;
        }
        Fetch("TenantV3_api.php", {
            action: "GETeditOccupant",
            energy_user_id: props.energy_user_id,
            report_bill_id: props.report_bill_id,
        })
            .then((data) => {
                if (data && data.status === "ok") {
                    if (data.readonly === "true") {
                        set_form_read_only(data.readonly);
                    }
                    let edit_tbl_data = data.data;
                    let from_date = edit_tbl_data["from_date"];
                    let to_date = edit_tbl_data["to_date"];
                    edit_tbl_data["from_date"] = dayjs(from_date).month() + 1;
                    edit_tbl_data["from_year"] = dayjs(from_date).format("YYYY");
                    edit_tbl_data["to_date"] = dayjs(to_date).month() + 1;
                    edit_tbl_data["no_end"] =
                        dayjs(to_date).format("YYYY") > 2039
                            ? set_no_end(true)
                            : set_no_end(false);
                    edit_tbl_data["to_year"] =
                        dayjs(to_date).format("YYYY") > 2039
                            ? dayjs().format("YYYY")
                            : dayjs(to_date).format("YYYY");

                    reset(edit_tbl_data);
                } else {
                    console.error("error", data);
                }
            })
            .catch((err) => console.error(err));
    };

    const onSubmit = (data) => {
        /*Perform whatever code is required to submit */
        Fetch("TenantV3_api.php", {
            action:
                props.modal_type !== "Edit Tenant"
                    ? "submit_new_tenant"
                    : "editOccupantSubmit",
            dont_send_bill: data.dont_send_bill,
            energy_user_id: props.energy_user_id,
            email: data.email,
            email_alert: data.email,
            email_report: data.email,
            fname: data.fname,
            lname: data.lname,
            title: data.user_title,
            no_end: no_end,
            org_name: data.org_name,
            phone_main: data.phone_main,
            square_ft: data.square_ft,
            occ_to_month: data.to_date,
            to_year_num: data.to_year,
            user_title: data.user_title,
            NO_occ_from_month: data.from_date,
            from_year_num: data.from_year,
            cust_field1: data.t_number, //Tenant number
            cust_field2: data.l_number, //Lease number
            site_tz: sessionStorage.getItem("prev_site_tz"),
            report_bill_id: props.report_bill_id,
            site_id: sessionStorage.getItem("prev_site_id"),
            mail_address1: data.mail_address1,
            mail_address2: data.mail_address2,
            mail_city: data.mail_city,
            mail_state: data.mail_state,
            mail_zip: data.mail_zip,

        })
            .then((data) => {
                if (data && data.status === "ok") {
                    props.get_dd_data();
                    props.handle_close_modal();
                } else {
                    console.error("error", data);
                }
            })
            .catch((err) => console.error(err));
    };

    const handleCloseModal = () => {
        props.handle_close_modal();
    };

    const generated_years_array = generateYears(15);

    const handleNoEnd = () => {
        set_no_end(!no_end);
    };


    return (
        <Modal
            show={show_modal} //this can be true because we are checking for data above
            // onHide={() => setData(null)} //When hiding, clear out data
            backdrop="static" //User must press cancel or submit to hide modal
            keyboard={false} //User must press cancel or submit to hide modal
            centered //centered on screen vertically
            size="lg" //Small modal
        >
            <Modal.Header className="add-tenant-modal-header">
                <Modal.Title>
                    {" "}
                    {props.modal_type} {form_read_only ? "(read only)" : ""}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    onSubmit={handleSubmit(onSubmit)}
                    className={form_read_only ? "read-only-form" : ""}
                >
                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center"
                    >
                        <Form.Label column xs={1}>
                            From
                        </Form.Label>
                        <Col sm={2}>
                            <Form.Select {...register("from_date", {required: true})}>
                                {tenant_dates.map((val, index) => (
                                    <option key={index} value={val.value}>
                                        {val.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col sm={2}>
                            <Form.Select
                                {...register("from_year", {required: true})}
                                defaultValue={new Date().getFullYear()}
                            >
                                {generated_years_array.map((val, index) => (
                                    <option key={index} value={val}>
                                        {val}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Form.Label column xs={1}>
                            To
                        </Form.Label>
                        <Col sm={2}>
                            <Form.Select disabled={no_end} {...register("to_date")}>
                                {tenant_dates.map((val, index) => (
                                    <option key={index} value={val.value}>
                                        {val.name}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col sm={2}>
                            <Form.Select
                                disabled={no_end}
                                {...register("to_year")}
                                defaultValue={new Date().getFullYear() + 1}
                            >
                                {generated_years_array.map((val, index) => (
                                    <option key={index} value={val}>
                                        {val}
                                    </option>
                                ))}
                            </Form.Select>
                        </Col>
                        <Col sm={2}>
                            <input
                                className="mt-2 me-2"
                                name="no_end"
                                type="checkbox"
                                checked={no_end}
                                onClick={handleNoEnd}
                                onChange={()=>{}}
                            />
                            <label htmlFor="no_end"> No End</label>
                        </Col>
                    </Form.Group>
                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center"
                    >
                        <Form.Label column md={6} className="mt-2 text-center">
                            Tenant Information - eu_id={props.energy_user_id}
                            <hr/>
                        </Form.Label>
                    </Form.Group>
                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center"
                    >
                        <Form.Label column style={{whiteSpace: "nowrap"}}>
                            Tenant Number
                        </Form.Label>
                        <Col>
                            <Form.Control size="sm" type="text" {...register("t_number")} />
                        </Col>
                        <Form.Label column style={{whiteSpace: "nowrap"}}>
                            Lease Number
                        </Form.Label>
                        <Col>
                            <Form.Control size="sm" type="text" {...register("l_number")} />
                        </Col>
                        <Form.Label column>Square Feet</Form.Label>
                        <Col>
                            <Form.Control size="sm" type="text" {...register("square_ft")} />
                        </Col>
                    </Form.Group>

                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center mt-3"
                    >
                        <Form.Label column sm={2} style={{whiteSpace: "nowrap"}}>
                            Tenant Name
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control size="sm" type="text" {...register("org_name")} />
                        </Col>
                    </Form.Group>

                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center mt-3"
                    >
                        <Form.Label column sm={2}>
                            Main Email
                        </Form.Label>
                        <Col sm={8}>
                            <Form.Control
                                size="sm"
                                type="text"
                                {...register("email", {
                                    required: true,
                                    pattern: /^\S+@\S+$/i,
                                })}
                            />
                        </Col>
                    </Form.Group>

                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center mt-3"
                    >
                        <Form.Label column sm={2} style={{whiteSpace: "nowrap"}}>
                            First Name
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control size="sm" type="text" {...register("fname")} />
                        </Col>
                        <Col/>
                        <Form.Label column sm={2}>
                            Last Name
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control size="sm" type="text" {...register("lname")} />
                        </Col>
                    </Form.Group>
                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center mt-3"
                    >
                        <Form.Label column sm={2} style={{whiteSpace: "nowrap"}}>
                            Title
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control size="sm" type="text" {...register("user_title")} />
                        </Col>
                        <Col/>
                        <Form.Label column sm={2}>
                            Phone
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control
                                size="sm"
                                type="number"
                                {...register("phone_main")}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center mt-3"
                    >
                        <Form.Label column sm={2} style={{whiteSpace: "nowrap"}}>
                            Mail Address 1
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control
                                size="sm"
                                type="text"
                                {...register("mail_address1")}
                            />
                        </Col>
                        <Col/>
                        <Form.Label column sm={2} style={{whiteSpace: "nowrap"}}>
                            Mail Address 2
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control
                                size="sm"
                                type="text"
                                {...register("mail_address2")}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center mt-3"
                    >
                        <Form.Label column sm={2} style={{whiteSpace: "nowrap"}}>
                            Mail City
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control
                                size="sm"
                                type="text"
                                {...register("mail_city")}
                            />
                        </Col>
                        <Col/>
                        <Form.Label column sm={2}>
                            Mail State
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control
                                size="sm"
                                type="text"
                                {...register("mail_state")}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center mt-3"
                    >
                        <Col/>
                        <Form.Label column sm={2} style={{whiteSpace: "nowrap"}}>
                            Mail Zip
                        </Form.Label>
                        <Col sm={3}>
                            <Form.Control
                                size="sm"
                                type="text"
                                {...register("mail_zip")}
                            />
                        </Col>
                        <Col/>
                    </Form.Group>
                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center mt-3"
                    >
                        <Col sm={3}>
                            <Form.Check
                                className="mt-2"
                                inline
                                label="Send Bill To Admin"
                                type="checkbox"
                                {...register("dont_send_bill")}
                            />
                        </Col>
                    </Form.Group>
                    <div className="float-end" style={{pointerEvents: "all"}}>
                        <Button className="btn-sm btn-secondary" onClick={handleCloseModal}>
                            Cancel
                        </Button>
                        <Button type="submit" className="mx-2 btn-sm btn-primary">
                            Submit
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
}
