import { svr_name } from "./AppConstants";

export function s3_upload(
  bucket_name,
  action,
  filename,
  file_mime_type,
  file,
  api_url
) {
  // s3_upload(
  //     "energychaser-utility-invoices", //Bucket name
  //     rates_utility_id + "-" + new_invoice_date.toString() + ".pdf", //filename
  //     "pdf", //file_mime_type
  //     this.state.files //pass array of files to be uploaded
  // );

  let form_data = new FormData();
  form_data.append("action", action); //go away
  form_data.append("bucket", bucket_name); //Rename to tab_id - required
  form_data.append("report", file); //Attach file - required
  form_data.append("file_mime_type", file_mime_type); //optional --needed for S3
  form_data.append("filename", filename); //optional --needed for S3

  fetch(svr_name + api_url, {
    method: "POST",
    credentials: "include",
    mode: "cors",
    body: form_data,
  })
    .then((response) => response.json())
    .then((data) => {
      if (data.status === "ok") {
        return data;
      }
    })
    .catch((error) => {
      console.error("Error:", error);
      return error;
    });
}
