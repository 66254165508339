import React, { Fragment, useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form, Row, Col } from "react-bootstrap";
import { Fetch } from "../../helpers/Fetch";

// import {Fetch} from "../../helpers/Fetch";
import { NumberFormat } from "../../helpers/NumberFormat";

const EditDevice = ({ rowdata, handleModalClose }) => {
    const {
        register, //Logic/magic to populate form: https://react-hook-form.com/api/useform/register/
        handleSubmit, //https://react-hook-form.com/api/useform/handlesubmit/
        reset, //Rest the form: https://react-hook-form.com/api/useform/reset/
        formState: { isDirty, dirtyFields, errors },
    } = useForm();

    const onSubmit = useCallback(
        async (_data) => {
            if (isDirty) {
                /*
            **** Form data changed ****
            Check to see if the server must tell the remote collector to refresh
            Not all fields require a remote collector update.
            Collector updates can be expensive/slow on large sites.
             */
                let updateCollector = "false"; //Initiate to false for no update by default
                if (dirtyFields.enabled) updateCollector = "true"; //If field modified/dirty, set flag to update remote collector
                if (dirtyFields.device_comm_data1) updateCollector = "true"; //If field modified/dirty, set flag to update remote collector

                /* ...any other fields that require updateCollector to be 'true' */
                try {
                    const data = await Fetch("devices_api.php", {
                        action: "update_device",
                        device: JSON.stringify(_data), //Send all form data
                        updateCollector: updateCollector, //Send true and false as text
                    });
                    if (data && data.status === "ok") {
                        handleModalClose(false); //Tell parent that the modal is closed
                    } else {
                        console.error("error", data);
                    }
                } catch (error) {
                    console.error(error);
                }
            } //No changes: Just close the modal
            else handleModalClose(false); //Tell parent that the modal is closed
        },
        [
            dirtyFields.device_comm_data1,
            dirtyFields.enabled,
            handleModalClose,
            isDirty,
        ],
    );

    //Data from parent
    const [data, setData] = useState(null);

    /**
     * Cancel without changes
     */
    const handleCancel = useCallback(() => {
        handleModalClose(false); //Tell parent that the modal is closed
    }, [handleModalClose]);

    /**
     * Wait until after first render for rowdata to load from props
     */
    useEffect(() => {
        if (rowdata) {
            //If data is set, load form
            reset(JSON.parse(rowdata)); //Important: reset the form to load new data
            setData(JSON.parse(rowdata)); //Put into local state to load form with default data
        } else setData(null); // do nothing
    }, [rowdata, reset]);

    return !data ? null : ( //need to check for data here or will get errors below when rowdata is not set
        <Modal
            show={true} //this can be true because we are checking for data above
            onHide={handleCancel} //When hiding, clear out data
            backdrop="static" //User must press cancel or submit to hide modal
            keyboard={false} //User must press cancel or submit to hide modal
            centered //centered on screen vertically
            // size="sm" //Small modal
        >
            <Modal.Header closeButton>
                <Modal.Title className="offset-5">Edit Meter</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form
                    onSubmit={handleSubmit(onSubmit)}
                    className="col-10 mx-auto"
                >
                    <div className="row text-center">
                        <h6>Type:&nbsp;{data.device_type_name}</h6>
                        <h6>DID:&nbsp;{data.device_id}</h6>

                        <Form.Group>
                            <Form.Check
                                inline
                                label="Device enabled"
                                type="checkbox"
                                {...register("enabled")}
                            />
                        </Form.Group>
                    </div>

                    <Form.Group as={Row}>
                        <Form.Label className="col-4" column="sm">
                            Device name
                        </Form.Label>
                        <Col xs={8}>
                            <Form.Control
                                size="sm"
                                type="text"
                                {...register("device_name", {
                                    required: "Device name can't be blank!",
                                    maxLength: 60,
                                })}
                            />
                            <ErrorMessage
                                as={Row}
                                className="m-1"
                                style={{
                                    border: "5px solid red",
                                    color: "red",
                                }}
                                errors={errors}
                                name="device_name"
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mt-1">
                        <Form.Label className="col-4" column="sm">
                            Device notes
                        </Form.Label>
                        <Col xs={8}>
                            <Form.Control
                                as="textarea"
                                size="sm"
                                rows={3}
                                {...register("device_notes")}
                            />
                        </Col>
                    </Form.Group>
                    {/* Some device types (like 18=PXMP Meter Point) do no use alarm settings
          for individual devices. Alarming is done at the collector level */}
                    {data.device_type_id !== 18 ? (
                        <Fragment>
                            <hr className="m-3"></hr>
                            <Form.Group as={Row} className="mt-1">
                                <Form.Label column="sm">Alarm delay</Form.Label>
                                <Col xs={3}>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        {...register("alarm_delay")}
                                    />
                                </Col>
                                <Form.Label column="sm">Int seconds</Form.Label>
                                <Col xs={3}>
                                    <Form.Control
                                        size="sm"
                                        type="text"
                                        readOnly
                                        value={data.contact_interval}
                                    />
                                </Col>
                            </Form.Group>
                            <div className="col text-center">
                                <font size="2">
                                    Calculated delay: {data.alarm_delay} delay
                                    x&nbsp;
                                    {data.contact_interval}
                                    &nbsp;seconds =&nbsp;
                                    {NumberFormat().format(
                                        data.alarm_delay *
                                            data.contact_interval,
                                    )}
                                    &nbsp; seconds
                                </font>
                            </div>
                            <div className="row text-center">
                                <Form.Group>
                                    <Form.Check
                                        inline
                                        label="Alarm enabled"
                                        type="checkbox"
                                        {...register("alarm_enabled")}
                                    />
                                </Form.Group>
                            </div>
                        </Fragment>
                    ) : (
                        ""
                    )}

                    <hr className="m-3"></hr>
                    <div className="row text-center">
                        <b>Advanced Settings</b>
                    </div>
                    <Form.Group as={Row} className="mt-1">
                        <Form.Label className="col-4" column="sm">
                            Comm data
                        </Form.Label>
                        <Col xs={8}>
                            <Form.Control
                                size="sm"
                                type="text"
                                {...register("device_comm_data1")}
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mt-1">
                        <Form.Label className="col-4" column="sm">
                            Tree ID
                        </Form.Label>
                        <Col xs={3}>
                            <Form.Control
                                size="sm"
                                type="number"
                                {...register("tree_id", {
                                    valueAsNumber: true,
                                })}
                            />
                        </Col>
                    </Form.Group>
                    <div
                        className="text-center mt-2 d-flex justify-content-center"
                        style={{ gap: "12px" }}
                    >
                        <Button type="submit" className="btn-sm">
                            Submit
                        </Button>
                        <Button
                            variant="secondary"
                            className="btn-sm"
                            onClick={handleCancel}
                        >
                            Cancel
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default EditDevice;
