import React, { useCallback, useState } from "react";
import Navigation from "../Navbar";
import BadDataTable from "./BadDataTable";
import { Fetch } from "../../helpers/Fetch";

const BadData = () => {
    const [reportBillId, setReportBillId] = useState("");
    const [profileTableData, setProfileTableData] = useState([]);
    const [reportTimeZone, setReportTimeZone] = useState("");
    const [reportName, setReportName] = useState("");
    const [reportGroupName, setReportGroupName] = useState("");
    const [submitRbid, setSubmitRbid] = useState(false);
    const [reportBillIdPlaceholder, setReportBillIdPlaceholder] = useState("");

    const handleRbidChange = useCallback((e) => {
        setReportBillId(e.target.value);
    }, []);

    const getProfileTableData = useCallback(
        async (e) => {
            e.preventDefault();
            try {
                const data = await Fetch("bad_data.php", {
                    action: "get_report_info",
                    report_bill_id: reportBillId,
                });

                if (data.status === "ok" && data.data.length > 0) {
                    const reportData = data.data[0];
                    setProfileTableData(data.data);
                    setReportTimeZone(reportData.site_tz);
                    setReportName(reportData.report_name);
                    setReportGroupName(reportData.report_group_name);
                    setReportBillIdPlaceholder(reportBillId);
                } else {
                    setProfileTableData([]);
                    setReportTimeZone("");
                    setReportName("");
                    setReportGroupName("");
                    setSubmitRbid(true);
                }
            } catch (error) {
                console.error("Error fetching profile table data:", error);
            }
        },
        [reportBillId],
    );

    return (
        <div>
            <Navigation />
            <h2 className="text-center">Bad Data Repair</h2>
            <div className="row">
                <div className="offset-4 col-2">
                    <label>Enter Report Bill ID:</label>
                </div>

                <div className="col-6">
                    <form onSubmit={getProfileTableData}>
                        <input
                            className="form-control-sm"
                            value={reportBillId}
                            onChange={handleRbidChange}
                            type="number"
                        />
                    </form>
                </div>
            </div>

            <div className="container">
                <hr className="mt-3" />
                <div className="row">
                    <div className="col-2">
                        <label>Group Name:</label>
                    </div>
                    <div className="col-3">
                        <input
                            className="form-control"
                            value={reportGroupName}
                            type="text"
                            readOnly
                        />
                    </div>
                    <div className="offset-2 col-2">
                        <label>Report Name:</label>
                    </div>
                    <div className="col-3">
                        <input
                            className="form-control"
                            value={reportName}
                            type="text"
                            readOnly
                        />
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-2">
                        <label>Site Time Zone:</label>
                    </div>
                    <div className="col-3">
                        <input
                            className="form-control"
                            value={reportTimeZone}
                            type="text"
                            readOnly
                        />
                    </div>
                </div>
                <BadDataTable
                    profile_table_data={profileTableData}
                    site_tz={reportTimeZone}
                    report_bill_id={reportBillIdPlaceholder}
                    submit_rbid={submitRbid}
                />
            </div>
        </div>
    );
};

export default BadData;
