import React, { useState, useEffect } from "react";
import Navigation from "../Navbar";
import AppFooter from "../AppFooter";
import "./Devices.css";

import SiteSelect from "../Global/Site_select2";
import CollectorTable3 from "./CollectorTable3";
import SiteNotesTextArea from "../Global/SiteNotesTextArea";
import { storageEventEmitter } from "../../helpers/StorageService";
const Devices = () => {
    const [siteId, setSiteId] = useState(
        sessionStorage.getItem("prev_site_id"),
    );
    const [siteTz, setSiteTz] = useState(
        sessionStorage.getItem("prev_site_tz"),
    );
    const [siteNotes, setSiteNotes] = useState(
        sessionStorage.getItem("prev_site_notes"),
    );

    useEffect(() => {
        const handleStorageChange = () => {
            setSiteId(sessionStorage.getItem("prev_site_id"));
            setSiteNotes(sessionStorage.getItem("prev_site_notes"));
            setSiteTz(sessionStorage.getItem("prev_site_tz"));
        };

        // Listen for the custom event
        storageEventEmitter.addEventListener(
            "storageChanged",
            handleStorageChange,
        );

        return () => {
            storageEventEmitter.removeEventListener(
                "storageChanged",
                handleStorageChange,
            );
        };
    }, []);

    const handleSiteChange = (e) => {
        setSiteId(e.site_id);
        setSiteTz(e.site_tz);
    };

    const handleSiteNotesChange = (value) => {
        setSiteNotes(value);
    };

    useEffect(() => {
        // You can add any effect or session management logic here if needed
    }, [siteId, siteTz, siteNotes]);

    return (
        <div>
            <Navigation />
            <div className="container-fluid">
                <div className="col-sm-8 col-lg-4 col-xl-3 mx-auto">
                    <SiteSelect
                        handleSiteChange={handleSiteChange}
                        site_notes={siteNotes}
                    />
                    <SiteNotesTextArea
                        site_id={siteId}
                        handleSiteNotesChange={handleSiteNotesChange}
                    />
                </div>
                <div className="mx-auto col-xxl-10 col-xl-12">
                    <CollectorTable3 siteId={siteId} siteTz={siteTz} />
                </div>
            </div>
            <AppFooter />
        </div>
    );
};

export default Devices;
