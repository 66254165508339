import React, { useState, useEffect, useCallback } from "react";
import Navigation from "../Navbar";
import SiteSelect from "../Global/Site_select2";
import { Fetch } from "../../helpers/Fetch";
import TenantReportReactTable from "./TenantReportReactTable";
import AppFooter from "../AppFooter";
import SiteNotesTextArea from "../Global/SiteNotesTextArea";
import { storageEventEmitter } from "../../helpers/StorageService";
const Tenantv3 = () => {
    const [siteTz, setSiteTz] = useState("");
    const [siteId, setSiteId] = useState(
        sessionStorage.getItem("prev_site_id"),
    );
    const [viewConsump, setViewConsump] = useState("usage");
    const [offset, setOffset] = useState(0);
    const [tableData, setTableData] = useState([]);
    const [tableDates, setTableDates] = useState([]);
    const [_contextMenu, setContextMenu] = useState([]);
    const [categoryRows, setCategoryRows] = useState([]);
    const [disablePagination, setDisablePagination] = useState(false);
    const [loading, setLoading] = useState(true);
    const [_loginData] = useState(
        JSON.parse(localStorage.getItem("login_data")),
    );
    const [siteNotes, setSiteNotes] = useState(
        sessionStorage.getItem("prev_site_notes"),
    );

    useEffect(() => {
        const handleStorageChange = () => {
            setSiteId(sessionStorage.getItem("prev_site_id"));
            setSiteNotes(sessionStorage.getItem("prev_site_notes"));
        };

        // Listen for the custom event
        storageEventEmitter.addEventListener(
            "storageChanged",
            handleStorageChange,
        );

        return () => {
            storageEventEmitter.removeEventListener(
                "storageChanged",
                handleStorageChange,
            );
        };
    }, []);

    useEffect(() => {
        if (siteId) {
            getTenantsTableData();
        }
        //eslint-disable-next-line
    }, [siteId, offset, viewConsump]);

    const getTenantsTableData = useCallback(async () => {
        setLoading(true);
        try {
            const data = await Fetch("TenantV3_api.php", {
                action: "fill_tenant_table",
                site_id: siteId,
                viewConsump: viewConsump,
                offset: offset,
            });
            if (data.status === "ok") {
                let massagedDates = data.dates;
                while (massagedDates.length < 6) {
                    massagedDates.push("");
                }

                setTableDates(massagedDates.reverse());
                setDisablePagination(data.dates.length < 6);
                setCategoryRows(data.rg_rows);
                setTableData(data.data2);
                setContextMenu(data.contextMenu);
            } else {
                console.log("Fetch ERROR", data);
            }
        } catch (error) {
            console.error("err:", error);
        } finally {
            setLoading(false);
        }
    }, [offset, siteId, viewConsump]);

    const handleOffsetChange = useCallback(
        async (e, val) => {
            let computedVal = offset + val;
            let disablePagination = false;

            tableDates.forEach((date) => {
                if (date === "") {
                    disablePagination = true;
                }
            });

            if (computedVal >= 0) {
                if (val !== "current" && offset >= 0) {
                    setOffset(offset + val);
                }
            } else if (computedVal < 0) {
                setOffset(0);
            }

            if (val === "current") {
                setOffset(0);
            } else {
                setDisablePagination(disablePagination);
            }
        },
        [offset, tableDates],
    );

    const handleSiteChange = useCallback((e) => {
        setSiteTz(e.site_tz);
        setSiteId(e.site_id);
    }, []);

    const toggleConsumptionMode = useCallback(async () => {
        setViewConsump(viewConsump === "usage" ? "all" : "usage");
    }, [viewConsump]);

    const handleTableRefresh = useCallback(() => {
        getTenantsTableData();
    }, [getTenantsTableData]);

    const handleSiteNotesChange = useCallback((value) => {
        setSiteNotes(value);
    }, []);

    return (
        <div>
            <Navigation />
            <div>
                <div className="col-4 offset-4 mb-2" align="center">
                    <SiteSelect
                        handleSiteChange={handleSiteChange}
                        site_notes={siteNotes}
                    />
                    <SiteNotesTextArea
                        site_id={siteId}
                        handleSiteNotesChange={handleSiteNotesChange}
                    />
                </div>
                {tableData.length > 0 && (
                    <TenantReportReactTable
                        table_data={tableData}
                        report_dates={tableDates}
                        site_tz={siteTz}
                        category_rows={categoryRows}
                        refresh_table={handleTableRefresh}
                        handle_change_offset={handleOffsetChange}
                        disable_pagination={disablePagination}
                        toggle_consumption={toggleConsumptionMode}
                        loading={loading}
                    />
                )}
            </div>
            {!loading && <AppFooter />}
        </div>
    );
};

export default Tenantv3;
