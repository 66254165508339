import React, {
    useState,
    useEffect,
    useMemo,
    Fragment,
    useCallback,
} from "react";
import { v4 as uuid } from "uuid";
import DeviceTable3 from "./DeviceTable3";
import ModalEditCollector from "./ModalEditCollector";
import ModalSwitchCollector from "./ModalSwitchCollector";

import { useTable, useExpanded, useSortBy } from "react-table";
import {
    Button,
    Table,
    Tooltip,
    OverlayTrigger,
    FormCheck,
} from "react-bootstrap";
import Fuse from "fuse.js";
import { Fetch } from "../../helpers/Fetch";
import { globalDateFormat } from "../../helpers/GlobalDateFormat";
import { storageEventEmitter } from "../../helpers/StorageService";

import dayjs from "dayjs";

//import timezone from "dayjs/plugin/timezone";
//import advancedFormat from "dayjs/plugin/advancedFormat";
import utc from "dayjs/plugin/utc";
import LocalizedFormat from "dayjs/plugin/localizedFormat";

//dayjs.extend(timezone);
//dayjs.extend(advancedFormat);
dayjs.extend(utc);
dayjs.extend(LocalizedFormat);

let timer;
/********************************************
 * END OF DAYJS IMPORTS
 ********************************************/

/**
 * This is a Functional Component, not a Class Component
 * https://www.twilio.com/blog/react-choose-functional-components
 * @param props
 * @returns {JSX.Element}
 */
export default function CollectorTable3(props) {
    const loginData = JSON.parse(localStorage.getItem("login_data"));
    const [isChecked, setIsChecked] = useState(false);
    const [siteTz, setSiteTz] = useState(
        sessionStorage.getItem("prev_site_tz"),
    );
    useEffect(() => {
        const handleStorageChange = () => {
            setSiteTz(sessionStorage.getItem("prev_site_tz"));
        };

        // Listen for the custom event
        storageEventEmitter.addEventListener(
            "storageChanged",
            handleStorageChange,
        );

        return () => {
            storageEventEmitter.removeEventListener(
                "storageChanged",
                handleStorageChange,
            );
        };
    }, []);
    const userTimezone = useMemo(() => {
        const storedUserData = localStorage.getItem("login_data");
        if (storedUserData) {
            try {
                const parsedUserData = JSON.parse(storedUserData);
                return parsedUserData.user_tz;
            } catch (error) {
                console.error("Error parsing user data:", error);
                return null;
            }
        }
        return null;
    }, []);

    const stopTimer = useCallback(() => {
        if (typeof timer === "number") {
            clearInterval(timer);
            timer = undefined; // Reset the timer
        }
    }, []);

    const siteId = useMemo(() => {
        setIsChecked(false);
        stopTimer();
        return props.siteId;
    }, [props.siteId, stopTimer]);

    const [collectors, setCollectors] = useState([]);
    const [filterText, setFilterText] = useState("");
    const [rowdata, setRowdata] = useState({});
    const [showCollectorModal, setShowCollectorModal] = useState(false);
    const [showDisabledDevices, setShowDisabledDevices] = useState(false);
    const [modalSwitchData, setModalSwitchData] = useState({
        avail_site_collectors: [], //List of available collectors on the site
        show_modal: false, //Show the modal
        collector_id: null, //collector_id of the child collector to be updated
        collector_name: null, //collector_name of the child collector to be updated
    });

    const [refreshHash, setRefreshHash] = useState(uuid());

    const fetchSiteCollectors = useCallback(async () => {
        if (siteId === null) {
            return;
        }
        try {
            const data = await Fetch("devices_api.php", {
                action: "get_site_collectors",
                site_id: siteId,
            });
            if (data && data.status === "ok") {
                setCollectors(data.collectors);
            } else {
                console.error("error", data);
            }
        } catch (error) {
            console.error(error);
        }
    }, [siteId]);

    useEffect(() => {
        fetchSiteCollectors();
        //eslint-disable-next-line
    }, [siteId]);

    const handleKick = useCallback(
        async (e) => {
            //This "kicks" a collector to reset it in the field
            try {
                await Fetch("devices_api.php", {
                    action: "kick_collector",
                    collector_id: e.target.attributes["cid"].value,
                });
                await fetchSiteCollectors();
            } catch (error) {
                console.log(error);
            }
        },
        [fetchSiteCollectors],
    );
    const handleRefresh = useCallback(async () => {
        await fetchSiteCollectors();
        setRefreshHash(uuid());
        //eslint-disable-next-line
    }, [fetchSiteCollectors]);

    const handleShowDisabledDevices = useCallback(() => {
        setShowDisabledDevices(!showDisabledDevices);
    }, [showDisabledDevices]);

    const handleAutoRefresh = useCallback(
        async (e) => {
            const checked = e.target.checked;
            setIsChecked(checked);
            if (checked) {
                // If the checkbox is checked, start the refresh timer
                await handleRefresh(); // Call handleRefresh immediately
                if (typeof timer !== "number") {
                    timer = setInterval(async () => {
                        await handleRefresh(); // Refresh every 5 seconds
                    }, 5000);
                }
            } else {
                stopTimer();
            }
        },
        [handleRefresh, stopTimer],
    );

    const handleSwitch = useCallback(
        async (e) => {
            try {
                const data = await Fetch("devices_api.php", {
                    action: "get_avail_collectors",
                    site_id: siteId, //From site selector
                });
                if (data.status === "ok") {
                    setModalSwitchData({
                        show_modal: true,
                        avail_site_collectors: data.collectors,
                        collector_id: e.target.attributes["cid"].value, //child collector to change parent
                        collector_name: e.target.attributes["c_name"].value,
                        parent_collector_id:
                            e.target.attributes["parent_cid"].value, //existing parent
                    });
                }
            } catch (error) {
                console.log(error);
            }
        },
        [siteId],
    );

    const memoCollectors = useMemo(() => {
        const fuse = new Fuse(collectors, {
            keys: ["collector_name"],
            threshold: 0,
            ignoreLocation: true,
            isCaseSensitive: false, //false is the default, but I want this comment as a reminder
        });

        const search_results = fuse.search(filterText);
        return filterText
            ? search_results.map((result) => result.item)
            : collectors;
    }, [collectors, filterText]);

    const handle_clear_filter = useCallback(() => {
        setFilterText("");
    }, []);

    const handleEditCollector = useCallback((e, props) => {
        setRowdata(props);
        setShowCollectorModal(true);
    }, []);

    const handleCloseModal = useCallback(() => {
        setShowCollectorModal(false);
        setModalSwitchData({ avail_site_collectors: [], show_modal: false });

        handleRefresh();
    }, [handleRefresh]);

    const columns = useMemo(
        () => [
            {
                // Make an expander cell
                Header: () => null, // No header
                id: "expander", // It needs an ID
                Cell: ({ row }) => {
                    if (row.original.parent_name) {
                        //If not an EDC/has a parent
                        if (row.isExpanded) {
                            //if expanded
                            return (
                                <i
                                    {...row.getToggleRowExpandedProps()}
                                    className="far fa-minus-square"
                                    style={{ color: "red" }}
                                />
                            );
                        } else {
                            //if NOT expanded
                            return (
                                <i
                                    {...row.getToggleRowExpandedProps()}
                                    className="far fa-plus-square"
                                    style={{ color: "green" }}
                                />
                            );
                        }
                    } else return null;
                },
            },
            {
                accessor: "parent_collector_id",
            },
            {
                accessor: "collector_name",
                sortType: (rowA, rowB) => {
                    const a = rowA.values.collector_name;
                    const b = rowB.values.collector_name;
                    if (a == null || b == null) {
                        return 0;
                    }
                    // Function to extract numbers from strings and return an array
                    const parseDeviceName = (name) =>
                        name
                            .split(/(\d+)/)
                            .map((part) =>
                                isNaN(part) ? part : parseInt(part, 10),
                            );

                    const parsedA = parseDeviceName(a);
                    const parsedB = parseDeviceName(b);

                    for (
                        let i = 0;
                        i < Math.max(parsedA.length, parsedB.length);
                        i++
                    ) {
                        if (parsedA[i] !== parsedB[i]) {
                            if (
                                typeof parsedA[i] === "string" &&
                                typeof parsedB[i] === "string"
                            ) {
                                return parsedA[i].localeCompare(parsedB[i]);
                            } else if (
                                typeof parsedA[i] === "number" &&
                                typeof parsedB[i] === "number"
                            ) {
                                return parsedA[i] - parsedB[i];
                            } else {
                                return typeof parsedA[i] === "string" ? 1 : -1;
                            }
                        }
                    }

                    return 0; // Return 0 if both device names are the same
                },
                Cell: (props) => {
                    return (
                        <div>
                            <i
                                className="fas fa-edit m-0"
                                title="Click to edit configuration."
                                onClick={(e) =>
                                    handleEditCollector(e, props.row.original)
                                }
                            />
                            {"Name: " + props.value}{" "}
                            {props.row.original.enabled !== true && (
                                <span
                                    style={{ color: "red", fontSize: "14px" }}
                                >
                                    Disabled
                                </span>
                            )}
                        </div>
                    );
                },
            },
            {
                accessor: "sub_device_type_name",
            },
            {
                accessor: "gmt_last_contact",
                Cell: (props) => {
                    const cellVal = props.cell.value;
                    let d = dayjs().unix() - dayjs.utc(cellVal).unix();
                    if (d < 0) {
                        d = 0;
                    }
                    return (
                        "Last: " +
                        (d < 3600 //if < 1 hour
                            ? d + " sec ago" //show seconds
                            : globalDateFormat(
                                  cellVal,
                                  undefined,
                                  userTimezone,
                              ))
                    );
                },
            },
            {
                accessor: "parent_name",
                c_className: "d-none d-lg-table-cell",
                Cell: (props) => {
                    return props.cell.value ? (
                        <Fragment>
                            {loginData.user_role >= 7000 && (
                                <Button
                                    className="btn-sm pt-0 pb-0 me-1"
                                    cid={props.cell.row.original.collector_id}
                                    c_name={
                                        props.cell.row.original.collector_name
                                    }
                                    parent_cid={
                                        props.cell.row.original
                                            .parent_collector_id
                                    }
                                    onClick={handleSwitch}
                                >
                                    Switch
                                </Button>
                            )}

                            <span className="col">
                                Parent: {props.cell.value}
                            </span>
                        </Fragment>
                    ) : (
                        <span className="col">
                            Contact interval:{" "}
                            {props.cell.row.original.contact_interval}
                            &nbsp;sec
                        </span>
                    );
                },
            },
            {
                id: "kick",
                c_className: "pr-0 pl-0",
                Cell: ({ row }) => {
                    return loginData.user_role >= 7000 ? (
                        <Button
                            className="btn-sm float-end pt-0 pb-0 ms-2"
                            cid={row.original.collector_id}
                            onClick={handleKick}
                        >
                            Kick
                        </Button>
                    ) : null;
                },
            },
        ],
        [
            handleEditCollector,
            handleKick,
            handleSwitch,
            loginData.user_role,
            userTimezone,
        ],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        toggleAllRowsExpanded,
    } = useTable(
        {
            columns,
            data: memoCollectors,
            autoResetExpanded: false,
            initialState: {
                hiddenColumns: ["parent_collector_id"],
                sortBy: [
                    {
                        id: "parent_collector_id",
                        desc: false,
                    },
                    {
                        id: "collector_name",
                        desc: false,
                    },
                    // {
                    //     id: "parent_name", //null (EDC units) float to the top
                    //     desc: false,
                    // },
                    {
                        id: "sub_device_type_name",
                        desc: false,
                    },
                ],
            },
        },
        useSortBy,
        useExpanded,
    );

    const memoizedRows = useMemo(() => {
        return rows;
    }, [rows]);

    useEffect(() => {
        if (!siteId) return;

        toggleAllRowsExpanded(false);
        // eslint-disable-next-line
    }, [siteId]);

    let hideColHeaders = false;

    return (
        <>
            <div className="text-center">
                <div style={{ fontSize: "2em" }}>Metering Devices</div>
                <div>
                    <Button className="btn-sm" onClick={handleRefresh}>
                        Refresh
                    </Button>

                    <span className="ms-3">Show Disabled Devices</span>
                    <FormCheck
                        className="btn-sm"
                        inline
                        checked={showDisabledDevices}
                        onChange={() => {
                            handleShowDisabledDevices();
                        }}
                    />
                    <>
                        <span className="ms-3">Refresh every 5 seconds</span>
                        <FormCheck
                            className="btn-sm"
                            inline
                            checked={isChecked}
                            onChange={handleAutoRefresh}
                        />
                        <input
                            type="text"
                            onChange={(e) => setFilterText(e.target.value)}
                            id="device_filter_input"
                            value={filterText}
                            placeholder={"Filter(Parent, Name)"}
                            className="form-control-sm"
                        />
                    </>

                    <button
                        className="btn-sm ms-1"
                        onClick={handle_clear_filter}
                    >
                        Clear
                    </button>
                </div>
            </div>
            <Table size="sm" {...getTableProps()}>
                <thead>
                    {headerGroups.map((headerGroup, i) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={`tr-${i}`}
                        >
                            {headerGroup.headers.map((column, i) => {
                                //Check if we are to render headers
                                if (!hideColHeaders) {
                                    //This will stop all future headers from being rendered
                                    if (column.hideColHeaders)
                                        hideColHeaders = column.hideColHeaders;

                                    return (
                                        <th
                                            {...column.getHeaderProps({
                                                className: column.c_className,
                                            })}
                                            key={`tr-${i}`}
                                        >
                                            {column.render("Header")}
                                        </th>
                                    );
                                }
                                return null;
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {/* Process collector rows */}
                    {memoizedRows.map((row, i) => {
                        prepareRow(row);
                        const rd = row.original; //row data
                        return (
                            <Fragment key={"idx_" + row.id}>
                                <OverlayTrigger
                                    placement="top"
                                    overlay={
                                        <Tooltip>
                                            {rd.enabled
                                                ? "Enabled"
                                                : "Disabled"}
                                            <br />
                                            CID: {rd.collector_id}
                                            <br />
                                            Notes: {rd.collector_notes}
                                            <br />
                                            Type: {rd.collector_type_name}
                                            <br />
                                            Connect: {rd.connection}
                                            <br />
                                            Alarm delay:&nbsp;
                                            {rd.alarm_delay_sec}
                                            &nbsp;seconds
                                            <br />
                                            Config:&nbsp;
                                            {globalDateFormat(
                                                rd.gmt_last_config_update,
                                                undefined,
                                                userTimezone,
                                            )}
                                        </Tooltip>
                                    }
                                    color="black"
                                >
                                    <tr
                                        {...row.getRowProps()}
                                        style={{
                                            backgroundColor: row.original
                                                .enabled
                                                ? "lightgreen"
                                                : "yellow",
                                        }}
                                        key={`tr-${i}`}
                                    >
                                        {row.cells.map((cell, j) => {
                                            return (
                                                <td
                                                    {...cell.getCellProps({
                                                        className:
                                                            cell.column
                                                                .c_className,
                                                    })}
                                                    key={`td-${i}-${j}`}
                                                >
                                                    {cell.column
                                                        .c_onClickEdit ? (
                                                        <i
                                                            onClick={
                                                                cell.column
                                                                    .c_onClickEdit
                                                            }
                                                            rowdata={JSON.stringify(
                                                                cell.row
                                                                    .original,
                                                            )}
                                                            className="fas fa-edit m-0"
                                                            title="Click to edit configuration."
                                                            style={{
                                                                cursor: "pointer",
                                                            }}
                                                        ></i>
                                                    ) : null}

                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                </OverlayTrigger>
                                {row.isExpanded ? (
                                    <tr>
                                        <td colSpan={visibleColumns.length}>
                                            <DeviceTable3
                                                key={refreshHash}
                                                row={row.original}
                                                siteTz={siteTz}
                                                showDisabledDevices={
                                                    showDisabledDevices
                                                }
                                            />
                                        </td>
                                    </tr>
                                ) : null}
                            </Fragment>
                        );
                    })}
                </tbody>
            </Table>

            {showCollectorModal && (
                <ModalEditCollector
                    handle_close_modal={handleCloseModal}
                    row_data={rowdata}
                />
            )}
            {modalSwitchData.show_modal && (
                <ModalSwitchCollector
                    modalSwitchData={modalSwitchData}
                    handle_close_modal={handleCloseModal}
                />
            )}
        </>
    );
}
