import React, { Component, useState, useMemo } from "react";

import { Fetch } from "../../helpers/Fetch";
import { CurrencyFormat } from "../../helpers/CurrencyFormat";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Table from 'react-bootstrap/Table';
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getSortedRowModel,
  useReactTable,
  createColumnHelper,
} from '@tanstack/react-table'

dayjs.extend(utc);
dayjs.extend(timezone);

class LatestInvoiceTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      latest_invoice_data: [],
    };
  }
  componentDidMount() {
    const { site_id } = this.props;
    Fetch("utilityInvoices_api.php", {
      action: "fill_l_invoices_table",
      site_id: site_id,
    }).then((data) => {
      this.setState({
        latest_invoice_data: data.data,
      });
    });
  }

  render() {
    const { latest_invoice_data } = this.state;
      function TableRender({data}) {
          const columnHelper = createColumnHelper()
          const [columnFilters, setColumnFilters] =useState([])

          const columns =useMemo(
              () => [
                  columnHelper.accessor('report_group_name', {
                        header: () => 'Group Name',
                        cell: info => info.getValue(),
                      }
                  ),
                  columnHelper.accessor('utility_name', {
                      header: () => 'Utility Name',
                      cell: info => info.getValue(),
                  }),
                  columnHelper.accessor('utility_account', {
                      header: () => 'Account',
                      cell: info => info.getValue(),
                  }),
                  columnHelper.accessor('service_from', {
                      header: () => 'Service From',
                      cell: info => info.getValue(),
                      accessorFn: val => dayjs.utc(val.service_from).format("YYYY-MM-DD HH:mm"),
                  }),
                  columnHelper.accessor('service_to', {
                      header: () => 'Service To',
                      cell: info => info.getValue(),
                      accessorFn: val => dayjs.utc(val.service_to).format("YYYY-MM-DD HH:mm"),
                  }),
                  columnHelper.accessor('bill_date', {
                      header: () => 'Invoice date',
                      cell: info => info.getValue(),
                      accessorFn: val => dayjs(val.bill_date).format("YYYY-MM-DD"),
                  }),
                  columnHelper.accessor('days', {
                      header: () => 'Days',
                      cell: info => info.getValue(),
                      accessorFn: val => dayjs(val.service_to).diff(dayjs(val.service_from), "days"),
                  }),
                  columnHelper.accessor('current_charges', {
                      header: () => 'Charges',
                      cell: info => info.getValue(),
                      accessorFn: val => "$" + CurrencyFormat().format(val.current_charges),
                  }),
                  columnHelper.accessor('consumption', {
                      header: () => 'Consumption',
                      cell: info => info.getValue(),
                      accessorFn: val => CurrencyFormat(0).format(val.consumption),
                  }),
                  columnHelper.accessor('unit_cost', {
                      header: () => 'Unit Cost',
                      cell: info => info.getValue(),
                      accessorFn: val => "$" + CurrencyFormat(5).format(val.current_charges / val.consumption),
                  }),
              ], [columnHelper]
          )

          const table = useReactTable({
              data,
              columns,
              filterFns: {},
              state: {
                  columnFilters,
              },
              onColumnFiltersChange: setColumnFilters,
              getCoreRowModel: getCoreRowModel(),
              getFilteredRowModel: getFilteredRowModel(),
              getSortedRowModel: getSortedRowModel(),
              debugColumns: false,
          })

          return (
              <div className="table-responsive">
                  <Table bordered striped size="sm">
                      <thead>
                      {table.getHeaderGroups().map(headerGroup => (
                          <tr key={headerGroup.id}>
                              {headerGroup.headers.map(header => {
                                  return (
                                      <th key={header.id} colSpan={header.colSpan}>
                                          {header.isPlaceholder ? null : (
                                              <>
                                                  <div
                                                      style={{cursor:'pointer', userSelect:'none'}}
                                                      {...{
                                                          onClick: header.column.getToggleSortingHandler(),
                                                      }}
                                                  >
                                                      {flexRender(
                                                          header.column.columnDef.header,
                                                          header.getContext()
                                                      )}
                                                      {{
                                                          asc: ' 🔼',
                                                          desc: ' 🔽',
                                                      }[header.column.getIsSorted()] ?? null}
                                                  </div>
                                                  {header.column.getCanFilter() ? (
                                                      <div>
                                                          <Filter column={header.column} />
                                                      </div>
                                                  ) : null}
                                              </>
                                          )}
                                      </th>
                                  )
                              })}
                          </tr>
                      ))}
                      </thead>
                      <tbody>
                      {table.getRowModel().rows.map(row => {
                          return (
                              <tr key={row.id}>
                                  {row.getVisibleCells().map(cell => {
                                      return (
                                          <td key={cell.id}>
                                              {flexRender(
                                                  cell.column.columnDef.cell,
                                                  cell.getContext()
                                              )}
                                          </td>
                                      )
                                  })}
                              </tr>
                          )
                      })}
                      </tbody>
                  </Table>
              </div>
          )
      }

      function DebouncedInput({value: initialValue, onChange, debounce = 500, ...props}) {
          const [value, setValue] = React.useState(initialValue)

          React.useEffect(() => {
              setValue(initialValue)
          }, [initialValue])

          React.useEffect(() => {
              const timeout = setTimeout(() => {
                  onChange(value)
              }, debounce)

              return () => clearTimeout(timeout)
              //eslint-disable-next-line
          }, [value])

          return (
              <input {...props} style={{width:'100%'}} value={value} onChange={e => setValue(e.target.value)} />
          )
      }
      function Filter({ column }) {
          const columnFilterValue = column.getFilterValue()

          return  (
              <DebouncedInput
                  className="border rounded"
                  onChange={value => column.setFilterValue(value)}
                  placeholder={`Search...`}
                  type="text"
                  value={columnFilterValue ?? ''}
              />
          )
      }

    return (
      <TableRender data={latest_invoice_data}/>
    );
  }
}

export default LatestInvoiceTable;
