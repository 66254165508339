import React, { useCallback, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useForm } from "react-hook-form";
import { Col, Form, Row } from "react-bootstrap";
import { Fetch } from "../../helpers/Fetch";
import { ErrorMessage } from "@hookform/error-message";

const ModalEditCollector = ({ row_data, handle_close_modal }) => {
    const {
        register,
        handleSubmit,
        reset,
        formState: { isDirty, errors },
    } = useForm();
    useEffect(() => {
        get_edit_occ();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reset]);

    const get_edit_occ = () => {
        reset(row_data);
    };

    const onSubmit = useCallback(
        async (data) => {
            if (isDirty) {
                /*Perform whatever code is required to submit */
                try {
                    const response = await Fetch("devices_api.php", {
                        action: "update_collector",
                        collector_name: data.collector_name,
                        contact_interval: data.contact_interval,
                        collector_id: data.collector_id,
                        enabled: data.enabled,
                    });
                    if (response && response.status === "ok") {
                        handle_close_modal();
                    } else {
                        console.error("error", response);
                    }
                } catch (error) {
                    console.error(error);
                }
            } else {
                handle_close_modal();
            }
        },
        [handle_close_modal, isDirty]
    );

    const handleCloseModal = () => {
        handle_close_modal();
    };

    return (
        <Modal
            show={true} //this can be true because we are checking for data above
            // onHide={() => setData(null)} //When hiding, clear out data
            backdrop="static" //User must press cancel or submit to hide modal
            keyboard={false} //User must press cancel or submit to hide modal
            centered //centered on screen vertically
            size="lg" //Small modal
        >
            <Modal.Header className="text-center">
                <Modal.Title> Edit Collector</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSubmit(onSubmit)}>
                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center mt-3"
                    >
                        <Form.Label
                            column
                            sm={3}
                            style={{ whiteSpace: "nowrap" }}
                        >
                            Name
                        </Form.Label>
                        <Col sm={6}>
                            <Form.Control
                                size="sm"
                                type="text"
                                {...register("collector_name", {
                                    required: "Collector name can't be blank!",
                                    maxLength: {
                                        value: 60,
                                        message:
                                            "Collector name can't exceed 60 characters",
                                    },
                                })}
                            />
                            <ErrorMessage
                                as={Row}
                                className="m-1"
                                style={{
                                    color: "red",
                                }}
                                errors={errors}
                                name="collector_name"
                            />
                        </Col>
                    </Form.Group>
                    <Form.Group
                        as={Row}
                        className="justify-content-center align-content-center mt-3"
                    >
                        <Form.Label
                            column
                            sm={3}
                            style={{ whiteSpace: "nowrap" }}
                        >
                            Contact Interval
                        </Form.Label>
                        <Col sm={6}>
                            <Form.Control
                                size="sm"
                                type="text"
                                {...register("contact_interval")}
                            />
                        </Col>
                    </Form.Group>
                    <div className="row text-center mt-3">
                        <Form.Group>
                            <Form.Check
                                inline
                                label="Device enabled"
                                type="checkbox"
                                {...register("enabled")}
                            />
                        </Form.Group>
                    </div>
                    <hr />
                    <div className="float-end">
                        <Button
                            className="btn-sm btn-secondary"
                            onClick={handleCloseModal}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            className="mx-2 btn-sm btn-primary"
                        >
                            Submit
                        </Button>
                    </div>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ModalEditCollector;
