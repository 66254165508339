import React, { PureComponent } from "react";

import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    ResponsiveContainer,
    Legend,
    Bar,
    Tooltip,
    BarChart,
} from "recharts";

import { Modal, Button } from "react-bootstrap";

import { svr_name } from "../../helpers/AppConstants";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { LogUserAction } from "../../helpers/LogUserAction";

dayjs.extend(utc);
dayjs.extend(timezone);

//const LineChartx = React.lazy(() => import('recharts'));

export class TenantModal extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            chart_loaded: true,
            loading: false,
            endDate: dayjs() //Left side of chart
                .tz(this.props.site_tz)
                .subtract(29, "days")
                .startOf("day")
                .toISOString(),
            initial_date: dayjs()
                .tz(this.props.site_tz)
                .startOf("day")
                .utc()
                .toISOString(),
            rg_tz: this.props.site_tz,
            graph_type: "bar",
            graph_difference: 7,
            plot_data: [],
        };
    }

    componentDidMount() {
        const { initial_date, endDate } = this.state;
        const { reportRow } = this.props;

        //Plot the initial 30-day bar graph
        this.get_bar_daily(reportRow.report_bill_id, endDate, initial_date);
    }

    /******************************************************
     * Events
     ******************************************************/
    handleCurrentButton = () => {
        const { reportRow } = this.props;
        const { graph_difference, graph_type, rg_tz } = this.state;
        let endDateBar = dayjs()
            .tz(rg_tz)
            .subtract(30, "days")
            .startOf("day")
            .utc()
            .toISOString();
        let end_date_line_seven = dayjs()
            .tz(rg_tz)
            .subtract(6, "days")
            .startOf("day")
            .utc()
            .toISOString();
        let end_date_line_two = dayjs()
            .tz(rg_tz)
            .subtract(1, "days")
            .startOf("day")
            .utc()
            .toISOString();

        let initial_date = dayjs().tz(rg_tz).utc().toISOString();

        if (graph_type === "bar") {
            LogUserAction("Show Bar Graph ");
            this.get_bar_daily(
                reportRow.report_bill_id,
                endDateBar,
                initial_date
            );
        } else if (graph_type === "line" && graph_difference === 7) {
            this.get_trend_data(
                reportRow.report_bill_id,
                end_date_line_seven,
                initial_date,
                7
            );
        } else if (graph_type === "line" && graph_difference === 1) {
            LogUserAction("Show Line Graph ");
            this.get_trend_data(
                reportRow.report_bill_id,
                end_date_line_two,
                initial_date,
                1
            );
        }
    };

    get_bar_daily = (rbid, from_date, to_date) => {
        fetch(svr_name + "tenant_info_api.php", {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                action: "get_bar_daily",
                report_bill_id: rbid,
                fromDate: from_date,
                toDate: to_date,
                site_tz: sessionStorage.getItem("site_tz"),
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                //Get the max Kwh value
                const maxKwh = Math.max.apply(
                    Math,
                    data.data.map(function (o) {
                        return o.kwh;
                    })
                );

                //Set the Y range se we get nice round numbers for each tick
                let rangeYDomain = 0;
                let y_tickCount; //Number of Y axis ticks including zero
                if (maxKwh > 10000) {
                    //10,000kwh bumps
                    rangeYDomain = (Math.floor(maxKwh / 10000) + 1) * 10000;
                    if (rangeYDomain < 4000)
                        y_tickCount = rangeYDomain / 5000 + 1;
                    else y_tickCount = rangeYDomain / 10000 + 1;
                } else if (maxKwh > 1000) {
                    //1,000kwh bumps
                    rangeYDomain = (Math.floor(maxKwh / 1000) + 1) * 1000;
                    if (rangeYDomain < 4000)
                        y_tickCount = rangeYDomain / 500 + 1;
                    else y_tickCount = rangeYDomain / 1000 + 1;
                } else if (maxKwh > 100) {
                    //100 bumps
                    rangeYDomain = (Math.floor(maxKwh / 100) + 1) * 100;
                    if (rangeYDomain < 400) y_tickCount = rangeYDomain / 50 + 1;
                    else y_tickCount = rangeYDomain / 100 + 1;
                } else if (maxKwh > 10) {
                    //10 bumps
                    rangeYDomain = (Math.floor(maxKwh / 10) + 1) * 10;
                    if (rangeYDomain < 40) y_tickCount = rangeYDomain / 5 + 1;
                    else y_tickCount = rangeYDomain / 10 + 1;
                } else {
                    //1 bumps
                    rangeYDomain = (Math.floor(maxKwh / 1) + 1) * 1;
                    if (rangeYDomain < 4) y_tickCount = rangeYDomain + 1;
                    else y_tickCount = rangeYDomain / 1 + 1;
                }

                this.setState({
                    plot_data: data,
                    y_axis_domain: [0, rangeYDomain],
                    y_tickCount: y_tickCount,
                    graph_type: "bar", //Bar chart
                    endDate: from_date, //Right hand side of the plot or from/start date
                    initial_date: to_date, //Left hand side of the plot or to/end date
                    loading: false,
                    graph_difference: 7,
                });
            });
    };

    get_trend_data = (rbid, from_date, to_date, graph_difference) => {
        //line chart
        
        // const tz_from_date = dayjs(from_date)
        //   .tz(sessionStorage.getItem("prev_site_tz"))
        //   .format("MM-DD-YYYY HH:mm");
        // const tz_to_date = dayjs(to_date)
        //   .tz(sessionStorage.getItem("prev_site_tz"))
        //   .format("MM-DD-YYYY HH:mm");

        fetch(svr_name + "tenant_info_api.php", {
            method: "POST",
            credentials: "include",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                action: "get_trend_data",
                report_bill_id: rbid,
                fromDate: from_date, //tz_from_date,
                toDate: to_date, //tz_to_date,
                site_tz: sessionStorage.getItem("prev_site_tz"),
            }),
        })
            .then((response) => response.json())
            .then((data) => {
                //Get the max Kwh value
                const maxKwd = Math.max.apply(
                    Math,
                    data.data.map(function (o) {
                        return o.kwd;
                    })
                );

                //Set the X axis tickCount
                let x_tickCount;
                // if (to_date - from_date === 2*24*60) //Two days
                x_tickCount = 4;

                //Set the Y range se we get nice round numbers for each tick
                let rangeYDomain = 0;
                let y_tickCount; //Number of ticks including zero
                if (maxKwd > 10000) {
                    //10,000kwh bumps
                    rangeYDomain = (Math.floor(maxKwd / 10000) + 1) * 10000;
                } else if (maxKwd > 1000) {
                    //1,000kwh bumps
                    rangeYDomain = (Math.floor(maxKwd / 1000) + 1) * 1000;
                } else if (maxKwd > 100) {
                    //100 bumps
                    rangeYDomain = (Math.floor(maxKwd / 100) + 1) * 100;
                    if (rangeYDomain < 400) y_tickCount = rangeYDomain / 50 + 1;
                    else y_tickCount = rangeYDomain / 100 + 1;
                } else if (maxKwd > 10) {
                    //10 bumps
                    rangeYDomain = (Math.floor(maxKwd / 10) + 1) * 10;
                    if (rangeYDomain < 40) y_tickCount = rangeYDomain / 5 + 1;
                    else y_tickCount = rangeYDomain / 10 + 1;
                } else {
                    //1 bumps
                    rangeYDomain = (Math.floor(maxKwd / 1) + 1) * 1;
                    if (rangeYDomain < 4) y_tickCount = rangeYDomain + 1;
                    else y_tickCount = rangeYDomain / 1 + 1;
                }
                this.setState({
                    plot_data: data,
                    y_axis_domain: [0, rangeYDomain],
                    x_tickCount: x_tickCount,
                    y_tickCount: y_tickCount,
                    graph_type: "line", //Line chart
                    endDate: from_date, //Right hand side of the plot or from/start date
                    initial_date: to_date, //Left hand side of the plot or to/end date
                    loading: false,
                    graph_difference: graph_difference,
                });
            });
    };

    formatXAxisLine = (tickItem) => {
        //const {rg_tz} = this.state;

        //For line chart, the API returns the timestamps in the timezone of the report group
        //So no TZ change required
        return dayjs(tickItem).format("MM-DD HH:mm");
        // .utc(tickItem)
        // .tz(rg_tz)
    };

    formatXAxisBar = (tickItem) => {
        const { rg_tz } = this.state;

        //Sometimes tickItem is not a date
        //Check for valid date
        if (dayjs(tickItem, dayjs.ISO_8601).isValid()) {
            return dayjs.utc(tickItem).tz(rg_tz).format("MM-DD");
        } else {
            return "";
        }
    };

    formatYAxis = (tickItem) => {
        return Number(tickItem).toFixed(2);
    };

    handleRadioButtonChange = (e) => {
        let onchange_val = parseInt(e.target.value);
        const { rg_tz } = this.state;

        //The end date - the right hand side date
        const { initial_date } = this.state; //Get the right side date

        //Calculate the left side date of the chart
        let endDate = dayjs(initial_date)
            .tz(rg_tz)
            .subtract(onchange_val, "days")
            .utc()
            .toISOString();

        if (onchange_val === 7) {
            //7 days
            let graph_diff = 7;
            this.get_trend_data(
                this.props.reportRow.report_bill_id,
                endDate,
                initial_date,
                graph_diff
            );
        } else if (onchange_val === 2) {
            let graph_diff = 1;
            //2 days - 48 hours
            this.get_trend_data(
                this.props.reportRow.report_bill_id,
                endDate,
                initial_date,
                graph_diff
            );
        } else if (onchange_val === 30) {
            let graph_diff = 7;
            //30 day bar chart
            this.get_bar_daily(
                this.props.reportRow.report_bill_id,
                endDate,
                initial_date,
                graph_diff
            );
        }
    };

    panNegative = () => {
        //get values from state
        const { graph_difference, endDate, initial_date, graph_type, rg_tz } =
            this.state;
        const { reportRow } = this.props;

        let calculated_initial = dayjs(initial_date)
            .tz(rg_tz)
            .subtract(graph_difference, "days")
            .utc()
            .toISOString();
        let calculated_base = dayjs(endDate)
            .tz(rg_tz)
            .subtract(graph_difference, "days")
            .utc()
            .toISOString();

        if (graph_type === "line") {
            this.get_trend_data(
                reportRow.report_bill_id,
                calculated_base,
                calculated_initial,
                graph_difference
            );
        } else if (graph_type === "bar" || graph_type === "") {
            this.get_bar_daily(
                reportRow.report_bill_id,
                calculated_base,
                calculated_initial,
                graph_difference
            );
        }
    };

    panPositive = () => {
        const { reportRow } = this.props;
        const { graph_difference, endDate, initial_date, graph_type, rg_tz } =
            this.state;
        let calculated_initial = dayjs(initial_date)
            .tz(rg_tz)
            .add(graph_difference, "days")
            .utc()
            .toISOString();
        let calculated_base = dayjs(endDate)
            .tz(rg_tz)
            .add(graph_difference, "days")
            .utc()
            .toISOString();

        let end_time = dayjs(initial_date);
        let time_now = dayjs();

        if (end_time < time_now) {
            if (graph_type === "line") {
                this.get_trend_data(
                    reportRow.report_bill_id,
                    calculated_base,
                    calculated_initial,
                    graph_difference
                );
            } else if (graph_type === "bar" || graph_type === "") {
                this.get_bar_daily(
                    reportRow.report_bill_id,
                    calculated_base,
                    calculated_initial,
                    graph_difference
                );
            }
        }
    };

    render() {
        const { reportRow, show_graph_modal } = this.props;
        const {
            chart_loaded,
            loading,
            endDate,
            initial_date,
            rg_tz,
            graph_type,
            plot_data,
            y_axis_domain,
            y_tickCount,
        } = this.state;

        const chartWidthDays = Number(
            dayjs(dayjs(initial_date).diff(dayjs(endDate))).format("D")
        );

        //NEVER DO THIS * NEVER DO THIS * NEVER DO THIS * NEVER DO THIS * NEVER DO THIS * NEVER DO THIS * NEVER DO THIS *
        //dayjs.suppressDeprecationWarnings = true;
        //NEVER DO THIS * NEVER DO THIS * NEVER DO THIS * NEVER DO THIS * NEVER DO THIS * NEVER DO THIS * NEVER DO THIS *

        const CustomTooltipBar = ({ _active, payload, label }) => {
            return (
                <div className="custom-tooltip">
                    <div className="custom-tooltip-header">
                        <p className="tooltip-label label">{`${dayjs
                            .utc(label)
                            .tz(rg_tz)
                            .format("MM-DD-YYYY")}`}</p>
                    </div>
                    <div style={{ color: "blue", float: "left" }}>&#9632;</div>
                    <div style={{ float: "left" }}>
                        {" "}
                        kWh per day:{" "}
                        {payload ? payload[0] && payload[0].value : "undefined"}
                    </div>
                </div>
            );
        };

        const CustomTooltipLine = ({ _active, payload, label }) => {
            return (
                <div className="custom-tooltip">
                    <div className="custom-tooltip-header">
                        <p className="tooltip-label label">{`${dayjs(
                            label
                        ).format("MM-DD h:mm")}`}</p>
                    </div>
                    <div style={{ color: "blue", float: "left" }}>&#9632;</div>
                    <div
                        className="custom-tooltip-body"
                        style={{ float: "left" }}
                    >
                        {" "}
                        kW Demand - 15 min: {payload[0] && payload[0].value}
                    </div>
                </div>
            );
        };

        return (
            <Modal
                show={show_graph_modal}
                onHide={this.props.handleGraphModalClose}
                size="xl"
            >
                <Modal.Header className="modal-header">
                    <Modal.Title>
                        {reportRow.org_name
                            ? reportRow.org_name
                            : "Vacant -" + reportRow.report_name}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {graph_type === "line" && ( //Show line chart
                        <ResponsiveContainer
                            width="100%"
                            height={400}
                            className="Tenantv2_chart"
                        >
                            <LineChart
                                width="130%"
                                height={700}
                                data={plot_data.data}
                                margin={{
                                    top: 0,
                                    right: 20,
                                    bottom: 25,
                                    left: 5,
                                }}
                            >
                                <Line
                                    dataKey="kwd"
                                    stroke="#8884d8"
                                    dot={false} //No data marker
                                    type="linear"
                                    animationDuration={0}
                                    isAnimationActive={false}
                                    connectNulls={true}
                                />
                                <CartesianGrid
                                    strokeDasharray="3 3"
                                    stroke="#ccc"
                                />

                                <XAxis //category
                                    dataKey="x" //Used for plotting
                                    tickFormatter={this.formatXAxisLine} //Format the timestamps
                                    interval={chartWidthDays === 2 ? 11 : 23} //Tick every 11 readings - 15 minute readings = every 3 hours
                                    angle={70}
                                    style={{ fontSize: ".8rem" }}
                                    dy={33} //padding from y axis
                                    dx={15} //padding from x axis
                                    label={{
                                        value: "kW Demand - 15 minute",
                                        dy: 80,
                                        angle: 0,
                                        position: "center",
                                    }}
                                />
                                <YAxis
                                    tickFormatter={this.formatYAxis}
                                    type="number"
                                    domain={y_axis_domain}
                                    tickCount={y_tickCount}
                                    label={{
                                        value: "kW Demand - 15 minute",
                                        dx: -40, //padding from x axis
                                        angle: -90,
                                        position: "center",
                                    }}
                                />
                                <Tooltip content={<CustomTooltipLine />} />
                            </LineChart>
                        </ResponsiveContainer>
                    )}

                    {graph_type === "bar" && ( //Bar chart
                        <ResponsiveContainer width="100%" height={400}>
                            <BarChart
                                width="130%"
                                height={300}
                                data={plot_data.data}
                                margin={{
                                    top: 5,
                                    right: 30,
                                    left: 20,
                                    bottom: 5,
                                }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis
                                    dataKey="x"
                                    tickFormatter={this.formatXAxisBar}
                                    interval={0} //All bars have a tick & label
                                    angle={70}
                                    dy={20}
                                    dx={10}
                                    style={{ fontSize: ".8rem" }}
                                    label={{
                                        value: "kWh Consumed per day",
                                        dy: 50, //padding from x axis
                                        angle: 0,
                                        position: "center",
                                    }}
                                />
                                <YAxis
                                    domain={y_axis_domain}
                                    tickCount={y_tickCount}
                                />
                                <Tooltip content={<CustomTooltipBar />} />
                                <Legend />
                                <Bar
                                    dataKey="kwh"
                                    fill="#0047AB"
                                    animationDuration={0}
                                    isAnimationActive={false}
                                />
                            </BarChart>
                        </ResponsiveContainer>
                    )}

                    <div className={graph_type !== "line" ? "mt-5" : "mt-5"}>
                        {chart_loaded ? (
                            <div>
                                <div className="input-group d-sm-none d-md-block">
                                    <div className="mx-auto">
                                        <button
                                            id="PanBackward"
                                            className={` hidden-print ${
                                                loading ? "" : "btn-primary"
                                            } `}
                                            value="negative"
                                            disabled={loading}
                                        >
                                            <span
                                                className="fas fa-chevron-left"
                                                onClick={this.panNegative}
                                            />
                                        </button>

                                        <input
                                            className="rounded text-center mx-2 "
                                            id="timezone"
                                            value={rg_tz}
                                            disabled
                                        />

                                        <label
                                            htmlFor="fromDate"
                                            className="col-form-label form-control-sm"
                                        >
                                            From:
                                        </label>
                                        <input
                                            className="rounded text-center"
                                            id="fromDate"
                                            value={dayjs(endDate)
                                                .tz(rg_tz)
                                                .format("MM-DD-YYYY HH:mm")}
                                            disabled
                                        />

                                        <label
                                            htmlFor="toDate"
                                            className="col-form-label form-control-sm"
                                        >
                                            To:
                                        </label>
                                        <input
                                            className="rounded text-center mx-2 "
                                            id="toDate"
                                            value={dayjs(initial_date)
                                                .tz(rg_tz)
                                                .format("MM-DD-YYYY HH:mm")}
                                            disabled
                                        />

                                        <button
                                            id="PanForward"
                                            className={`${
                                                loading ? "" : "btn-primary"
                                            }  hidden-print`}
                                            value="positive"
                                            disabled={loading}
                                        >
                                            <span
                                                className="fas fa-chevron-right"
                                                onClick={this.panPositive}
                                            />
                                        </button>
                                        <select
                                            className="mx-2 form-control-sm"
                                            onChange={
                                                this.handleRadioButtonChange
                                            }
                                        >
                                            <option value="30">
                                                Past 30 days - Daily kWh
                                            </option>
                                            <option value="7">
                                                {" "}
                                                7 Days - kW Demand
                                            </option>
                                            <option value="2">
                                                48 hours - kW Demand
                                            </option>
                                        </select>
                                        <button
                                            className="btn btn-primary btn-sm mb-1 mx-2"
                                            onClick={this.handleCurrentButton}
                                        >
                                            Today
                                        </button>
                                    </div>
                                </div>

                                {loading && (
                                    <div
                                        className="text-center"
                                        style={{
                                            fontSize: "15px",
                                            marginTop: "10px",
                                        }}
                                    >
                                        <div className="loading-div">
                                            Loading{" "}
                                            <img
                                                alt=""
                                                height={20}
                                                src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                                            />
                                        </div>
                                    </div>
                                )}
                            </div>
                        ) : (
                            <>
                                <div
                                    className="text-center"
                                    style={{
                                        height: "200px",
                                        fontSize: "20px",
                                    }}
                                >
                                    <div className="loading-div">
                                        Loading{" "}
                                        <img
                                            alt=""
                                            height={20}
                                            src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA=="
                                        />
                                    </div>
                                </div>
                            </>
                        )}
                    </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                    <Button
                        variant="secondary"
                        onClick={this.props.handleGraphModalClose}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }
}
