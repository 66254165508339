import React, { useState, useEffect, useCallback } from "react";
import dayjs from "dayjs";
import { Fetch } from "../../helpers/Fetch";
import AddNewTenantModal from "./AddNewTenantModal";
import { LogUserAction } from "../../helpers/LogUserAction";

const ExpandedReportTable = ({ report_bill_id }) => {
    const [reportInfo, setReportInfo] = useState({});
    const [occHistory, setOccHistory] = useState([]);
    const [reportNotes, setReportNotes] = useState("");
    const [showMeteringDetails, setShowMeteringDetails] = useState(false);
    const [reportDevices, setReportDevices] = useState([]);
    const [showAddNewModal, setShowAddNewModal] = useState(false);
    const [modalType, setModalType] = useState("Add New Tenant");
    const [energyUserId, setEnergyUserId] = useState("");
    const loginData = JSON.parse(localStorage.getItem("login_data"));

    useEffect(
        () => {
            getDdData();
        },
        //eslint-disable-next-line
        []
    );

    const getDdData = useCallback(async () => {
        const data = await Fetch("TenantV3_api.php", {
            action: "fill_dd_t_row",
            report_bill_id: report_bill_id,
        });
        if (data.status === "ok") {
            setOccHistory(data.occ_history);
            setReportDevices(data.report_devices);
            setReportInfo(data.report_info);
            setReportNotes(data.report_info.report_note);
        } else {
            console.log("Fetch ERROR", data);
        }
    }, [report_bill_id]);

    const handleReportNotesSubmit = useCallback(async () => {
        const data = await Fetch("TenantV3_api.php", {
            action: "update_report_note",
            report_bill_id: report_bill_id,
            report_note: reportNotes,
        });
        if (data.status === "ok") {
            setReportNotes(data.get.report_note);
        }
    }, [reportNotes, report_bill_id]);

    const handleShowMeteringDetails = useCallback(() => {
        setShowMeteringDetails((prev) => !prev);
    }, []);

    const handleReportNotesChange = useCallback((e) => {
        setReportNotes(e.target.value);
    }, []);

    const handleShowAddModal = useCallback(
        (modalType, element) => {
            LogUserAction("Add/Edit user report details");
            if (loginData.user_role > 6000) {
                setShowAddNewModal(true);
                setModalType(modalType);
                setEnergyUserId(element && element.energy_user_id);
            }
        },
        [loginData.user_role]
    );

    const handleCloseAddModal = useCallback(() => {
        setShowAddNewModal(false);
    }, []);

    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-center">
                <h4>
                    <button
                        className={`badge badge-primary mx-4 ${
                            loginData.user_role < 7000
                                ? "button-disabled"
                                : "button-enabled"
                        }`}
                        disabled={loginData.user_role < 7000}
                        onClick={() => handleShowAddModal("Add New Tenant")}
                    >
                        Add New Tenant
                    </button>
                </h4>
                <h3 className="space_name">
                    {reportInfo.report_name} - Tenant History
                </h3>
            </div>
            {/* occ history details */}
            <table className="table table-bordered table-responsive table-sm">
                <thead>
                    <tr>
                        <th>#</th>
                        <th>From Date</th>
                        <th>To Date</th>
                        <th>Tenant Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                    </tr>
                </thead>
                <tbody>
                    {occHistory.map((element, index) => (
                        <tr key={index + element.energy_user_id}>
                            <td>
                                <i
                                    className={`fas fa-edit ${
                                        loginData.user_role < 7000
                                            ? "i-disabled"
                                            : ""
                                    }`}
                                    onClick={() => {
                                        if (loginData.user_role < 7000) return;
                                        handleShowAddModal(
                                            "Edit Tenant",
                                            element
                                        );
                                    }}
                                />
                            </td>
                            <td>
                                {dayjs(element.from_date).format("MM-DD-YYYY")}
                            </td>
                            <td>
                                {dayjs(element.to_date).format("YYYY") > 2049
                                    ? "No End"
                                    : dayjs(element.to_date).format(
                                          "MM-DD-YYYY"
                                      )}
                            </td>
                            <td>{element.org_name}</td>
                            <td>{element.email_report}</td>
                            <td>{element.phone_main}</td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="d-flex justify-content-center">
                <span className="h4">
                    rb_id:&nbsp;{report_bill_id} - Location Notes
                </span>

                <button
                    className="badge badge-secondary mx-5 mb-2"
                    onClick={handleShowMeteringDetails}
                    style={{ marginTop: "auto", backgroundColor: "grey" }}
                >
                    Metering Details
                </button>
            </div>
            <div className="d-flex justify-content-center">
                <textarea
                    className="form-control report_note"
                    value={reportNotes}
                    onChange={handleReportNotesChange}
                    name="report_notes"
                    rows="3"
                />
                <button
                    className={`update_notes_btn btn btn-sm ${
                        loginData.user_role < 7000
                            ? "button-disabled"
                            : "button-enabled"
                    }`}
                    type="button"
                    disabled={loginData.user_role < 7000}
                    onClick={handleReportNotesSubmit}
                >
                    Update Notes
                </button>
            </div>
            {/* Metering details table */}
            {showMeteringDetails && (
                <table className="table table-bordered table-responsive table-sm">
                    <thead>
                    <tr>
                        <th>collector Id</th>
                        <th>device Id</th>
                        <th>Enabled</th>
                        <th>Alarm</th>
                        <th>Utility</th>
                        <th>Collector Name</th>
                        <th>Device Name</th>
                        <th>Device Type Name</th>
                        <th>Percent</th>
                        <th>Notes</th>
                        <th>Comm Data</th>
                    </tr>
                    </thead>
                    <tbody>
                        {reportDevices.map((element, index) => (
                            <tr key={index}>
                                <td>{element.collector_id}</td>
                                <td>{element.device_id}</td>
                                <td>
                                    <input
                                        type="checkbox"
                                        style={{
                                            width: "30px",
                                            height: "30px",
                                        }}
                                        defaultChecked={element.enabled}
                                        readOnly
                                        disabled
                                    />
                                </td>
                                <td>
                                    <input
                                        type="checkbox"
                                        style={{
                                            width: "30px",
                                            height: "30px",
                                        }}
                                        defaultChecked={element.alarm_enabled}
                                        readOnly
                                        disabled
                                    />
                                </td>
                                <td>{element.utility_type_name}</td>
                                <td>{element.collector_name}</td>
                                <td>{element.device_name}</td>
                                <td>{element.device_type_name}</td>
                                <td>{element.percent}</td>
                                <td>{element.device_notes}</td>
                                <td>{element.device_comm_data1}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
            {showAddNewModal && (
                <AddNewTenantModal
                    modal_type={modalType}
                    energy_user_id={energyUserId}
                    show_add_new_modal={showAddNewModal}
                    handle_close_modal={handleCloseAddModal}
                    report_bill_id={report_bill_id}
                    get_dd_data={getDdData}
                />
            )}
        </div>
    );
};

export default ExpandedReportTable;
