import React, { useMemo, useState, Fragment, useCallback } from "react";
import { useTable, useExpanded } from "react-table";
import { Button, Table } from "react-bootstrap";
import "./Tenant_Info.css";
import Fuse from "fuse.js";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import LocalizedFormat from "dayjs/plugin/localizedFormat";
import ExpandedReportTable from "./ExpandedReportTable";
import { TenantModal } from "./TenantModal";
import { Fetch } from "../../helpers/Fetch";
import GroupInfoModal from "./GroupInfoModal";

dayjs.extend(utc);
dayjs.extend(LocalizedFormat);

export default function TenantInfoTable(props) {
    // Search Text
    const [filterText, setFilterText] = useState("");
    const [showVacant, setShowVacant] = useState(false);

    const [showInfoModal, setShowInfoModal] = useState(false);
    const [groupInfo, setGroupInfo] = useState([]);
    // Memoize and convert report data from props
    const reports = useMemo(() => {
        const reports = props.data ?? [];

        const fuse = new Fuse(reports, {
            keys: ["report_name", "org_name"],
            threshold: 0,
            ignoreLocation: true,
            isCaseSensitive: false, //false is the default but I want this comment as a reminder
        });

        const search_results = fuse.search(filterText);

        return filterText
            ? search_results.map((result) => result.item)
            : reports;
    }, [props.data, filterText]);

    // Memoize the tenant graph data from props
    const tenantV3Data = useMemo(() => {
        return props.tenant_v3_data ?? [];
    }, [props.tenant_v3_data]);

    const isSameGroupName = (report_bill_id_first, report_bill_id_second) => {
        // Find the first object with the given report_group_id
        const obj1 = tenantV3Data.find(
            (obj) => obj.report_bill_id === report_bill_id_first,
        );
        // Find the second object with the given report_group_id
        const obj2 = tenantV3Data.find(
            (obj) => obj.report_bill_id === report_bill_id_second,
        );

        // Check if either object is missing
        if (!obj1 || !obj2) {
            return false; // Return false if either object is not found
        }

        // Return true if report_group_names are equal
        return obj1.report_group_name === obj2.report_group_name;
    };

    const getReportGroupId = (report_bill_id) => {
        const found = tenantV3Data.find(
            (item) => item.report_bill_id === report_bill_id,
        );
        return found ? found.report_group_id : 0;
    };

    const getCollapsed = (report_bill_id) => {
        const found = tenantV3Data.find(
            (item) => item.report_bill_id === report_bill_id,
        );
        return found ? found.collapsed : false;
    };

    const getReportGroupName = (report_bill_id) => {
        const found = tenantV3Data.find(
            (item) => item.report_bill_id === report_bill_id,
        );
        return found ? found.report_group_name : "";
    };

    const getGroupCollapsed = (report_bill_id) => {
        const found = tenantV3Data.find(
            (item) => item.report_bill_id === report_bill_id,
        );
        return found ? found.collapsed : true;
    };

    const handleAlert = (type, message) => {
        props.onMessage(type, message);
    };
    // State for modal state change
    const [showGraphModal, setShowGraphModal] = useState(false);

    // State for modal data
    const [reportRow, setReportRow] = useState(null);

    /**
     * Reset Filter Text state
     */
    const handleClearFilter = useCallback(() => {
        setFilterText("");
    }, [setFilterText]);
    /**
     * handle House Loads
     */

    const handleOpenInfoModal = (report_group_id) => {
        setShowInfoModal(true);
        Fetch("tenant_info_api.php", {
            action: "get_group_info",
            report_group_id: report_group_id,
        }).then((data) => {
            if (data.status === "ok") {
                setGroupInfo(data.data);
            }
        });
    };

    const handleCloseInfoModal = () => {
        setShowInfoModal(false);
    };

    const handleGroupExpand = async (_e, report_group_id, collapse_val) => {
        await Fetch("tenant_info_api.php", {
            action: "UPDATE_rg_collapsed",
            report_group_id: report_group_id,
            collapsed: !collapse_val,
        });

        //   refresh table
        props.refresh_table();
    };

    const handleGroupCollapse = async (_e, report_group_id, collapse_val) => {
        await Fetch("tenant_info_api.php", {
            action: "UPDATE_rg_collapsed",
            report_group_id: report_group_id,
            collapsed: !collapse_val,
        });
        //   refresh table
        props.refresh_table();
    };

    /**
     * Show Graph Modal dialog
     */
    const handleShowGraphModal = useCallback(
        (_e, props, _p) => {
            const { report_bill_id } = props;
            const matchingObject = tenantV3Data.find(
                (item) => item.report_bill_id === report_bill_id,
            );
            if (matchingObject) {
                setShowGraphModal(true);
                setReportRow(matchingObject);
            }
        },
        [tenantV3Data, setReportRow, setShowGraphModal],
    );

    /**
     * Close Graph Modal Dialog
     */
    const handleGraphModalClose = useCallback(() => {
        setShowGraphModal(false);
    }, [setShowGraphModal]);

    const columns = useMemo(
        () => [
            {
                // Make an expander cell
                Header: () => <div>#</div>, // No header
                id: "expander", // It needs an ID
                Cell: ({ row }) => {
                    // Use Cell to render an expander for each row.
                    // We use the getToggleRowExpandedProps prop-getter
                    // to build the expander.
                    // CAUTION: There is some react-table MAGIC that happens in this section

                    if (row.isExpanded) {
                        //if expanded
                        return (
                            <i
                                {...row.getToggleRowExpandedProps()}
                                className="far fa-minus-square d-print-none"
                                style={{ color: "red" }}
                            />
                        );
                    } else {
                        //if NOT expanded
                        if (row.original.report_bill_id) {
                            return (
                                <i
                                    {...row.getToggleRowExpandedProps()}
                                    className="far fa-plus-square d-print-none"
                                    style={{ color: "green" }}
                                />
                            );
                        }
                    }
                },
            },
            {
                Header: () => <div>Report Group Name</div>,
                accessor: "report_group_name",
                Cell: (props) => {
                    return props.value;
                },
            },
            {
                Header: () => <div className="me-5">Report Name</div>, //Text of the header
                accessor: "report_name", //Property name in data
                Cell: (props) => {
                    return (
                        <div>
                            {props.value}
                            {props.row.original.report_bill_id && (
                                <i
                                    className="float-end far fa-chart-bar fa-lg table-icon-styling"
                                    onClick={(e) =>
                                        handleShowGraphModal(
                                            e,
                                            props.row.original,
                                            props,
                                        )
                                    }
                                />
                            )}
                        </div>
                    );
                },
            },
            {
                Header: () => <div className="me-5">Organization Name</div>,
                accessor: "org_name",
                Cell: (props) => <div>{props.value}</div>,
            },
            {
                Header: () => <div className="me-5">First Name</div>,
                accessor: "fname",
                Cell: (props) => <div>{props.value}</div>,
            },
            {
                Header: () => <div className="me-5">Last Name</div>,
                accessor: "lname",
                Cell: (props) => <div>{props.value}</div>,
            },
            {
                Header: () => <div className="me-5">From Date</div>,
                accessor: "from_date",

                Cell: (props) => (
                    <div>
                        {props.value
                            ? dayjs(props.value).format("MM-DD-YYYY HH:mm")
                            : ""}
                    </div>
                ),
            },
            {
                Header: () => <div className="me-5">To Date</div>,
                accessor: "to_date",
                Cell: (props) => (
                    <div>
                        {props.value && props.value !== "1/1/2199"
                            ? dayjs(props.value).format("MM-DD-YYYY HH:mm")
                            : ""}
                    </div>
                ),
            },
            {
                Header: () => <div className="me-5">Notes</div>,
                accessor: "notes",
                Cell: (props) => <div>{props.value}</div>,
            },
            {
                accessor: "report_bill_id",
                Header: "report_bill_id",
            },
            {
                accessor: "dont_send_bill",
                Header: "dont_send_bill",
            },
            {
                accessor: "energy_user_id",
                Header: "energy_user_id",
            },
        ],
        [handleShowGraphModal],
    );

    /**
     * Check if the date is passed current time
     */
    const isDatePassed = useCallback(
        (to_date) => dayjs(to_date).isBefore(dayjs()),
        [],
    );

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
        visibleColumns,
        // state: { expanded },
    } = useTable(
        {
            columns,
            data: reports,
            isLoading: true,
            autoResetExpanded: true,
            debugTable: true,
            initialState: {
                hiddenColumns: [
                    "report_group_name",
                    "report_bill_id",
                    "dont_send_bill",
                    "energy_user_id",
                ],
            },
        },
        useExpanded,
    );

    return (
        <>
            <div className="text-center d-print-none mb-2">
                <span className="float-start mt-2">
                    <input
                        className="mx-2"
                        type="checkbox"
                        checked={showVacant}
                        onChange={() => setShowVacant(!showVacant)}
                    />
                    <label className="form-check-label">Show Vacants</label>
                </span>
                <Button
                    className="btn btn-primary btn-sm button-bar-button mx-2"
                    onClick={props.refresh_table}
                >
                    Refresh
                </Button>
                <input
                    type="text"
                    onChange={(e) => setFilterText(e.target.value)}
                    value={filterText}
                    id="device_filter_input"
                    placeholder="Filter(Space,Tenant Name)"
                    className="form-control-sm"
                />
                <button className="btn-sm mx-3" onClick={handleClearFilter}>
                    Clear
                </button>

                <span className="float-end mt-2">
                    Legend:
                    <i
                        className="fas fa-square-full"
                        style={{
                            color: "orange",
                            marginLeft: "10px",
                        }}
                    />
                    &nbsp;Send to admin
                    <i
                        className="fas fa-square-full"
                        style={{
                            color: "yellow",
                            marginLeft: "10px",
                        }}
                    />
                    &nbsp;Vacant
                    <i
                        className="fas fa-square-full"
                        style={{
                            color: "lightgray",
                            marginLeft: "10px",
                        }}
                    />
                    &nbsp;House Loads
                </span>
            </div>
            <Table //Bootstrap table settings
                size="sm"
                bordered
                {...getTableProps()}
                className={props.loading ? "table-loading" : ""}
                key={`${filterText}`}
            >
                <thead>
                    {headerGroups.map((headerGroup, index) => (
                        <tr
                            {...headerGroup.getHeaderGroupProps()}
                            key={`header-${index}`}
                        >
                            {headerGroup.headers.map((column, i) => {
                                return (
                                    <th
                                        {...column.getHeaderProps()}
                                        key={`th-${index}-${i}`}
                                    >
                                        {column.render("Header")}
                                    </th>
                                );
                            })}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {/* Process collector rows */}
                    {rows.map((row, i) => {
                        const isGroupName =
                            i === 0
                                ? false
                                : isSameGroupName(
                                      row.values.report_bill_id,
                                      rows[i - 1].values.report_bill_id,
                                  );
                        const reportGroupId = getReportGroupId(
                            row.values.report_bill_id,
                        );
                        const collapsed = getCollapsed(
                            row.values.report_bill_id,
                        );
                        const reportGroupName = getReportGroupName(
                            row.values.report_bill_id,
                        );

                        const isGroupCollapsed = getGroupCollapsed(
                            row.values.report_bill_id,
                        );

                        prepareRow(row);
                        return (
                            <Fragment key={"idx_" + row.id}>
                                {(i === 0 // first row
                                    ? true
                                    : !isGroupName) &&
                                    //If different category/true - produce category row
                                    reportGroupId > 0 && (
                                        <tr
                                            {...row.getRowProps()}
                                            key={`${row.id}-${i}`}
                                        >
                                            <td
                                                colSpan={1}
                                                className={`${
                                                    reportGroupId === 0
                                                        ? "custom-group-color"
                                                        : "category-header-color"
                                                } text-center`}
                                                key={`td-${row.id}-${i}-1`}
                                            >
                                                {collapsed ? (
                                                    <i
                                                        className="fas fa-plus-square"
                                                        onClick={(e) =>
                                                            handleGroupExpand(
                                                                e,
                                                                reportGroupId,
                                                                collapsed,
                                                            )
                                                        }
                                                    />
                                                ) : (
                                                    <i
                                                        className="fas fa-minus-square"
                                                        onClick={(e) =>
                                                            handleGroupCollapse(
                                                                e,
                                                                reportGroupId,
                                                                collapsed,
                                                            )
                                                        }
                                                    />
                                                )}
                                            </td>
                                            <td
                                                colSpan={7}
                                                className={`${
                                                    reportGroupId === 0
                                                        ? "custom-group-color"
                                                        : "category-header-color"
                                                } text-center `}
                                                key={`td-${row.id}-${i}-2`}
                                            >
                                                {reportGroupName}
                                                <i
                                                    className="fa fa-info-circle mx-2 d-print-none"
                                                    onClick={() =>
                                                        handleOpenInfoModal(
                                                            reportGroupId,
                                                        )
                                                    }
                                                    aria-hidden="true"
                                                />
                                            </td>
                                        </tr>
                                    )}
                                <tr
                                    {...row.getRowProps()}
                                    key={`tr-${i}`}
                                    className={
                                        isDatePassed(row.values.to_date)
                                            ? "vacant-color"
                                            : ""
                                    }
                                >
                                    {row.cells.map((cell, j) => {
                                        return (
                                            !isGroupCollapsed &&
                                            (showVacant ? (
                                                isDatePassed(
                                                    row.values.to_date,
                                                ) && (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        key={`td-${i}-${j}`}
                                                    >
                                                        {cell.render("Cell")}
                                                    </td>
                                                )
                                            ) : (
                                                <td
                                                    {...cell.getCellProps()}
                                                    key={`td-${i}-${j}`}
                                                >
                                                    {cell.render("Cell")}
                                                </td>
                                            ))
                                        );
                                    })}
                                </tr>
                                {row.isExpanded ? (
                                    <>
                                        <tr>
                                            <td colSpan={visibleColumns.length}>
                                                <ExpandedReportTable
                                                    report_bill_id={
                                                        row.values
                                                            .report_bill_id
                                                    }
                                                    onMessage={handleAlert}
                                                />
                                            </td>
                                        </tr>
                                    </>
                                ) : null}
                            </Fragment>
                        );
                    })}
                </tbody>
            </Table>
            {showGraphModal && (
                <TenantModal
                    show_graph_modal={showGraphModal}
                    reportRow={reportRow}
                    handleGraphModalClose={handleGraphModalClose}
                    site_tz={sessionStorage.getItem("prev_site_tz")}
                />
            )}

            <GroupInfoModal
                show={showInfoModal}
                handleClose={handleCloseInfoModal}
                groupInfo={groupInfo}
            />
        </>
    );
}
