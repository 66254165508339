import React, { Component } from "react";
import { useTable } from "react-table";
import { OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import { Fetch } from "../../helpers/Fetch";
import { globalDateFormat } from "../../helpers/GlobalDateFormat";

class AdminUserLog extends Component {
  constructor(props) {
    super(props);

    //Get logged in user data
    let user = JSON.parse(localStorage.getItem("login_data"));

    this.state = {
      admin_logs_data: [],
      message: "",
      host: "",
      enable_filter_btn: true,
      user_tz: user.user_tz, //logged-in user TZ
    };
  }
  componentDidUpdate(prevProps, _prevState, _snapshot) {
    if (prevProps.edited_user_id !== this.props.edited_user_id) {
      this.get_admin_log_data();
    }
  }
  componentDidMount() {
    this.get_admin_log_data();
  }

  handle_host_change = (e) => {
    this.setState({
      host: e.target.value,
      enable_filter_btn: false,
    });
  };
  handle_message_change = (e) => {
    this.setState({
      message: e.target.value,
      enable_filter_btn: false,
    });
  };

  get_all_users_logs = (_e) => {
    Fetch("admin_log.php", {
      action: "get_all_users_logs",
    })
      .then((data) => {
        if (data.status === "ok") {
          this.setState({
            admin_logs_data: data.data,
          });
        }
      })
      .catch((err) => console.log(err));

    // this.get_admin_log_data()
  };

  get_non_admin_users_logs = (_e) => {
    Fetch("admin_log.php", {
      action: "get_non_admin_users_logs",
    })
      .then((data) => {
        if (data.status === "ok") {
          this.setState({
            admin_logs_data: data.data,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  filter_admin_log_data = () => {
    const { message, host } = this.state;
    Fetch("admin_log.php", {
      action: "filter_log_table",
      edited_user_id: this.props.edited_user_id,
      message: message,
      host: host,
    })
      .then((data) => {
        if (data.status === "ok") {
          this.setState({
            admin_logs_data: data.data,
          });
        }
      })
      .catch((err) => console.log(err));
  };

  get_admin_log_data = () => {
    Fetch("admin_log.php", {
      action: "get_log_table",
      edited_user_id: this.props.edited_user_id,
    })
      .then((data) => {
        if (data.status === "ok") {
          this.setState({
            admin_logs_data: data.data,
            message: "",
            host: "",
          });
        }
      })
      .catch((err) => console.log(err));
  };

  handleUserSelectChange = (e) => {
    let val = e.target.value;
    if (val === "1") {
      this.get_admin_log_data();
    } else if (val === "2") {
      this.get_all_users_logs();
    } else if (val === "3") {
      this.get_non_admin_users_logs();
    }
  };

  render() {
    const { admin_logs_data, enable_filter_btn, message, host } =
      this.state;

    const columns = [
      {
        Header: () => (
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip>
                <div>UTC Timestamp </div>
              </Tooltip>
            }
          >
            <div className="mx-5">Timestamp</div>
          </OverlayTrigger>
        ),
        accessor: "utc_timestamp",
        key: "utc_timestamp",
        Cell: (props) => (
          <div className="text-center">
            {globalDateFormat(props.value)}
          </div>
        ),
      },
      {
        Header: "Username",
        accessor: "username",
        key: "username",
      },
      {
        Header: "F.Name",
        accessor: "fname",
        key: "fname",
      },
      {
        Header: "L.Name",
        accessor: "lname",
        key: "lname",
      },
      {
        Header: "Message",
        accessor: "message",
        key: "message",
        class: "w-40 text-wrap break-word",
      },
      {
        Header: "Host",
        accessor: "host",
        key: "host",
        class: "d-none d-md-table-cell",
      },
      {
        Header: "IP Address",
        accessor: "ip_addr",
      },
      {
        Header: "Remote Host",
        accessor: "remote_host",
      },
      {
        Header: "User Agent",
        accessor: "user_agent",
      },
    ];

    function TableRender({ columns, data }) {
      const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        rows,
        prepareRow,
      } = useTable({
        columns,
        data,
        initialState: {
          hiddenColumns: ["user_agent", "remote_host", "ip_addr"],
        },
      });
      return (
        <div>
          <Table //Bootstrap table settings
            bordered
            hover
            responsive
            size="sm"
            // variant="dark"
            {...getTableProps()}
          >
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr key={"header"} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      key={column.key}
                      {...column.getHeaderProps({
                        class: column.class,
                      })}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row, i) => {
                prepareRow(row);
                return (
                  <React.Fragment key={i}>
                    <OverlayTrigger
                      key={i}
                      placement="top"
                      overlay={
                        <Tooltip>
                          <div>
                            IP address:{row.values.ip_addr}
                            <br /> Remote Host:{row.values.remote_host}
                            <br /> User Agent:{row.values.user_agent}
                            <br />{" "}
                          </div>
                        </Tooltip>
                      }
                    >
                      <tr key={row.values.site_id} {...row.getRowProps()}>
                        {row.cells.map((cell) => {
                          return (
                            <td
                              {...cell.getCellProps({
                                class: cell.column.class,
                              })}
                            >
                              {cell.render("Cell")}
                            </td>
                          );
                        })}
                      </tr>
                    </OverlayTrigger>
                  </React.Fragment>
                );
              })}
            </tbody>
          </Table>
        </div>
      );
    }
    return (
      <div>
        <h4 className="text-center">Admin User Log</h4>
        <div className="d-flex align-items-center justify-content-center mb-3">
          <select className="mx-2" onChange={this.handleUserSelectChange}>
            <option value={1}>Current User</option>
            <option value={2}>All Users</option>
            <option value={3}>Non-Admin Users</option>
          </select>
          {/*<label className="me-1">*/}
          {/*  <b>Show All Users</b>*/}
          {/*</label>*/}
          {/*<input*/}
          {/*    type="checkbox"*/}
          {/*    onChange={this.get_all_users_logs}*/}
          {/*    value={host}*/}
          {/*    className="form-control-sm me-3"*/}
          {/*/>*/}

          <label className="me-1">
            <b>Host</b>
          </label>
          <input
            type="text"
            onChange={this.handle_host_change}
            value={host}
            className="form-control-sm me-3"
          />
          <label className="me-1">
            <b>Message</b>
          </label>
          <input
            type="text"
            value={message}
            onChange={this.handle_message_change}
            className="form-control-sm me-1"
          />
          {
            <button
              className="btn btn-primary btn-sm"
              disabled={enable_filter_btn}
              onClick={this.filter_admin_log_data}
            >
              Filter
            </button>
          }
          {
            <button
              className="btn btn-primary btn-sm mx-2"
              disabled={enable_filter_btn}
              onClick={this.get_admin_log_data}
            >
              Clear Filter
            </button>
          }
        </div>
        <div className=" align-items-center justify-content-center">
          <TableRender columns={columns} data={admin_logs_data} />
        </div>
      </div>
    );
  }
}

export default AdminUserLog;
